import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Byte: { input: number; output: number };
  Date: { input: string; output: string };
  DateTime: { input: string; output: string };
  Decimal: { input: number; output: number };
  Long: { input: number; output: number };
  UUID: { input: string; output: string };
};

export type AccessoryRecord = {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  number?: Maybe<Scalars['Int']['output']>;
  quantity: Scalars['Int']['output'];
  shortTypeDescriptionEn?: Maybe<Scalars['String']['output']>;
  shortTypeDescriptionFr?: Maybe<Scalars['String']['output']>;
};

export type AccessoryRecordMobile = {
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  number?: Maybe<Scalars['Int']['output']>;
  quantity: Scalars['Int']['output'];
  shortTypeDescriptionEn?: Maybe<Scalars['String']['output']>;
  shortTypeDescriptionFr?: Maybe<Scalars['String']['output']>;
};

export type AccessoryUpdateRecord = {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  number?: Maybe<Scalars['Int']['output']>;
  quantity: Scalars['Int']['output'];
  shortTypeDescriptionEn?: Maybe<Scalars['String']['output']>;
  shortTypeDescriptionFr?: Maybe<Scalars['String']['output']>;
};

export type AdditionalTimeRecord = {
  numberOfPersons: Scalars['Int']['output'];
  timeRanges?: Maybe<Array<EmployeeDistributedTimeRangeRecord>>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION',
}

export type AttachmentInput = {
  id: Scalars['UUID']['input'];
  isDeleted: Scalars['Boolean']['input'];
  isUploaded: Scalars['Boolean']['input'];
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['Long']['input']>;
};

export type AttachmentRecord = {
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isUploaded: Scalars['Boolean']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type BaseFee = {
  employeeId: Scalars['String']['output'];
};

export type BillableTimeSheetEntryInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  completed: Scalars['Boolean']['input'];
  contractAgreementId: Scalars['UUID']['input'];
  contractAgreementNumber?: InputMaybe<Scalars['String']['input']>;
  craneOperatorId: Scalars['UUID']['input'];
  entryType?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  isDeleted: Scalars['Boolean']['input'];
  ordreDeTravailId?: InputMaybe<Scalars['String']['input']>;
  ordreDeTravailNumber?: InputMaybe<Scalars['String']['input']>;
  premiums?: InputMaybe<Array<PremiumInput>>;
  updatedAt?: InputMaybe<Scalars['Long']['input']>;
  workTimeRanges?: InputMaybe<Array<WorkTimeRangeInput>>;
};

export type BillableTimeSheetEntryUpdateInput = {
  attachments?: InputMaybe<Array<UpdateAttachmentInput>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  contractAgreementId: Scalars['UUID']['input'];
  craneOperatorId: Scalars['UUID']['input'];
  entryType?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  linkedDocumentNumber?: InputMaybe<Scalars['Int']['input']>;
  premiums?: InputMaybe<Array<PremiumCreateOrUpdateInput>>;
  workTimeRanges?: InputMaybe<Array<WorkTimeRangeCreateOrUpdateInput>>;
  workType?: InputMaybe<WorkType>;
};

export type BillingCodeRecord = {
  code: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  subCode: Scalars['Int']['output'];
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BranchForSalesPredictionRecord = {
  address?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Scalars['String']['output']>;
  dispatchBranchId?: Maybe<Scalars['UUID']['output']>;
  id: Scalars['UUID']['output'];
  isActive: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nameEn?: Maybe<Scalars['String']['output']>;
  number: Scalars['Int']['output'];
  province?: Maybe<BranchProvince>;
  salesDistribution: Scalars['Boolean']['output'];
  shortNameEn?: Maybe<Scalars['String']['output']>;
  shortNameFr?: Maybe<Scalars['String']['output']>;
};

export type BranchForSalesPredictionRecordFilterInput = {
  address?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<BranchForSalesPredictionRecordFilterInput>>;
  contact?: InputMaybe<StringOperationFilterInput>;
  dispatchBranchId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  nameEn?: InputMaybe<StringOperationFilterInput>;
  number?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<BranchForSalesPredictionRecordFilterInput>>;
  province?: InputMaybe<NullableOfBranchProvinceOperationFilterInput>;
  salesDistribution?: InputMaybe<BooleanOperationFilterInput>;
  shortNameEn?: InputMaybe<StringOperationFilterInput>;
  shortNameFr?: InputMaybe<StringOperationFilterInput>;
};

export type BranchForSalesPredictionRecordSortInput = {
  address?: InputMaybe<SortEnumType>;
  contact?: InputMaybe<SortEnumType>;
  dispatchBranchId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  nameEn?: InputMaybe<SortEnumType>;
  number?: InputMaybe<SortEnumType>;
  province?: InputMaybe<SortEnumType>;
  salesDistribution?: InputMaybe<SortEnumType>;
  shortNameEn?: InputMaybe<SortEnumType>;
  shortNameFr?: InputMaybe<SortEnumType>;
};

export enum BranchProvince {
  AB = 'aB',
  MB = 'mB',
  NB = 'nB',
  NE = 'nE',
  ON = 'oN',
  QC = 'qC',
  TN = 'tN',
}

export type BranchRecord = {
  address?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  number: Scalars['Int']['output'];
  salesDistribution: Scalars['Boolean']['output'];
};

export type BranchRecordMobile = {
  address?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  number: Scalars['Int']['output'];
  salesDistribution: Scalars['Boolean']['output'];
};

export type Checkpoint = {
  id: Scalars['UUID']['output'];
  updatedAt: Scalars['Long']['output'];
};

export type CheckpointInput = {
  id: Scalars['UUID']['input'];
  updatedAt: Scalars['Long']['input'];
};

export type Client = {
  address?: Maybe<Scalars['String']['output']>;
  billingRequirementDescription?: Maybe<Scalars['String']['output']>;
  callVolume: Scalars['Int']['output'];
  category?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  isPurchaseOrderMandatory: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  number: Scalars['Int']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  representativeId?: Maybe<Scalars['UUID']['output']>;
  totalSales?: Maybe<Scalars['Decimal']['output']>;
  trainingRequirements: Array<ClientTrainingRequirement>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ClientFilterInput = {
  address?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<ClientFilterInput>>;
  billingRequirementDescription?: InputMaybe<StringOperationFilterInput>;
  callVolume?: InputMaybe<IntOperationFilterInput>;
  category?: InputMaybe<StringOperationFilterInput>;
  contact?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  deleted?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isPurchaseOrderMandatory?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  number?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ClientFilterInput>>;
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  representativeId?: InputMaybe<UuidOperationFilterInput>;
  totalSales?: InputMaybe<DecimalOperationFilterInput>;
  trainingRequirements?: InputMaybe<ListFilterInputTypeOfClientTrainingRequirementFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type ClientForSalesPredictionRecord = {
  clientId?: Maybe<Scalars['String']['output']>;
};

export type ClientForSalesPredictionRecordFilterInput = {
  and?: InputMaybe<Array<ClientForSalesPredictionRecordFilterInput>>;
  clientId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ClientForSalesPredictionRecordFilterInput>>;
};

export type ClientForSalesPredictionRecordSortInput = {
  clientId?: InputMaybe<SortEnumType>;
};

export type ClientRecord = {
  address?: Maybe<Scalars['String']['output']>;
  billingRequirementDescription?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Scalars['String']['output']>;
  isPurchaseOrderMandatory: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  purchaseOrder?: Maybe<Scalars['String']['output']>;
  requirements?: Maybe<Array<RequirementRecord>>;
};

export type ClientRecordMobile = {
  address?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
};

export type ClientSortInput = {
  address?: InputMaybe<SortEnumType>;
  billingRequirementDescription?: InputMaybe<SortEnumType>;
  callVolume?: InputMaybe<SortEnumType>;
  category?: InputMaybe<SortEnumType>;
  contact?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  deleted?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isPurchaseOrderMandatory?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  number?: InputMaybe<SortEnumType>;
  phoneNumber?: InputMaybe<SortEnumType>;
  representativeId?: InputMaybe<SortEnumType>;
  totalSales?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type ClientTrainingRequirement = {
  clientId?: Maybe<Scalars['UUID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  isActive: Scalars['Boolean']['output'];
  organisation: Scalars['Int']['output'];
  trainingId: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ClientTrainingRequirementFilterInput = {
  and?: InputMaybe<Array<ClientTrainingRequirementFilterInput>>;
  clientId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  deleted?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ClientTrainingRequirementFilterInput>>;
  organisation?: InputMaybe<IntOperationFilterInput>;
  trainingId?: InputMaybe<UuidOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type ContractAgreementAccessoryRecord = {
  accessoryId: Scalars['UUID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  number?: Maybe<Scalars['Int']['output']>;
  quantity: Scalars['Int']['output'];
  shortTypeDescriptionEn?: Maybe<Scalars['String']['output']>;
  shortTypeDescriptionFr?: Maybe<Scalars['String']['output']>;
  used: Scalars['Boolean']['output'];
};

export type ContractAgreementAccessoryRecordMobile = {
  accessoryId: Scalars['UUID']['output'];
  description: Scalars['String']['output'];
  number?: Maybe<Scalars['Int']['output']>;
  quantity: Scalars['Int']['output'];
  shortTypeDescriptionEn?: Maybe<Scalars['String']['output']>;
  shortTypeDescriptionFr?: Maybe<Scalars['String']['output']>;
  used: Scalars['Boolean']['output'];
};

export type ContractAgreementOperatorRecord = {
  contractAgreementId?: Maybe<Scalars['UUID']['output']>;
  dailyTimeSheetId?: Maybe<Scalars['UUID']['output']>;
  employeeId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  kind: CraneOperatorKind;
  name: Scalars['String']['output'];
};

export type ContractAgreementOrdreDeTravailRecord = {
  accessories: Array<AccessoryRecord>;
  addendum?: Maybe<Scalars['String']['output']>;
  arrivalDateTime?: Maybe<Scalars['DateTime']['output']>;
  arrowLength: Scalars['Decimal']['output'];
  callReceivedFrom?: Maybe<Scalars['String']['output']>;
  client?: Maybe<ClientRecord>;
  contractAgreements?: Maybe<Array<ContractAgreementStatusRecord>>;
  contractMinimum: Scalars['Decimal']['output'];
  counterweight: Scalars['Decimal']['output'];
  crane?: Maybe<CraneRecord>;
  date: Scalars['Date']['output'];
  departureDate: Scalars['DateTime']['output'];
  departureDateTime?: Maybe<Scalars['DateTime']['output']>;
  dispatchBranch?: Maybe<BranchRecord>;
  estimatedDurationInDays: Scalars['Int']['output'];
  estimatedDurationInHours: Scalars['Int']['output'];
  gtNumber?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  jibLength: Scalars['Decimal']['output'];
  maximumWeightToLift: Scalars['Decimal']['output'];
  nextOrdreDeTravailNumber?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  offset: Scalars['Decimal']['output'];
  operators: Array<ContractAgreementOperatorRecord>;
  previousOrdreDeTravailId?: Maybe<Scalars['String']['output']>;
  previousOrdreDeTravailNumber?: Maybe<Scalars['String']['output']>;
  readyDateTime?: Maybe<Scalars['DateTime']['output']>;
  representative?: Maybe<RepresentativeRecord>;
  requiredCapacity: Scalars['Decimal']['output'];
  salesBranch?: Maybe<BranchRecord>;
  serviceCallId?: Maybe<Scalars['UUID']['output']>;
  site?: Maybe<SiteRecord>;
  status: OrdreDeTravailStatus;
  updatedAt: Scalars['Long']['output'];
  validationStatus: OrdreDeTravailValidationStatus;
  waitingForInformationComment?: Maybe<Scalars['String']['output']>;
  weekType?: Maybe<WeekType>;
  workDescription?: Maybe<Scalars['String']['output']>;
  workRadius: Scalars['Decimal']['output'];
  workType: WorkTypeRecord;
};

export type ContractAgreementRecordMobile = {
  accessories?: Maybe<Array<ContractAgreementAccessoryRecordMobile>>;
  carAllowances?: Maybe<Array<CountEmployeeDistributionRecord>>;
  comment?: Maybe<Scalars['String']['output']>;
  employeeDistributionTravellingExpenses?: Maybe<Array<EmployeeDistributedTravellingExpensesRecordMobile>>;
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isSubmitted: Scalars['Boolean']['output'];
  lunchBreaks?: Maybe<Array<DefaultTimeRangeRecordMobile>>;
  manTime?: Maybe<AdditionalTimeRecord>;
  manTransport?: Maybe<AdditionalTimeRecord>;
  meal?: Maybe<Scalars['Int']['output']>;
  meals?: Maybe<Array<CountEmployeeDistributionRecord>>;
  mechanicalFailures?: Maybe<Array<MechanicalFailureTimeRangeRecordMobile>>;
  noBreakTimes?: Maybe<Array<EmployeeDistributedDefaultTimeRangeRecord>>;
  noLunchTimes?: Maybe<Array<EmployeeDistributedDefaultTimeRangeRecord>>;
  number?: Maybe<Scalars['Int']['output']>;
  ordreDeTravailDate?: Maybe<Scalars['DateTime']['output']>;
  ordreDeTravailId: Scalars['String']['output'];
  parking: Scalars['Boolean']['output'];
  parkingDistributionIds?: Maybe<Array<Scalars['String']['output']>>;
  pension?: Maybe<PensionRecordMobile>;
  pensions?: Maybe<Array<CountEmployeeDistributionRecord>>;
  preparation?: Maybe<Scalars['Decimal']['output']>;
  preparations?: Maybe<Array<PreparationEmployeeDistributionRecord>>;
  sendRequests?: Maybe<Array<ContractAgreementSendRequestRecordMobile>>;
  serviceHours?: Maybe<Array<DistributedTimeRangeRecordMobile>>;
  signature?: Maybe<Scalars['String']['output']>;
  signedAt?: Maybe<Scalars['DateTime']['output']>;
  status: ContractAgreementStatus;
  taxis: Scalars['Boolean']['output'];
  taxisDistributionIds?: Maybe<Array<Scalars['String']['output']>>;
  travellingExpenses?: Maybe<TravellingExpensesRecordMobile>;
  updatedAt: Scalars['Long']['output'];
};

export type ContractAgreementSendRequestRecord = {
  id: Scalars['String']['output'];
  recipient: Scalars['String']['output'];
  sent: Scalars['Boolean']['output'];
  type: SendRequestType;
};

export type ContractAgreementSendRequestRecordMobile = {
  id: Scalars['String']['output'];
  recipient: Scalars['String']['output'];
  sent: Scalars['Boolean']['output'];
  type: SendRequestType;
};

export enum ContractAgreementStatus {
  Created = 'created',
  Finished = 'finished',
  Ongoing = 'ongoing',
}

export type ContractAgreementStatusRecord = {
  status: ContractAgreementStatus;
};

export type ContractAgreementStatusRecordMobile = {
  status: ContractAgreementStatus;
};

export type ContractAgreementWithOrdreDeTravailRecord = {
  accessories?: Maybe<Array<ContractAgreementAccessoryRecord>>;
  carAllowances?: Maybe<Array<CountFee>>;
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isSubmitted: Scalars['Boolean']['output'];
  isValidated: Scalars['Boolean']['output'];
  lunchBreaks?: Maybe<Array<DefaultTimeRangeRecord>>;
  manTime?: Maybe<Array<EmployeeDistributedTimeRangeRecord>>;
  manTransport?: Maybe<Array<EmployeeDistributedTimeRangeRecord>>;
  meals?: Maybe<Array<CountFee>>;
  mechanicalFailures?: Maybe<Array<MechanicalFailureTimeRangeRecord>>;
  noBreakTimes?: Maybe<Array<EmployeeDistributedTimeRangeRecord>>;
  noLunchTimes?: Maybe<Array<EmployeeDistributedTimeRangeRecord>>;
  number?: Maybe<Scalars['Int']['output']>;
  ordreDeTravail?: Maybe<ContractAgreementOrdreDeTravailRecord>;
  ordreDeTravailId: Scalars['String']['output'];
  parking?: Maybe<Array<BaseFee>>;
  pensions?: Maybe<Array<CountFee>>;
  preparations?: Maybe<Array<DurationFee>>;
  sendRequests?: Maybe<Array<ContractAgreementSendRequestRecord>>;
  serviceHours?: Maybe<Array<DistributedTimeRangeRecord>>;
  signature?: Maybe<Scalars['String']['output']>;
  signedAt?: Maybe<Scalars['DateTime']['output']>;
  status: ContractAgreementStatus;
  submittedAt?: Maybe<Scalars['DateTime']['output']>;
  submittedBy?: Maybe<UserInformationRecord>;
  taxis?: Maybe<Array<BaseFee>>;
  travellingExpenses?: Maybe<Array<TravellingFee>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CountEmployeeDistributionRecord = {
  count: Scalars['Int']['output'];
  employeeIds: Array<Scalars['String']['output']>;
};

export type CountFee = {
  count: Scalars['Int']['output'];
  employeeId: Scalars['String']['output'];
};

export type CraneOperatorForSalesPredictionRecord = {
  employeeId: Scalars['String']['output'];
  kind: CraneOperatorKind;
};

export type CraneOperatorForSalesPredictionRecordFilterInput = {
  and?: InputMaybe<Array<CraneOperatorForSalesPredictionRecordFilterInput>>;
  employeeId?: InputMaybe<StringOperationFilterInput>;
  kind?: InputMaybe<CraneOperatorKindOperationFilterInput>;
  or?: InputMaybe<Array<CraneOperatorForSalesPredictionRecordFilterInput>>;
};

export enum CraneOperatorKind {
  AdditionalMan = 'additionalMan',
  Electrician = 'electrician',
  Engineer = 'engineer',
  Helper = 'helper',
  IndustrialDesigner = 'industrialDesigner',
  Mechanic = 'mechanic',
  Operator = 'operator',
  ReplacedOperator = 'replacedOperator',
  Rigger = 'rigger',
  Signalman = 'signalman',
  SiteSupervisor = 'siteSupervisor',
  TechnicalSupervisor = 'technicalSupervisor',
  Trucker = 'trucker',
  Welder = 'welder',
}

export type CraneOperatorKindOperationFilterInput = {
  eq?: InputMaybe<CraneOperatorKind>;
  in?: InputMaybe<Array<CraneOperatorKind>>;
  neq?: InputMaybe<CraneOperatorKind>;
  nin?: InputMaybe<Array<CraneOperatorKind>>;
};

export enum CraneOperatorStartingPoint {
  Branch = 'branch',
  WorkSite = 'workSite',
}

export type CraneOperatorUpdateRecord = {
  id: Scalars['String']['output'];
  kind?: Maybe<CraneOperatorKind>;
  name: Scalars['String']['output'];
};

export type CraneRecord = {
  capacity?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  number?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
};

export type CraneRecordMobile = {
  capacity?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  number?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
};

export type CreateCraneOperatorRequestInput = {
  employeeId: Scalars['UUID']['input'];
  kind: CraneOperatorKind;
  ordreDeTravailId: Scalars['UUID']['input'];
};

export type CreateCraneOperatorResponse = {
  employeeName: Scalars['String']['output'];
  kind: CraneOperatorKind;
};

export type CustomListRequestInput = {
  dateRange?: InputMaybe<DateRangeInput>;
  dispatchBranchIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  onlyNotSubmitted: Scalars['Boolean']['input'];
  operatorsBranchIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type DailyOrdreDeTravailInformationRecord = {
  date: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  status: OrdreDeTravailStatus;
};

export type DailyTimeSheetAndEmployeeRecord = {
  billableTimeSheetEntries: Array<GetDailyTimeSheetTimeSheetEntryRecord>;
  date: Scalars['Date']['output'];
  employee: Scalars['String']['output'];
  employeeNumber: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  nonBillableTimeSheetEntry?: Maybe<GetDailyTimeSheetTimeSheetEntryRecord>;
  number: Scalars['Int']['output'];
  submittedAt?: Maybe<Scalars['DateTime']['output']>;
  submittedBy?: Maybe<UserInformationRecord>;
  timeSheetId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type DailyTimeSheetInput = {
  billableTimeSheetEntries?: InputMaybe<Array<BillableTimeSheetEntryInput>>;
  date: Scalars['DateTime']['input'];
  employeeId?: InputMaybe<Scalars['UUID']['input']>;
  id: Scalars['UUID']['input'];
  isDeleted: Scalars['Boolean']['input'];
  nonBillableTimeSheetEntry?: InputMaybe<NonBillableTimeSheetEntryInput>;
  updatedAt?: InputMaybe<Scalars['Long']['input']>;
};

export type DailyTimeSheetRecord = {
  billableTimeSheetEntries: Array<TimeSheetEntryRecord>;
  completed: Scalars['Boolean']['output'];
  date: Scalars['Date']['output'];
  employeeId: Scalars['UUID']['output'];
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  nonBillableTimeSheetEntry?: Maybe<TimeSheetEntryRecord>;
  updatedAt: Scalars['Long']['output'];
};

export enum DailyTimeSheetStatus {
  Completed = 'completed',
  NotStarted = 'notStarted',
  Ongoing = 'ongoing',
}

export enum DailyTimeSheetType {
  Linked = 'linked',
  Mixed = 'mixed',
  NonLinked = 'nonLinked',
}

export type DailyTimeSheetUpdateInput = {
  billableTimeSheetEntries?: InputMaybe<Array<BillableTimeSheetEntryUpdateInput>>;
  dailyTimeSheetId: Scalars['String']['input'];
  nonBillableTimeSheetEntry?: InputMaybe<NonBillableTimeSheetEntryUpdateInput>;
};

export type DateOperationFilterInput = {
  eq?: InputMaybe<Scalars['Date']['input']>;
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  neq?: InputMaybe<Scalars['Date']['input']>;
  ngt?: InputMaybe<Scalars['Date']['input']>;
  ngte?: InputMaybe<Scalars['Date']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  nlt?: InputMaybe<Scalars['Date']['input']>;
  nlte?: InputMaybe<Scalars['Date']['input']>;
};

export type DateRangeInput = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  neq?: InputMaybe<Scalars['Decimal']['input']>;
  ngt?: InputMaybe<Scalars['Decimal']['input']>;
  ngte?: InputMaybe<Scalars['Decimal']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  nlt?: InputMaybe<Scalars['Decimal']['input']>;
  nlte?: InputMaybe<Scalars['Decimal']['input']>;
};

export type DefaultTimeRangeRecord = {
  durationInMinutes: Scalars['Int']['output'];
  from: Scalars['String']['output'];
  startsOnNextDay: Scalars['Boolean']['output'];
};

export type DefaultTimeRangeRecordMobile = {
  durationInMinutes: Scalars['Int']['output'];
  from: Scalars['String']['output'];
  startsOnNextDay: Scalars['Boolean']['output'];
};

export enum DistanceFeeRange {
  EightyNineToOneHundredNineteen = 'eightyNineToOneHundredNineteen',
  FortyNineToSeventyTwo = 'fortyNineToSeventyTwo',
  OneHundredTwentyOrMore = 'oneHundredTwentyOrMore',
  SeventyThreeToEightyEight = 'seventyThreeToEightyEight',
  SiteTransfer = 'siteTransfer',
}

export type DistributedTimeRangeRecord = {
  durationInMinutes: Scalars['Int']['output'];
  from: Scalars['String']['output'];
  startsOnNextDay: Scalars['Boolean']['output'];
  timeEntryInformation: TimeEntryInformationRecord;
};

export type DistributedTimeRangeRecordMobile = {
  durationInMinutes: Scalars['Int']['output'];
  from: Scalars['String']['output'];
  startsOnNextDay: Scalars['Boolean']['output'];
  timeEntryInformation: TimeEntryInformationRecordMobile;
};

export type DurationFee = {
  duration: Scalars['Decimal']['output'];
  employeeId: Scalars['String']['output'];
};

export type EmployeeDistributedDefaultTimeRangeRecord = {
  durationInMinutes: Scalars['Int']['output'];
  employeeIds: Array<Scalars['String']['output']>;
  from: Scalars['String']['output'];
  startsOnNextDay: Scalars['Boolean']['output'];
};

export type EmployeeDistributedTimeRangeRecord = {
  durationInMinutes: Scalars['Int']['output'];
  employeeIds: Array<Scalars['String']['output']>;
  from: Scalars['String']['output'];
  startsOnNextDay: Scalars['Boolean']['output'];
  timeEntryInformation: TimeEntryInformationRecord;
};

export type EmployeeDistributedTravellingExpensesRecordMobile = {
  distanceTravelled: DistanceFeeRange;
  employeeIds: Array<Scalars['String']['output']>;
  extendedDistance?: Maybe<Scalars['Decimal']['output']>;
};

export type EmployeeServiceCallNotificationResponse = {
  count: Scalars['Int']['output'];
  notification?: Maybe<ServiceCallNotificationRecord>;
};

export type EmployeeTimeSheetEntriesRecord = {
  employeeName?: Maybe<Scalars['String']['output']>;
  entries: Array<EmployeeTimeSheetEntryRecord>;
};

export type EmployeeTimeSheetEntryRecord = {
  dailyTimeSheetId: Scalars['String']['output'];
  date: Scalars['Date']['output'];
  dispatchBranch?: Maybe<Scalars['Int']['output']>;
  employeeBranch?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  jobCode: Scalars['String']['output'];
  type: TimeSheetEntryType;
  validationStatus: TimeSheetEntryStatus;
};

export type EquipmentTypeRecord = {
  abbreviation?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['Int']['output']>;
  sequence?: Maybe<Scalars['Decimal']['output']>;
};

export enum FournisseurType {
  Other = 'other',
  Transporter = 'transporter',
}

export type GenerateContractAgreementNumberRecord = {
  number: Scalars['Int']['output'];
  status: ContractAgreementStatus;
};

export type GetAllDailyTimeSheetsRecord = {
  branchNumber?: Maybe<Scalars['Int']['output']>;
  craneOperator: Scalars['String']['output'];
  date: Scalars['Date']['output'];
  effectiveDailyTimeSheetValidationStatus?: Maybe<TimeSheetEntryValidationStatus>;
  id?: Maybe<Scalars['String']['output']>;
  jobCode: JobCode;
  status: DailyTimeSheetStatus;
  timeSheetId?: Maybe<Scalars['String']['output']>;
  type: DailyTimeSheetType;
};

export type GetAllEmployeeRecord = {
  fullName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
};

export type GetAllEmployeesFilterInput = {
  jobCode?: InputMaybe<JobCode>;
};

export type GetAllOrdreDeTravailNotificationsResponse = {
  count: Scalars['Int']['output'];
  items: Array<NotificationAcknowledgementRecord>;
};

export type GetAllOrdresDeTravailForValidationListItem = {
  branch: Scalars['Int']['output'];
  client: Scalars['String']['output'];
  contractMinimum: Scalars['Decimal']['output'];
  craneNumber: Scalars['String']['output'];
  craneOperators: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  maxDate: Scalars['Date']['output'];
  maximumWeightToLift: Scalars['Decimal']['output'];
  minDate: Scalars['Date']['output'];
  number: Scalars['String']['output'];
  operatorsBranches: Array<Maybe<Scalars['Int']['output']>>;
  requiredCapacity: Scalars['Decimal']['output'];
  salesBranch: Scalars['Int']['output'];
  site: Scalars['String']['output'];
  status?: Maybe<OrdreDeTravailStatus>;
  validationStatus?: Maybe<OrdreDeTravailValidationStatus>;
  waitingForInformationComment: Scalars['String']['output'];
  workDescription: Scalars['String']['output'];
  workType: WorkType;
};

export type GetAllOrdresDeTravailForValidationResponse = {
  count: Scalars['Int']['output'];
  items: Array<GetAllOrdresDeTravailForValidationListItem>;
};

export type GetAllOrdresDeTravailResponse = {
  count: Scalars['Int']['output'];
  items: Array<GetAllOrdresDeTravailResponseListItem>;
};

export type GetAllOrdresDeTravailResponseListItem = {
  clientName?: Maybe<Scalars['String']['output']>;
  contractMinimum: Scalars['Decimal']['output'];
  craneNumber?: Maybe<Scalars['String']['output']>;
  craneOperators: Scalars['String']['output'];
  dispatchBranch?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  maxDate: Scalars['Date']['output'];
  maximumWeightToLift: Scalars['Decimal']['output'];
  minDate: Scalars['Date']['output'];
  number: Scalars['String']['output'];
  representativeName: Scalars['String']['output'];
  requiredCapacity: Scalars['Decimal']['output'];
  serviceCallFriendlyId?: Maybe<Scalars['String']['output']>;
  siteName: Scalars['String']['output'];
  status?: Maybe<OrdreDeTravailStatus>;
  workDescription: Scalars['String']['output'];
  workType: WorkType;
};

export type GetAllPaginatedContractAgreementRecord = {
  comment?: Maybe<Scalars['String']['output']>;
  contractAgreementStatus: ContractAgreementStatus;
  id: Scalars['String']['output'];
  number?: Maybe<Scalars['Int']['output']>;
  ordreDeTravail: OrdreDeTravailForContractAgreementRecord;
};

export type GetAllPaginatedDailyTimeSheetsResponse = {
  count: Scalars['Int']['output'];
  items: Array<GetAllDailyTimeSheetsRecord>;
};

export type GetAllPaginatedResponse = {
  count: Scalars['Int']['output'];
  items: Array<GetAllPaginatedContractAgreementRecord>;
};

export type GetAllPaginatedTimeSheetEntriesResponse = {
  count: Scalars['Int']['output'];
  items: Array<EmployeeTimeSheetEntriesRecord>;
};

export type GetAllTimeSheetsValidationRecordsResponse = {
  count: Scalars['Int']['output'];
  items: Array<GetAllDailyTimeSheetsRecord>;
};

export type GetAllWorkSitesRecord = {
  code?: Maybe<Scalars['String']['output']>;
  contact: Scalars['String']['output'];
  id: Scalars['String']['output'];
  location: Scalars['String']['output'];
  mobilePhoneNumber: Scalars['String']['output'];
  name: Scalars['String']['output'];
  number?: Maybe<Scalars['Int']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type GetDailyTimeSheetAttachmentRecord = {
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isUploaded: Scalars['Boolean']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GetDailyTimeSheetPremiumRecord = {
  amount?: Maybe<Scalars['Decimal']['output']>;
  durationInMinutes?: Maybe<Scalars['Decimal']['output']>;
  extendedDistance?: Maybe<Scalars['Decimal']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  gtNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isSubmitted?: Maybe<Scalars['Boolean']['output']>;
  numberOfDays?: Maybe<Scalars['Int']['output']>;
  numberOfHours?: Maybe<Scalars['Int']['output']>;
  numberOfPersons?: Maybe<Scalars['Int']['output']>;
  posteDeTravail?: Maybe<PosteDeTravailRecord>;
  salesBranchNumber?: Maybe<Scalars['Int']['output']>;
  startsOnNextDay: Scalars['Boolean']['output'];
  timeEntryInformation?: Maybe<TimeEntryInformationRecord>;
  type: Scalars['String']['output'];
  unitNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  workOrderNumber?: Maybe<Scalars['String']['output']>;
};

export type GetDailyTimeSheetRecord = {
  billableTimeSheetEntries: Array<GetDailyTimeSheetTimeSheetEntryRecord>;
  date: Scalars['Date']['output'];
  employee: Scalars['String']['output'];
  employeeNumber: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  nonBillableTimeSheetEntry?: Maybe<GetDailyTimeSheetTimeSheetEntryRecord>;
  number: Scalars['Int']['output'];
  submittedAt?: Maybe<Scalars['DateTime']['output']>;
  submittedBy?: Maybe<UserInformationRecord>;
  timeSheetId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GetDailyTimeSheetTimeSheetEntryRecord = {
  attachments?: Maybe<Array<GetDailyTimeSheetAttachmentRecord>>;
  client?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  completed: Scalars['Boolean']['output'];
  contractAgreementId?: Maybe<Scalars['String']['output']>;
  contractAgreementNumber?: Maybe<Scalars['String']['output']>;
  craneNumber?: Maybe<Scalars['String']['output']>;
  craneOperatorId?: Maybe<Scalars['String']['output']>;
  entryType: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isLinkedToDocument: Scalars['Boolean']['output'];
  isSubmitted: Scalars['Boolean']['output'];
  isValidated: Scalars['Boolean']['output'];
  manuallyCreated: Scalars['Boolean']['output'];
  number?: Maybe<Scalars['Int']['output']>;
  ordreDeTravailId?: Maybe<Scalars['String']['output']>;
  ordreDeTravailNumber?: Maybe<Scalars['String']['output']>;
  premiums?: Maybe<Array<GetDailyTimeSheetPremiumRecord>>;
  salesBranchNumber?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  validationStatus: TimeSheetEntryValidationStatus;
  workTimeRanges?: Maybe<Array<GetDailyTimeSheetWorkTimeRangeRecord>>;
  workType?: Maybe<WorkType>;
  workTypeNumber?: Maybe<Scalars['Int']['output']>;
};

export type GetDailyTimeSheetWorkTimeRangeRecord = {
  durationInMinutes: Scalars['Int']['output'];
  from: Scalars['String']['output'];
  gtNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isSubmitted: Scalars['Boolean']['output'];
  posteDeTravail?: Maybe<PosteDeTravailRecord>;
  salesBranchNumber?: Maybe<Scalars['Int']['output']>;
  startsOnNextDay: Scalars['Boolean']['output'];
  timeEntryInformation?: Maybe<TimeEntryInformationRecord>;
  unitNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** @deprecated Use the value in the nested Poste property */
  workKind?: Maybe<WorkKind>;
  /** @deprecated Use the value in the nested Poste property */
  workKindNumber?: Maybe<Scalars['Int']['output']>;
  workOrderNumber?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the value in the nested Poste property */
  workType: WorkType;
  /** @deprecated Use the value in the nested Poste property */
  workTypeNumber: Scalars['Int']['output'];
};

export type GetEmployeeServiceCallNotificationsResponse = {
  count: Scalars['Int']['output'];
  notifications: Array<ServiceCallNotificationRecord>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

export enum JobCode {
  Administration = 'administration',
  Charpentier = 'charpentier',
  Chaudronnier = 'chaudronnier',
  Electricien = 'electricien',
  Grutier = 'grutier',
  GrutierClasseA = 'grutierClasseA',
  GrutierClasseADeuxiemeHomme = 'grutierClasseADeuxiemeHomme',
  GrutierClasseB = 'grutierClasseB',
  MecanicienAscenseur = 'mecanicienAscenseur',
  MecanicienChantier = 'mecanicienChantier',
  MecanicienMachineriesLourdes = 'mecanicienMachineriesLourdes',
  MonteurAcierStructure = 'monteurAcierStructure',
  OperateurEquipementLourd = 'operateurEquipementLourd',
}

export enum LinkOperator {
  And = 'and',
  Or = 'or',
}

export type ListFilterInputTypeOfClientTrainingRequirementFilterInput = {
  all?: InputMaybe<ClientTrainingRequirementFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ClientTrainingRequirementFilterInput>;
  some?: InputMaybe<ClientTrainingRequirementFilterInput>;
};

export type ListFilterInputTypeOfCraneOperatorForSalesPredictionRecordFilterInput = {
  all?: InputMaybe<CraneOperatorForSalesPredictionRecordFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CraneOperatorForSalesPredictionRecordFilterInput>;
  some?: InputMaybe<CraneOperatorForSalesPredictionRecordFilterInput>;
};

export type ListRequestInput = {
  filters?: InputMaybe<Array<Scalars['String']['input']>>;
  linkOperator?: InputMaybe<LinkOperator>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ListRequestWithBranchTypeIdsFilterInput = {
  dateRange?: InputMaybe<DateRangeInput>;
  dispatchBranchIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  filters?: InputMaybe<Array<Scalars['String']['input']>>;
  linkOperator?: InputMaybe<LinkOperator>;
  operatorsBranchIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ListRequestWithDateInput = {
  date: Scalars['Date']['input'];
  filters?: InputMaybe<Array<Scalars['String']['input']>>;
  linkOperator?: InputMaybe<LinkOperator>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ListRequestWithDateRangeInput = {
  dateRange?: InputMaybe<DateRangeInput>;
  filters?: InputMaybe<Array<Scalars['String']['input']>>;
  linkOperator?: InputMaybe<LinkOperator>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type MechanicalFailureTimeRangeRecord = {
  comment?: Maybe<Scalars['String']['output']>;
  durationInMinutes: Scalars['Int']['output'];
  from: Scalars['String']['output'];
  startsOnNextDay?: Maybe<Scalars['Boolean']['output']>;
};

export type MechanicalFailureTimeRangeRecordMobile = {
  comment?: Maybe<Scalars['String']['output']>;
  durationInMinutes: Scalars['Int']['output'];
  from: Scalars['String']['output'];
  startsOnNextDay?: Maybe<Scalars['Boolean']['output']>;
};

export type Mutation = {
  addCraneOperator: CreateCraneOperatorResponse;
  generateContractAgreementNumber: GenerateContractAgreementNumberRecord;
  pushContractAgreements: Array<ContractAgreementRecordMobile>;
  pushDailyTimeSheets: Array<DailyTimeSheetRecord>;
  pushNotifications: Array<Notification>;
  pushOrdresDeTravail: Array<OrdreDeTravailRecordMobile>;
  removeDevice?: Maybe<Scalars['String']['output']>;
  submitDailyTimeSheet: SubmitDailyTimeSheetMutationResponse;
  updateContractAgreement: UpdateContractAgreementRecord;
  updateDevices?: Maybe<Scalars['String']['output']>;
  updateOrdreDeTravail?: Maybe<OrdreDeTravailRecord>;
  updateServiceCallUpdatedNotification: ServiceCallNotificationRecord;
  updateStatus?: Maybe<OrdreDeTravailStatus>;
  updateTimeSheet?: Maybe<DailyTimeSheetAndEmployeeRecord>;
  updateWeekType?: Maybe<WeekType>;
};

export type MutationAddCraneOperatorArgs = {
  createCraneOperatorRequest: CreateCraneOperatorRequestInput;
};

export type MutationGenerateContractAgreementNumberArgs = {
  contractAgreementId: Scalars['String']['input'];
};

export type MutationPushContractAgreementsArgs = {
  contractAgreements: Array<PushRowContractAgreementInput>;
};

export type MutationPushDailyTimeSheetsArgs = {
  dailyTimeSheets: Array<PushRowDailyTimeSheetInput>;
};

export type MutationPushNotificationsArgs = {
  notifications: Array<PushRowNotificationInput>;
};

export type MutationPushOrdresDeTravailArgs = {
  ordresDeTravail: Array<PushRowOrdreDeTravailInput>;
};

export type MutationRemoveDeviceArgs = {
  token: Scalars['String']['input'];
};

export type MutationSubmitDailyTimeSheetArgs = {
  input: SubmitDailyTimeSheetInput;
};

export type MutationUpdateContractAgreementArgs = {
  contractAgreementInput: SyncContractAgreementInput;
};

export type MutationUpdateDevicesArgs = {
  deviceId?: InputMaybe<Scalars['UUID']['input']>;
  languageCode: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationUpdateOrdreDeTravailArgs = {
  id: Scalars['UUID']['input'];
  ordreDeTravailInput: OrdreDeTravailUpdateInput;
};

export type MutationUpdateServiceCallUpdatedNotificationArgs = {
  id: Scalars['UUID']['input'];
  notificationInput: ServiceCallUpdatedNotificationInput;
};

export type MutationUpdateStatusArgs = {
  id: Scalars['UUID']['input'];
  status: OrdreDeTravailStatus;
};

export type MutationUpdateTimeSheetArgs = {
  timeSheetInput: DailyTimeSheetUpdateInput;
};

export type MutationUpdateWeekTypeArgs = {
  id: Scalars['UUID']['input'];
  weekType?: InputMaybe<WeekType>;
};

export type NonBillableTimeSheetEntryInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  completed: Scalars['Boolean']['input'];
  entryType?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  premiums?: InputMaybe<Array<PremiumInput>>;
  updatedAt?: InputMaybe<Scalars['Long']['input']>;
  workTimeRanges?: InputMaybe<Array<WorkTimeRangeInput>>;
};

export type NonBillableTimeSheetEntryUpdateInput = {
  attachments?: InputMaybe<Array<UpdateAttachmentInput>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  entryType?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  linkedDocumentNumber?: InputMaybe<Scalars['Int']['input']>;
  premiums?: InputMaybe<Array<PremiumCreateOrUpdateInput>>;
  workTimeRanges?: InputMaybe<Array<WorkTimeRangeCreateOrUpdateInput>>;
  workType?: InputMaybe<WorkType>;
};

export type Notification = {
  createdAt: Scalars['DateTime']['output'];
  employeeId: Scalars['UUID']['output'];
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  status: NotificationStatus;
  type: NotificationType;
  updatedAt: Scalars['Long']['output'];
};

export type NotificationAcknowledgementRecord = {
  employeeFullName: Scalars['String']['output'];
  lastNotificationType: Scalars['String']['output'];
  notificationId: Scalars['String']['output'];
  notificationStatus: NotificationStatus;
  operatorBranchNumber?: Maybe<Scalars['Int']['output']>;
  ordreDeTravail: OrdreDeTravailForNotificationAcknowledgementRecord;
};

export enum NotificationStatus {
  Confirmed = 'confirmed',
  Read = 'read',
  Received = 'received',
  Sent = 'sent',
}

export enum NotificationType {
  AccessoryReminder = 'accessoryReminder',
  CancelledOrdreDeTravail = 'cancelledOrdreDeTravail',
  NewOrdreDeTravail = 'newOrdreDeTravail',
  OrdreDeTravailContinuity = 'ordreDeTravailContinuity',
  UnassignedOrdreDeTravail = 'unassignedOrdreDeTravail',
  UpdatedOrdreDeTravail = 'updatedOrdreDeTravail',
}

export type NullableOfBranchProvinceOperationFilterInput = {
  eq?: InputMaybe<BranchProvince>;
  in?: InputMaybe<Array<InputMaybe<BranchProvince>>>;
  neq?: InputMaybe<BranchProvince>;
  nin?: InputMaybe<Array<InputMaybe<BranchProvince>>>;
};

export type OperatorRecord = {
  arrivalDateTime?: Maybe<Scalars['DateTime']['output']>;
  confirmation: Scalars['Boolean']['output'];
  departureDateTime?: Maybe<Scalars['DateTime']['output']>;
  emailAddress: Scalars['String']['output'];
  employeeId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isCurrentUser: Scalars['Boolean']['output'];
  kind: CraneOperatorKind;
  lastName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  startingPoint?: Maybe<CraneOperatorStartingPoint>;
};

export type OperatorRecordMobile = {
  confirmation: Scalars['Boolean']['output'];
  contractAgreementId?: Maybe<Scalars['String']['output']>;
  emailAddress: Scalars['String']['output'];
  employeeId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isCurrentUser: Scalars['Boolean']['output'];
  kind: CraneOperatorKind;
  lastName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum OrdreDeTravailAddendumKind {
  Archived = 'archived',
  ClientOrWorksite = 'clientOrWorksite',
  CraneOperator = 'craneOperator',
  Dispatching = 'dispatching',
  InternalNote = 'internalNote',
  Invoicing = 'invoicing',
  Quote = 'quote',
  SalesSupervisor = 'salesSupervisor',
  SupervisionNote = 'supervisionNote',
}

export type OrdreDeTravailAddendumRecord = {
  description: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  kind: OrdreDeTravailAddendumKind;
  manuallyCreated: Scalars['Boolean']['output'];
};

export enum OrdreDeTravailAttachmentKind {
  AssemblySequence = 'assemblySequence',
  Certifications = 'certifications',
  Charters = 'charters',
  CompetenceCards = 'competenceCards',
  GroundPressureCalculations = 'groundPressureCalculations',
  InsuranceDeclaration = 'insuranceDeclaration',
  LiftingPlan = 'liftingPlan',
  ListOfTransports = 'listOfTransports',
  Location = 'location',
  Others = 'others',
  ParkingPermit = 'parkingPermit',
  Permit = 'permit',
  Photos = 'photos',
  ProjectDocuments = 'projectDocuments',
  RouteSpecifications = 'routeSpecifications',
  SignalingPermit = 'signalingPermit',
  StabilityStudy = 'stabilityStudy',
  TechnicalDrawings = 'technicalDrawings',
  TransportPermit = 'transportPermit',
  TravelSpecifications = 'travelSpecifications',
  WorkMethodEventSealedByEngineer = 'workMethodEventSealedByEngineer',
}

export type OrdreDeTravailAttachmentRecord = {
  displayName: Scalars['String']['output'];
  downloadUrl: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  kind: OrdreDeTravailAttachmentKind;
  manuallyCreated: Scalars['Boolean']['output'];
  mimeType: Scalars['String']['output'];
  uri: Scalars['String']['output'];
};

export type OrdreDeTravailForContractAgreementRecord = {
  client?: Maybe<ClientRecord>;
  crane?: Maybe<CraneRecord>;
  date: Scalars['Date']['output'];
  dispatchBranch?: Maybe<BranchRecord>;
  id: Scalars['String']['output'];
  number: Scalars['String']['output'];
  operators: Array<OperatorRecord>;
  representative?: Maybe<RepresentativeRecord>;
  salesBranch?: Maybe<BranchRecord>;
  site?: Maybe<SiteRecord>;
  status: OrdreDeTravailStatus;
  workDescription?: Maybe<Scalars['String']['output']>;
  workType: WorkTypeRecord;
};

export type OrdreDeTravailForNotificationAcknowledgementRecord = {
  clientName?: Maybe<Scalars['String']['output']>;
  craneNumber?: Maybe<Scalars['String']['output']>;
  date: Scalars['Date']['output'];
  dispatchBranchNumber?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  number: Scalars['String']['output'];
  representativeName: Scalars['String']['output'];
  salesBranchNumber?: Maybe<Scalars['Int']['output']>;
  siteName?: Maybe<Scalars['String']['output']>;
  status: OrdreDeTravailStatus;
  workDescription: Scalars['String']['output'];
  workTypeValue: WorkType;
};

export type OrdreDeTravailForSalesPrediction = {
  arrivalDateTime?: Maybe<Scalars['DateTime']['output']>;
  client: ClientForSalesPredictionRecord;
  /** @deprecated Use `MainEquipmentId` instead */
  craneId?: Maybe<Scalars['UUID']['output']>;
  craneOperators: Array<CraneOperatorForSalesPredictionRecord>;
  date: Scalars['Date']['output'];
  departureDateTime?: Maybe<Scalars['DateTime']['output']>;
  dispatchBranch?: Maybe<BranchForSalesPredictionRecord>;
  id: Scalars['String']['output'];
  mainEquipmentId?: Maybe<Scalars['UUID']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  salesBranch?: Maybe<BranchForSalesPredictionRecord>;
  status: OrdreDeTravailStatus;
  type: OrdreDeTravailType;
};

export type OrdreDeTravailForSalesPredictionFilterInput = {
  and?: InputMaybe<Array<OrdreDeTravailForSalesPredictionFilterInput>>;
  arrivalDateTime?: InputMaybe<DateTimeOperationFilterInput>;
  client?: InputMaybe<ClientForSalesPredictionRecordFilterInput>;
  craneId?: InputMaybe<UuidOperationFilterInput>;
  craneOperators?: InputMaybe<ListFilterInputTypeOfCraneOperatorForSalesPredictionRecordFilterInput>;
  date?: InputMaybe<DateOperationFilterInput>;
  departureDateTime?: InputMaybe<DateTimeOperationFilterInput>;
  dispatchBranch?: InputMaybe<BranchForSalesPredictionRecordFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  mainEquipmentId?: InputMaybe<UuidOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrdreDeTravailForSalesPredictionFilterInput>>;
  salesBranch?: InputMaybe<BranchForSalesPredictionRecordFilterInput>;
  status?: InputMaybe<OrdreDeTravailStatusOperationFilterInput>;
  type?: InputMaybe<OrdreDeTravailTypeOperationFilterInput>;
};

export type OrdreDeTravailForSalesPredictionSortInput = {
  arrivalDateTime?: InputMaybe<SortEnumType>;
  client?: InputMaybe<ClientForSalesPredictionRecordSortInput>;
  craneId?: InputMaybe<SortEnumType>;
  date?: InputMaybe<SortEnumType>;
  departureDateTime?: InputMaybe<SortEnumType>;
  dispatchBranch?: InputMaybe<BranchForSalesPredictionRecordSortInput>;
  id?: InputMaybe<SortEnumType>;
  mainEquipmentId?: InputMaybe<SortEnumType>;
  number?: InputMaybe<SortEnumType>;
  salesBranch?: InputMaybe<BranchForSalesPredictionRecordSortInput>;
  status?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
};

export type OrdreDeTravailFournisseurRecord = {
  billingCode?: Maybe<BillingCodeRecord>;
  departureDateTime?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  fournisseurId?: Maybe<Scalars['UUID']['output']>;
  id: Scalars['UUID']['output'];
  isPlanned: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  numeroDeCommandeAchat: Scalars['String']['output'];
  type: FournisseurType;
};

export type OrdreDeTravailNotification = Notification & {
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['Date']['output'];
  employeeId: Scalars['UUID']['output'];
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  number: Scalars['String']['output'];
  ordreDeTravailId: Scalars['String']['output'];
  status: NotificationStatus;
  type: NotificationType;
  updatedAt: Scalars['Long']['output'];
};

export type OrdreDeTravailRecord = {
  accessories: Array<AccessoryRecord>;
  addenda: Array<OrdreDeTravailAddendumRecord>;
  arrivalDateTime?: Maybe<Scalars['DateTime']['output']>;
  arrowLength: Scalars['Decimal']['output'];
  attachments: Array<OrdreDeTravailAttachmentRecord>;
  callReceivedFrom?: Maybe<Scalars['String']['output']>;
  client?: Maybe<ClientRecord>;
  contractMinimum: Scalars['Decimal']['output'];
  counterweight: Scalars['Decimal']['output'];
  crane?: Maybe<CraneRecord>;
  date: Scalars['Date']['output'];
  departureDate: Scalars['DateTime']['output'];
  departureDateTime?: Maybe<Scalars['DateTime']['output']>;
  dispatchBranch?: Maybe<BranchRecord>;
  estimatedDurationInDays: Scalars['Int']['output'];
  estimatedDurationInHours: Scalars['Int']['output'];
  fournisseurs: Array<OrdreDeTravailFournisseurRecord>;
  gtNumber?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isEstablishedSchedule: Scalars['Boolean']['output'];
  isSecondServing: Scalars['Boolean']['output'];
  jibLength: Scalars['Decimal']['output'];
  maximumWeightToLift: Scalars['Decimal']['output'];
  nextOrdreDeTravailId?: Maybe<Scalars['String']['output']>;
  nextOrdreDeTravailNumber?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  offset: Scalars['Decimal']['output'];
  operators: Array<OperatorRecord>;
  preferredEquipmentType?: Maybe<EquipmentTypeRecord>;
  preventReplacement: Scalars['Boolean']['output'];
  preventSubcontracting: Scalars['Boolean']['output'];
  previousOrdreDeTravailId?: Maybe<Scalars['String']['output']>;
  previousOrdreDeTravailNumber?: Maybe<Scalars['String']['output']>;
  readyDateTime?: Maybe<Scalars['DateTime']['output']>;
  representative?: Maybe<RepresentativeRecord>;
  requiredCapacity: Scalars['Decimal']['output'];
  salesBranch?: Maybe<BranchRecord>;
  serviceCall?: Maybe<ServiceCallRecord>;
  site?: Maybe<SiteRecord>;
  status: OrdreDeTravailStatus;
  subcontractor?: Maybe<OrdreDeTravailSubcontractorRecord>;
  type: OrdreDeTravailType;
  updatedAt: Scalars['Long']['output'];
  validationStatus: OrdreDeTravailValidationStatus;
  waitingForInformationComment?: Maybe<Scalars['String']['output']>;
  weekType?: Maybe<WeekType>;
  workDescription?: Maybe<Scalars['String']['output']>;
  workRadius: Scalars['Decimal']['output'];
  workType: WorkTypeRecord;
  workTypeCategory?: Maybe<WorkTypeCategory>;
};

export type OrdreDeTravailRecordMobile = {
  accessories: Array<AccessoryRecordMobile>;
  addendum?: Maybe<Scalars['String']['output']>;
  arrivalDateTime?: Maybe<Scalars['DateTime']['output']>;
  arrowLength: Scalars['Decimal']['output'];
  callReceivedFrom?: Maybe<Scalars['String']['output']>;
  client?: Maybe<ClientRecordMobile>;
  contractAgreements?: Maybe<Array<ContractAgreementStatusRecordMobile>>;
  contractMinimum: Scalars['Decimal']['output'];
  counterweight: Scalars['Decimal']['output'];
  crane?: Maybe<CraneRecordMobile>;
  date: Scalars['Date']['output'];
  departureDate: Scalars['DateTime']['output'];
  departureDateTime?: Maybe<Scalars['DateTime']['output']>;
  dispatchBranch?: Maybe<BranchRecordMobile>;
  estimatedDurationInDays: Scalars['Int']['output'];
  estimatedDurationInHours: Scalars['Int']['output'];
  gtNumber?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  jibLength: Scalars['Decimal']['output'];
  maximumWeightToLift: Scalars['Decimal']['output'];
  nextOrdreDeTravailNumber?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  offset: Scalars['Decimal']['output'];
  operators: Array<OperatorRecordMobile>;
  previousOrdreDeTravailId?: Maybe<Scalars['String']['output']>;
  previousOrdreDeTravailNumber?: Maybe<Scalars['String']['output']>;
  readyDateTime?: Maybe<Scalars['DateTime']['output']>;
  representative?: Maybe<RepresentativeRecordMobile>;
  requiredCapacity: Scalars['Decimal']['output'];
  salesBranch?: Maybe<BranchRecordMobile>;
  serviceCallId?: Maybe<Scalars['UUID']['output']>;
  site?: Maybe<SiteRecordMobile>;
  status: OrdreDeTravailStatus;
  updatedAt: Scalars['Long']['output'];
  validationStatus: OrdreDeTravailValidationStatus;
  waitingForInformationComment?: Maybe<Scalars['String']['output']>;
  weekType?: Maybe<WeekType>;
  workDescription?: Maybe<Scalars['String']['output']>;
  workRadius: Scalars['Decimal']['output'];
  workType: WorkTypeRecordMobile;
};

export enum OrdreDeTravailStatus {
  Cancelled = 'cancelled',
  Dispatching = 'dispatching',
  External = 'external',
  Finished = 'finished',
  Modified = 'modified',
  Ongoing = 'ongoing',
  Upcoming = 'upcoming',
}

export type OrdreDeTravailStatusOperationFilterInput = {
  eq?: InputMaybe<OrdreDeTravailStatus>;
  in?: InputMaybe<Array<OrdreDeTravailStatus>>;
  neq?: InputMaybe<OrdreDeTravailStatus>;
  nin?: InputMaybe<Array<OrdreDeTravailStatus>>;
};

export type OrdreDeTravailSubcontractorRecord = {
  description: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  numeroDeCommandeAchat: Scalars['String']['output'];
};

export enum OrdreDeTravailType {
  AccessoryRental = 'accessoryRental',
  Bare = 'bare',
  CraneOperatorLaborRental = 'craneOperatorLaborRental',
  LaborRental = 'laborRental',
  LaborRentalWithCrane = 'laborRentalWithCrane',
  LiftingPlan = 'liftingPlan',
  LiftingTest = 'liftingTest',
  OperatedHourly = 'operatedHourly',
  OperatedMonthly = 'operatedMonthly',
  RollingEquipment = 'rollingEquipment',
}

export type OrdreDeTravailTypeOperationFilterInput = {
  eq?: InputMaybe<OrdreDeTravailType>;
  in?: InputMaybe<Array<OrdreDeTravailType>>;
  neq?: InputMaybe<OrdreDeTravailType>;
  nin?: InputMaybe<Array<OrdreDeTravailType>>;
};

export type OrdreDeTravailUpdateAddendumInput = {
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  kind: OrdreDeTravailAddendumKind;
  manuallyCreated: Scalars['Boolean']['input'];
};

export type OrdreDeTravailUpdateAttachmentInput = {
  displayName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  kind: OrdreDeTravailAttachmentKind;
  manuallyCreated: Scalars['Boolean']['input'];
  mimeType: Scalars['String']['input'];
  uri: Scalars['String']['input'];
};

export type OrdreDeTravailUpdateFournisseurInput = {
  billingCodeId?: InputMaybe<Scalars['UUID']['input']>;
  departureDateTime?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  fournisseurId?: InputMaybe<Scalars['UUID']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  isPlanned: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  numeroDeCommandeAchat: Scalars['String']['input'];
  type: FournisseurType;
};

export type OrdreDeTravailUpdateInput = {
  addenda: Array<OrdreDeTravailUpdateAddendumInput>;
  arrivalDateTime: Scalars['DateTime']['input'];
  attachments: Array<OrdreDeTravailUpdateAttachmentInput>;
  callReceivedFrom: Scalars['String']['input'];
  date: Scalars['Date']['input'];
  departureDateTime?: InputMaybe<Scalars['DateTime']['input']>;
  estimatedDurationInHours: Scalars['Int']['input'];
  fournisseurs: Array<OrdreDeTravailUpdateFournisseurInput>;
  gtNumber?: InputMaybe<Scalars['Int']['input']>;
  isEstablishedSchedule: Scalars['Boolean']['input'];
  purchaseOrder?: InputMaybe<Scalars['String']['input']>;
  readyDateTime?: InputMaybe<Scalars['DateTime']['input']>;
  representativeId: Scalars['String']['input'];
  updateContinuity: Scalars['Boolean']['input'];
  updatedAt: Scalars['Long']['input'];
  weekType?: InputMaybe<WeekType>;
  workDescription: Scalars['String']['input'];
  workSite: OrdreDeTravailUpdateWorkSiteInput;
  workType: Scalars['Int']['input'];
  workTypeCategory?: InputMaybe<WorkTypeCategory>;
};

export type OrdreDeTravailUpdateNotification = Notification & {
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['Date']['output'];
  employeeId: Scalars['UUID']['output'];
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  notificationData: OrdreDeTravailUpdateNotificationDataRecord;
  number: Scalars['String']['output'];
  ordreDeTravailId: Scalars['String']['output'];
  status: NotificationStatus;
  type: NotificationType;
  updatedAt: Scalars['Long']['output'];
};

export type OrdreDeTravailUpdateNotificationDataRecord = {
  originalAccessories?: Maybe<Array<AccessoryUpdateRecord>>;
  originalArrivalDateTime?: Maybe<Scalars['DateTime']['output']>;
  originalCraneNumber?: Maybe<Scalars['String']['output']>;
  originalCraneOperators?: Maybe<Array<CraneOperatorUpdateRecord>>;
  originalDepartureDateTime?: Maybe<Scalars['DateTime']['output']>;
  originalSiteLocation?: Maybe<Scalars['String']['output']>;
};

export type OrdreDeTravailUpdateWorkSiteInput = {
  id: Scalars['String']['input'];
  location: Scalars['String']['input'];
  mobilePhoneNumber: Scalars['String']['input'];
  name: Scalars['String']['input'];
  toSeeOnSite: Scalars['String']['input'];
};

export enum OrdreDeTravailValidationStatus {
  Cancelled = 'cancelled',
  DocumentsReceived = 'documentsReceived',
  Entering = 'entering',
  Open = 'open',
  Submitted = 'submitted',
  Upcoming = 'upcoming',
  Validated = 'validated',
  WaitingForInformation = 'waitingForInformation',
}

export type OrdreDeTravailWithDailyInformationRecord = {
  dailyOtsInformation?: Maybe<Array<DailyOrdreDeTravailInformationRecord>>;
  isPrimaryContractAgreementFinished?: Maybe<Scalars['Boolean']['output']>;
  ordreDeTravail?: Maybe<OrdreDeTravailRecord>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PensionRecordMobile = {
  numberOfPersons: Scalars['Int']['output'];
  unitOfTimeCount: Scalars['Int']['output'];
};

export type PosteDeTravailInput = {
  additionalDigit?: InputMaybe<Scalars['Int']['input']>;
  workKindNumber?: InputMaybe<Scalars['Int']['input']>;
  workTypeNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type PosteDeTravailRecord = {
  additionalDigit?: Maybe<Scalars['Int']['output']>;
  workKind?: Maybe<WorkKind>;
  workKindNumber?: Maybe<Scalars['Int']['output']>;
  workType?: Maybe<WorkType>;
  workTypeNumber?: Maybe<Scalars['Int']['output']>;
};

export type PosteDeTravailRecordMobile = {
  additionalDigit?: Maybe<Scalars['Int']['output']>;
  workKind?: Maybe<WorkKind>;
  workKindNumber?: Maybe<Scalars['Int']['output']>;
  workType?: Maybe<WorkType>;
  workTypeNumber?: Maybe<Scalars['Int']['output']>;
};

export type PremiumCreateOrUpdateInput = {
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  distanceTravelled?: InputMaybe<DistanceFeeRange>;
  durationInMinutes?: InputMaybe<Scalars['Int']['input']>;
  extendedDistance?: InputMaybe<Scalars['Decimal']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  gtNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  numberOfDays?: InputMaybe<Scalars['Int']['input']>;
  numberOfPersons?: InputMaybe<Scalars['Int']['input']>;
  posteDeTravail?: InputMaybe<UpdatePosteDeTravailInput>;
  salesBranchNumber?: InputMaybe<Scalars['Int']['input']>;
  startsOnNextDay?: InputMaybe<Scalars['Boolean']['input']>;
  timeEntryInformation?: InputMaybe<TimeEntryInformationInput>;
  type?: InputMaybe<Scalars['String']['input']>;
  unitNumber?: InputMaybe<Scalars['String']['input']>;
  workOrderNumber?: InputMaybe<Scalars['String']['input']>;
};

export type PremiumInput = {
  distanceTravelled?: InputMaybe<DistanceFeeRange>;
  durationInMinutes?: InputMaybe<Scalars['Int']['input']>;
  extendedDistance?: InputMaybe<Scalars['Decimal']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  gtNumber?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  isDeleted: Scalars['Boolean']['input'];
  numberOfDays?: InputMaybe<Scalars['Int']['input']>;
  numberOfPersons?: InputMaybe<Scalars['Int']['input']>;
  posteDeTravail: PosteDeTravailInput;
  salesBranchNumber?: InputMaybe<Scalars['Int']['input']>;
  startsOnNextDay?: InputMaybe<Scalars['Boolean']['input']>;
  timeEntryInformation?: InputMaybe<TimeEntryInformationInput>;
  type?: InputMaybe<Scalars['String']['input']>;
  unitNumber?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Long']['input']>;
  workOrderNumber?: InputMaybe<Scalars['String']['input']>;
};

export type PremiumRecord = {
  durationInMinutes?: Maybe<Scalars['Decimal']['output']>;
  extendedDistance?: Maybe<Scalars['Decimal']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  numberOfDays?: Maybe<Scalars['Int']['output']>;
  numberOfHours?: Maybe<Scalars['Int']['output']>;
  numberOfPersons?: Maybe<Scalars['Int']['output']>;
  posteDeTravail?: Maybe<PosteDeTravailRecordMobile>;
  startsOnNextDay?: Maybe<Scalars['Boolean']['output']>;
  timeEntryInformation?: Maybe<TimeEntryInformationRecord>;
  type: Scalars['String']['output'];
  unitNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** @deprecated Use the value in the nested PosteDeTravail property */
  workKind?: Maybe<WorkKind>;
  /** @deprecated Use the value in the nested PosteDeTravail property */
  workType?: Maybe<WorkType>;
};

export type PreparationEmployeeDistributionRecord = {
  duration: Scalars['Decimal']['output'];
  employeeIds: Array<Scalars['String']['output']>;
};

export type PullBulkEntityOfContractAgreementRecordMobile = {
  checkpoint?: Maybe<Checkpoint>;
  documents: Array<ContractAgreementRecordMobile>;
};

export type PullBulkEntityOfDailyTimeSheetRecord = {
  checkpoint?: Maybe<Checkpoint>;
  documents: Array<DailyTimeSheetRecord>;
};

export type PullBulkEntityOfOrdreDeTravailRecordMobile = {
  checkpoint?: Maybe<Checkpoint>;
  documents: Array<OrdreDeTravailRecordMobile>;
};

export type PullBulkNotification = {
  checkpoint?: Maybe<Checkpoint>;
  documents: Array<Notification>;
};

export type PushRowContractAgreementInput = {
  assumedMasterState?: InputMaybe<SyncContractAgreementInputMobileInput>;
  newDocumentState: SyncContractAgreementInputMobileInput;
};

export type PushRowDailyTimeSheetInput = {
  assumedMasterState?: InputMaybe<DailyTimeSheetInput>;
  newDocumentState: DailyTimeSheetInput;
};

export type PushRowNotificationInput = {
  assumedMasterState?: InputMaybe<UpdateNotificationRecordInput>;
  newDocumentState: UpdateNotificationRecordInput;
};

export type PushRowOrdreDeTravailInput = {
  assumedMasterState?: InputMaybe<SyncUpdateOrdreDeTravailInput>;
  newDocumentState: SyncUpdateOrdreDeTravailInput;
};

export type Query = {
  allEmployees: Array<GetAllEmployeeRecord>;
  allPaginatedContractAgreements: GetAllPaginatedResponse;
  allPaginatedDailyTimeSheets: GetAllPaginatedDailyTimeSheetsResponse;
  allPaginatedOrdreDeTravailNotificationsWithoutFinishedStatus: GetAllOrdreDeTravailNotificationsResponse;
  allPaginatedOrdresDeTravail: GetAllOrdresDeTravailResponse;
  allPaginatedOrdresDeTravailForValidation: GetAllOrdresDeTravailForValidationResponse;
  allTimeSheetEntries: GetAllPaginatedTimeSheetEntriesResponse;
  allTimeSheetsValidation: GetAllTimeSheetsValidationRecordsResponse;
  client?: Maybe<ClientConnection>;
  contractAgreementPdf?: Maybe<Array<Scalars['Byte']['output']>>;
  dailyTimeSheetById?: Maybe<GetDailyTimeSheetRecord>;
  employeeId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `upgradeMobile` */
  minimalVersionMobile: Scalars['Boolean']['output'];
  ordreDeTravailById?: Maybe<OrdreDeTravailRecord>;
  ordreDeTravailForSalesPrediction?: Maybe<OrdresDeTravailConnection>;
  ordreDeTravailPdf?: Maybe<Array<Scalars['Byte']['output']>>;
  ordreDeTravailWithDailyInfo?: Maybe<OrdreDeTravailWithDailyInformationRecord>;
  pullContractAgreements: PullBulkEntityOfContractAgreementRecordMobile;
  pullDailyTimeSheets: PullBulkEntityOfDailyTimeSheetRecord;
  pullNotifications: PullBulkNotification;
  pullOrdresDeTravail: PullBulkEntityOfOrdreDeTravailRecordMobile;
  representatives: Array<RepresentativeRecord>;
  serviceCallNotifications: GetEmployeeServiceCallNotificationsResponse;
  timeSheetPdf?: Maybe<Array<Scalars['Byte']['output']>>;
  upgradeTypeMobile: UpgradeType;
  workSites: Array<GetAllWorkSitesRecord>;
};

export type QueryAllEmployeesArgs = {
  filter: GetAllEmployeesFilterInput;
};

export type QueryAllPaginatedContractAgreementsArgs = {
  request: ListRequestInput;
};

export type QueryAllPaginatedDailyTimeSheetsArgs = {
  request: ListRequestWithDateInput;
};

export type QueryAllPaginatedOrdreDeTravailNotificationsWithoutFinishedStatusArgs = {
  request: ListRequestWithDateInput;
};

export type QueryAllPaginatedOrdresDeTravailArgs = {
  request: ListRequestWithDateRangeInput;
};

export type QueryAllPaginatedOrdresDeTravailForValidationArgs = {
  request: ListRequestWithBranchTypeIdsFilterInput;
};

export type QueryAllTimeSheetEntriesArgs = {
  request: CustomListRequestInput;
};

export type QueryAllTimeSheetsValidationArgs = {
  request: ListRequestWithDateRangeInput;
};

export type QueryClientArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ClientSortInput>>;
  where?: InputMaybe<ClientFilterInput>;
};

export type QueryContractAgreementPdfArgs = {
  id: Scalars['String']['input'];
  language: SupportedLanguage;
  originalVersion: Scalars['Boolean']['input'];
};

export type QueryDailyTimeSheetByIdArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryMinimalVersionMobileArgs = {
  currentVersion: Scalars['String']['input'];
};

export type QueryOrdreDeTravailByIdArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryOrdreDeTravailForSalesPredictionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<OrdreDeTravailForSalesPredictionSortInput>>;
  where?: InputMaybe<OrdreDeTravailForSalesPredictionFilterInput>;
};

export type QueryOrdreDeTravailPdfArgs = {
  id: Scalars['UUID']['input'];
  language: SupportedLanguage;
};

export type QueryOrdreDeTravailWithDailyInfoArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryPullContractAgreementsArgs = {
  checkpoint?: InputMaybe<CheckpointInput>;
  limit?: Scalars['Int']['input'];
};

export type QueryPullDailyTimeSheetsArgs = {
  checkpoint?: InputMaybe<CheckpointInput>;
  limit?: Scalars['Int']['input'];
};

export type QueryPullNotificationsArgs = {
  checkpoint?: InputMaybe<CheckpointInput>;
  limit?: Scalars['Int']['input'];
};

export type QueryPullOrdresDeTravailArgs = {
  checkpoint?: InputMaybe<CheckpointInput>;
  limit?: Scalars['Int']['input'];
};

export type QueryServiceCallNotificationsArgs = {
  minNumberToReturn?: Scalars['Int']['input'];
};

export type QueryTimeSheetPdfArgs = {
  dailyTimeSheetId: Scalars['String']['input'];
  language: SupportedLanguage;
  timeSheetId: Scalars['String']['input'];
};

export type QueryUpgradeTypeMobileArgs = {
  currentVersion: Scalars['String']['input'];
};

export type RepresentativeRecord = {
  id: Scalars['String']['output'];
  mobilePhoneNumber?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type RepresentativeRecordMobile = {
  id: Scalars['String']['output'];
  mobilePhoneNumber?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type RequirementRecord = {
  code: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  natureCode: Scalars['Int']['output'];
};

export enum SendRequestType {
  Email = 'email',
  Sms = 'sms',
}

export type ServiceCallAccessoryGroupUpdateData = {
  branchName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
};

export type ServiceCallAccessoryTypeUpdateData = {
  number?: Maybe<Scalars['Int']['output']>;
  shortDescriptionEn?: Maybe<Scalars['String']['output']>;
  shortDescriptionFr?: Maybe<Scalars['String']['output']>;
};

export type ServiceCallAccessoryUpdateData = {
  accessoryGroup?: Maybe<ServiceCallAccessoryGroupUpdateData>;
  accessoryOutOfInventory?: Maybe<Scalars['String']['output']>;
  accessoryType?: Maybe<ServiceCallAccessoryTypeUpdateData>;
  billable: Scalars['Boolean']['output'];
  billingCode?: Maybe<ServiceCallBillingUpdateData>;
  quantity: Scalars['Int']['output'];
  rate?: Maybe<Scalars['Decimal']['output']>;
};

export type ServiceCallBillingUpdateData = {
  code: Scalars['Int']['output'];
  priceCode: Scalars['Int']['output'];
  shortDescriptionEn?: Maybe<Scalars['String']['output']>;
  shortDescriptionFr?: Maybe<Scalars['String']['output']>;
  subCode: Scalars['Int']['output'];
};

export type ServiceCallBoomConfigurationUpdateData = {
  boomLength?: Maybe<Scalars['Decimal']['output']>;
  counterweight?: Maybe<Scalars['Decimal']['output']>;
  jibLength?: Maybe<Scalars['Decimal']['output']>;
  maxWeight?: Maybe<Scalars['Decimal']['output']>;
  offsetAngle?: Maybe<Scalars['Decimal']['output']>;
  radius?: Maybe<Scalars['Decimal']['output']>;
};

export type ServiceCallClientUpdateData = {
  name?: Maybe<Scalars['String']['output']>;
};

export type ServiceCallCraneConfigurationUpdateData = {
  capacity?: Maybe<Scalars['Int']['output']>;
  equipmentType?: Maybe<ServiceCallEquipmentTypeUpdateData>;
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
};

export type ServiceCallEquipmentTypeUpdateData = {
  abbreviation?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
};

export type ServiceCallNotificationBillingRecord = {
  codeSubCode: Scalars['String']['output'];
  descriptionEn: Scalars['String']['output'];
  descriptionFr: Scalars['String']['output'];
};

export type ServiceCallNotificationDataRecord = {
  accessories?: Maybe<Array<Maybe<ServiceCallAccessoryUpdateData>>>;
  boomConfiguration?: Maybe<ServiceCallBoomConfigurationUpdateData>;
  client?: Maybe<ServiceCallClientUpdateData>;
  craneConfiguration?: Maybe<ServiceCallCraneConfigurationUpdateData>;
  preventReplacement?: Maybe<Scalars['Boolean']['output']>;
  preventSubcontracting?: Maybe<Scalars['Boolean']['output']>;
  project?: Maybe<ServiceCallProjectUpdateData>;
  status?: Maybe<Scalars['String']['output']>;
};

export type ServiceCallNotificationRecord = {
  billingCode?: Maybe<ServiceCallNotificationBillingRecord>;
  continuityLastDate: Scalars['Date']['output'];
  createdAt: Scalars['DateTime']['output'];
  employeeId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isFirstOrdreDeTravailOfContinuity: Scalars['Boolean']['output'];
  newDateTime: Scalars['DateTime']['output'];
  notificationData?: Maybe<ServiceCallUpdatedNotificationDataRecord>;
  oldDateTime: Scalars['DateTime']['output'];
  ordreDeTravailArrivalDate?: Maybe<Scalars['DateTime']['output']>;
  ordreDeTravailClient: Scalars['String']['output'];
  ordreDeTravailDate: Scalars['Date']['output'];
  ordreDeTravailId: Scalars['UUID']['output'];
  ordreDeTravailNumber: Scalars['String']['output'];
  quantityDeleted: Scalars['Int']['output'];
  status: NotificationStatus;
  type: ServiceCallNotificationRecordType;
};

export enum ServiceCallNotificationRecordType {
  OrdreDeTravailImpossibleOptimizationNotification = 'ordreDeTravailImpossibleOptimizationNotification',
  OrdreDeTravailTransporterRemovedNotification = 'ordreDeTravailTransporterRemovedNotification',
  OrdreDeTravailTransportersMovedNotification = 'ordreDeTravailTransportersMovedNotification',
  ServiceCallUpdatedNotification = 'serviceCallUpdatedNotification',
}

export type ServiceCallProjectUpdateData = {
  address?: Maybe<Scalars['String']['output']>;
  arrivalDate?: Maybe<Scalars['DateTime']['output']>;
  departureDate?: Maybe<Scalars['DateTime']['output']>;
  durationInDays?: Maybe<Scalars['Int']['output']>;
  hoursPerDay?: Maybe<Scalars['Decimal']['output']>;
  isSecondServing?: Maybe<Scalars['Boolean']['output']>;
  readyDate?: Maybe<Scalars['DateTime']['output']>;
  workType?: Maybe<WorkType>;
};

export type ServiceCallRecord = {
  friendlyId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  permalink: Scalars['String']['output'];
};

export type ServiceCallUpdatedNotificationDataRecord = {
  newNotificationData: ServiceCallNotificationDataRecord;
  originalNotificationData: ServiceCallNotificationDataRecord;
};

export type ServiceCallUpdatedNotificationInput = {
  status: NotificationStatus;
};

export type SiteRecord = {
  id?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  mobilePhoneNumber?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  requirements?: Maybe<Array<RequirementRecord>>;
  toSeeOnSite?: Maybe<Scalars['String']['output']>;
};

export type SiteRecordMobile = {
  id?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  mobilePhoneNumber?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  toSeeOnSite?: Maybe<Scalars['String']['output']>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitDailyTimeSheetInput = {
  dailyTimeSheetId: Scalars['UUID']['input'];
};

export type SubmitDailyTimeSheetMutationResponse = {
  dailyTimeSheetWithEmployee?: Maybe<DailyTimeSheetAndEmployeeRecord>;
  errors?: Maybe<Array<SubmitDailyTimesheetMutationResponseError>>;
  success: Scalars['Boolean']['output'];
};

export type SubmitDailyTimesheetMutationResponseError = {
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export type Subscription = {
  onContractAgreementUpdated: PullBulkEntityOfContractAgreementRecordMobile;
  onDailyTimeSheetUpdated: PullBulkEntityOfDailyTimeSheetRecord;
  onNotificationUpdated: PullBulkNotification;
  onOrdreDeTravailUpdated: PullBulkEntityOfOrdreDeTravailRecordMobile;
  onServiceCallNotificationUpdated: EmployeeServiceCallNotificationResponse;
};

export type SubscriptionOnContractAgreementUpdatedArgs = {
  employeeId: Scalars['UUID']['input'];
};

export type SubscriptionOnDailyTimeSheetUpdatedArgs = {
  employeeId: Scalars['UUID']['input'];
};

export type SubscriptionOnNotificationUpdatedArgs = {
  employeeId: Scalars['UUID']['input'];
};

export type SubscriptionOnOrdreDeTravailUpdatedArgs = {
  employeeId: Scalars['UUID']['input'];
};

export type SubscriptionOnServiceCallNotificationUpdatedArgs = {
  employeeId: Scalars['UUID']['input'];
};

export enum SupportedLanguage {
  English = 'english',
  French = 'french',
}

export type SyncAdditionalTimeInput = {
  timeRanges?: InputMaybe<Array<SyncEmployeeDistributedTimeRangeInput>>;
};

export type SyncAdditionalTimeInputMobileInput = {
  timeRanges?: InputMaybe<Array<SyncEmployeeDistributedTimeRangeInputMobileInput>>;
};

export type SyncAmountEmployeeDistributionInput = {
  amount: Scalars['Decimal']['input'];
  employeeIds: Array<Scalars['String']['input']>;
};

export type SyncContractAgreementAccessoryInput = {
  id: Scalars['UUID']['input'];
  used: Scalars['Boolean']['input'];
};

export type SyncContractAgreementAccessoryInputMobileInput = {
  accessoryId: Scalars['UUID']['input'];
  description: Scalars['String']['input'];
  number?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
  shortTypeDescriptionEn?: InputMaybe<Scalars['String']['input']>;
  shortTypeDescriptionFr?: InputMaybe<Scalars['String']['input']>;
  used: Scalars['Boolean']['input'];
};

export type SyncContractAgreementInput = {
  accessories: Array<SyncContractAgreementAccessoryInput>;
  carAllowances: Array<SyncCountEmployeeDistributionInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
  lunchBreaks: Array<SyncDefaultTimeRangeInput>;
  manTime?: InputMaybe<SyncAdditionalTimeInput>;
  manTransport?: InputMaybe<SyncAdditionalTimeInput>;
  meals: Array<SyncCountEmployeeDistributionInput>;
  mechanicalFailures?: InputMaybe<Array<SyncMechanicalFailureTimeRangeInput>>;
  noBreakTimes?: InputMaybe<Array<SyncEmployeeDistributedTimeRangeInput>>;
  noLunchTimes?: InputMaybe<Array<SyncEmployeeDistributedTimeRangeInput>>;
  number?: InputMaybe<Scalars['Int']['input']>;
  ordreDeTravailId: Scalars['String']['input'];
  parking: Array<SyncAmountEmployeeDistributionInput>;
  pensions: Array<SyncCountEmployeeDistributionInput>;
  preparations: Array<SyncDurationEmployeeDistributionInput>;
  serviceHours: Array<SyncDistributedTimeRangeInput>;
  signature?: InputMaybe<Scalars['String']['input']>;
  signedAt?: InputMaybe<Scalars['DateTime']['input']>;
  status: ContractAgreementStatus;
  taxis: Array<SyncAmountEmployeeDistributionInput>;
  travellingExpenses: Array<SyncEmployeeDistributionTravellingExpensesInput>;
  updatedAt?: InputMaybe<Scalars['Long']['input']>;
};

export type SyncContractAgreementInputMobileInput = {
  accessories?: InputMaybe<Array<SyncContractAgreementAccessoryInputMobileInput>>;
  carAllowances?: InputMaybe<Array<SyncCountEmployeeDistributionInputMobileInput>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  distributedNoBreakTimes?: InputMaybe<Array<SyncEmployeeDistributedTimeRangeInputMobileInput>>;
  distributedNoLunchTimes?: InputMaybe<Array<SyncEmployeeDistributedTimeRangeInputMobileInput>>;
  employeeDistributionTravellingExpenses?: InputMaybe<Array<SyncEmployeeDistributionTravellingExpensesInputMobileInput>>;
  id: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
  lunchBreaks?: InputMaybe<Array<SyncDefaultTimeRangeInputMobileInput>>;
  manTime?: InputMaybe<SyncAdditionalTimeInputMobileInput>;
  manTransport?: InputMaybe<SyncAdditionalTimeInputMobileInput>;
  meals?: InputMaybe<Array<SyncCountEmployeeDistributionInputMobileInput>>;
  mechanicalFailures?: InputMaybe<Array<SyncMechanicalFailureTimeRangeInputMobileInput>>;
  noBreakTimes?: InputMaybe<Array<SyncDefaultTimeInputMobileInput>>;
  noLunchTimes?: InputMaybe<Array<SyncDefaultTimeInputMobileInput>>;
  number?: InputMaybe<Scalars['Int']['input']>;
  ordreDeTravailId: Scalars['String']['input'];
  parkingDistributionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  pensions?: InputMaybe<Array<SyncCountEmployeeDistributionInputMobileInput>>;
  preparations?: InputMaybe<Array<SyncDurationEmployeeDistributionInputMobileInput>>;
  sendRequests?: InputMaybe<Array<SyncContractAgreementSendRequestInputMobileInput>>;
  serviceHours?: InputMaybe<Array<SyncDistributedTimeRangeInputMobileInput>>;
  signature?: InputMaybe<Scalars['String']['input']>;
  signedAt?: InputMaybe<Scalars['DateTime']['input']>;
  status: ContractAgreementStatus;
  taxisDistributionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedAt?: InputMaybe<Scalars['Long']['input']>;
};

export type SyncContractAgreementSendRequestInputMobileInput = {
  id: Scalars['String']['input'];
  recipient: Scalars['String']['input'];
  sent: Scalars['Boolean']['input'];
  type: SendRequestType;
};

export type SyncCountEmployeeDistributionInput = {
  count: Scalars['Int']['input'];
  employeeIds: Array<Scalars['String']['input']>;
};

export type SyncCountEmployeeDistributionInputMobileInput = {
  count: Scalars['Int']['input'];
  employeeIds: Array<Scalars['String']['input']>;
};

export type SyncDefaultTimeInputMobileInput = {
  employeeIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  from: Scalars['String']['input'];
  startsOnNextDay: Scalars['Boolean']['input'];
};

export type SyncDefaultTimeRangeInput = {
  durationInMinutes: Scalars['Int']['input'];
  from: Scalars['String']['input'];
  startsOnNextDay?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SyncDefaultTimeRangeInputMobileInput = {
  durationInMinutes: Scalars['Int']['input'];
  from: Scalars['String']['input'];
  startsOnNextDay?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SyncDistributedTimeRangeInput = {
  durationInMinutes: Scalars['Int']['input'];
  from: Scalars['String']['input'];
  startsOnNextDay?: InputMaybe<Scalars['Boolean']['input']>;
  timeEntryInformation?: InputMaybe<SyncTimeEntryInformationInput>;
};

export type SyncDistributedTimeRangeInputMobileInput = {
  durationInMinutes: Scalars['Int']['input'];
  from: Scalars['String']['input'];
  startsOnNextDay?: InputMaybe<Scalars['Boolean']['input']>;
  timeEntryInformation?: InputMaybe<SyncTimeEntryInformationInputMobileInput>;
};

export type SyncDurationEmployeeDistributionInput = {
  duration: Scalars['Decimal']['input'];
  employeeIds: Array<Scalars['String']['input']>;
};

export type SyncDurationEmployeeDistributionInputMobileInput = {
  duration: Scalars['Decimal']['input'];
  employeeIds: Array<Scalars['String']['input']>;
};

export type SyncEmployeeDistributedTimeRangeInput = {
  durationInMinutes: Scalars['Int']['input'];
  employeeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  from: Scalars['String']['input'];
  startsOnNextDay?: InputMaybe<Scalars['Boolean']['input']>;
  timeEntryInformation?: InputMaybe<SyncTimeEntryInformationInput>;
};

export type SyncEmployeeDistributedTimeRangeInputMobileInput = {
  durationInMinutes: Scalars['Int']['input'];
  employeeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  from: Scalars['String']['input'];
  startsOnNextDay?: InputMaybe<Scalars['Boolean']['input']>;
  timeEntryInformation?: InputMaybe<SyncTimeEntryInformationInputMobileInput>;
};

export type SyncEmployeeDistributionTravellingExpensesInput = {
  distanceTravelled: DistanceFeeRange;
  employeeIds: Array<Scalars['String']['input']>;
  extendedDistance?: InputMaybe<Scalars['Decimal']['input']>;
};

export type SyncEmployeeDistributionTravellingExpensesInputMobileInput = {
  distanceTravelled: DistanceFeeRange;
  employeeIds: Array<Scalars['String']['input']>;
  extendedDistance?: InputMaybe<Scalars['Decimal']['input']>;
};

export type SyncMechanicalFailureTimeRangeInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  durationInMinutes: Scalars['Int']['input'];
  from: Scalars['String']['input'];
  startsOnNextDay?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SyncMechanicalFailureTimeRangeInputMobileInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  durationInMinutes: Scalars['Int']['input'];
  from: Scalars['String']['input'];
  startsOnNextDay?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SyncOperatorInput = {
  confirmation: Scalars['Boolean']['input'];
  emailAddress: Scalars['String']['input'];
  employeeId: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  isCurrentUser: Scalars['Boolean']['input'];
  kind: CraneOperatorKind;
  lastName: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type SyncTimeEntryInformationInput = {
  double?: InputMaybe<Scalars['Decimal']['input']>;
  single?: InputMaybe<Scalars['Decimal']['input']>;
  singleAndAHalf?: InputMaybe<Scalars['Decimal']['input']>;
};

export type SyncTimeEntryInformationInputMobileInput = {
  double?: InputMaybe<Scalars['Decimal']['input']>;
  single?: InputMaybe<Scalars['Decimal']['input']>;
  singleAndAHalf?: InputMaybe<Scalars['Decimal']['input']>;
};

export type SyncUpdateOrdreDeTravailInput = {
  id: Scalars['String']['input'];
  operators: Array<SyncOperatorInput>;
  status: OrdreDeTravailStatus;
  updatedAt?: InputMaybe<Scalars['Long']['input']>;
};

export type TimeEntryInformationInput = {
  automaticPrime?: InputMaybe<Scalars['Decimal']['input']>;
  double?: InputMaybe<Scalars['Decimal']['input']>;
  rate?: InputMaybe<Scalars['Decimal']['input']>;
  single?: InputMaybe<Scalars['Decimal']['input']>;
  singleAndAHalf?: InputMaybe<Scalars['Decimal']['input']>;
  variablePrime?: InputMaybe<Scalars['Decimal']['input']>;
};

export type TimeEntryInformationRecord = {
  automaticPrime?: Maybe<Scalars['Decimal']['output']>;
  double?: Maybe<Scalars['Decimal']['output']>;
  rate?: Maybe<Scalars['Decimal']['output']>;
  single?: Maybe<Scalars['Decimal']['output']>;
  singleAndAHalf?: Maybe<Scalars['Decimal']['output']>;
  variablePrime?: Maybe<Scalars['Decimal']['output']>;
};

export type TimeEntryInformationRecordMobile = {
  automaticPrime?: Maybe<Scalars['Decimal']['output']>;
  double?: Maybe<Scalars['Decimal']['output']>;
  rate?: Maybe<Scalars['Decimal']['output']>;
  single?: Maybe<Scalars['Decimal']['output']>;
  singleAndAHalf?: Maybe<Scalars['Decimal']['output']>;
  variablePrime?: Maybe<Scalars['Decimal']['output']>;
};

export type TimeSheetEntryRecord = {
  attachments?: Maybe<Array<AttachmentRecord>>;
  comment?: Maybe<Scalars['String']['output']>;
  completed: Scalars['Boolean']['output'];
  contractAgreementId?: Maybe<Scalars['String']['output']>;
  contractAgreementNumber?: Maybe<Scalars['String']['output']>;
  craneOperatorId?: Maybe<Scalars['String']['output']>;
  entryType: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  ordreDeTravailId?: Maybe<Scalars['String']['output']>;
  ordreDeTravailNumber?: Maybe<Scalars['String']['output']>;
  premiums?: Maybe<Array<PremiumRecord>>;
  updatedAt: Scalars['DateTime']['output'];
  workTimeRanges?: Maybe<Array<WorkTimeRangeRecord>>;
};

export enum TimeSheetEntryStatus {
  Completed = 'completed',
  Ongoing = 'ongoing',
  Submitted = 'submitted',
  Validated = 'validated',
}

export enum TimeSheetEntryType {
  Linked = 'linked',
  NonLinked = 'nonLinked',
}

export enum TimeSheetEntryValidationStatus {
  DocumentsReceived = 'documentsReceived',
  Open = 'open',
  Submitted = 'submitted',
}

export type TravellingExpensesRecordMobile = {
  distanceTravelled: DistanceFeeRange;
  extendedDistance?: Maybe<Scalars['Decimal']['output']>;
};

export type TravellingFee = {
  distanceTravelled: DistanceFeeRange;
  employeeId: Scalars['String']['output'];
  extendedDistance?: Maybe<Scalars['Decimal']['output']>;
};

export type UpdateAttachmentInput = {
  id: Scalars['UUID']['input'];
  isDeleted: Scalars['Boolean']['input'];
  isUploaded: Scalars['Boolean']['input'];
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['Long']['input']>;
};

export type UpdateContractAgreementRecord = {
  contractAgreement?: Maybe<ContractAgreementWithOrdreDeTravailRecord>;
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type UpdateNotificationRecordInput = {
  id: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
  status?: InputMaybe<NotificationStatus>;
  updatedAt?: InputMaybe<Scalars['Long']['input']>;
};

export type UpdatePosteDeTravailInput = {
  additionalDigit?: InputMaybe<Scalars['Int']['input']>;
  workKindNumber?: InputMaybe<Scalars['Int']['input']>;
  workTypeNumber?: InputMaybe<Scalars['Int']['input']>;
};

export enum UpgradeType {
  None = 'none',
  Optional = 'optional',
  Required = 'required',
}

export type UserInformationRecord = {
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  gt?: InputMaybe<Scalars['UUID']['input']>;
  gte?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  lt?: InputMaybe<Scalars['UUID']['input']>;
  lte?: InputMaybe<Scalars['UUID']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  ngt?: InputMaybe<Scalars['UUID']['input']>;
  ngte?: InputMaybe<Scalars['UUID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  nlt?: InputMaybe<Scalars['UUID']['input']>;
  nlte?: InputMaybe<Scalars['UUID']['input']>;
};

export enum WeekType {
  FiveDaysEightHours = 'fiveDaysEightHours',
  FiveDaysNineHours = 'fiveDaysNineHours',
  FiveDaysTenHours = 'fiveDaysTenHours',
  FourDaysTenHours = 'fourDaysTenHours',
  FourDaysTenHoursOneDayFiveHours = 'fourDaysTenHoursOneDayFiveHours',
}

export enum WorkKind {
  Administration = 'administration',
  AssemblyAndDisassembly = 'assemblyAndDisassembly',
  AutoKmAllowance = 'autoKmAllowance',
  BillableManTransport = 'billableManTransport',
  BreakageRepair = 'breakageRepair',
  BreakageWaiting = 'breakageWaiting',
  CNesst = 'cNESST',
  CarKmAllowance = 'carKmAllowance',
  CraneAssistant = 'craneAssistant',
  CraneOperator = 'craneOperator',
  CraneOperatorMenToLoad = 'craneOperatorMenToLoad',
  CraneOperatorTransportToLoad = 'craneOperatorTransportToLoad',
  CraneOperatorsNonOperatedRental = 'craneOperatorsNonOperatedRental',
  CraneTransport = 'craneTransport',
  CraneYardPreparation = 'craneYardPreparation',
  Csst = 'csst',
  Escort = 'escort',
  FuelFilling = 'fuelFilling',
  Holiday = 'holiday',
  Maintenance = 'maintenance',
  Meal = 'meal',
  Mechanic = 'mechanic',
  MenTransport = 'menTransport',
  NonBillableManTransport = 'nonBillableManTransport',
  PaidHoursNotOperated = 'paidHoursNotOperated',
  Parking = 'parking',
  PresentationAtWork = 'presentationAtWork',
  Repair = 'repair',
  RestOrMealPeriod = 'restOrMealPeriod',
  RoadBreakageWaiting = 'roadBreakageWaiting',
  RoomAndBoardDay = 'roomAndBoardDay',
  RoomAndBoardTwoPlusWeeks = 'roomAndBoardTwoPlusWeeks',
  RoomAndBoardWeek = 'roomAndBoardWeek',
  SeverancePay = 'severancePay',
  Sickness = 'sickness',
  SiteManTransfer = 'siteManTransfer',
  SpecialLeaves = 'specialLeaves',
  TaxiBusOrTolls = 'taxiBusOrTolls',
  TaxiBusParking = 'taxiBusParking',
  TowerCrane = 'towerCrane',
  Training = 'training',
  TransferManToLoad = 'transferManToLoad',
  TravelExpenses49To72Km = 'travelExpenses49To72Km',
  TravelExpenses61To90Km = 'travelExpenses61To90Km',
  TravelExpenses73To88Km = 'travelExpenses73To88Km',
  TravelExpenses89To119Km = 'travelExpenses89To119Km',
  TravelExpenses91To119Km = 'travelExpenses91To119Km',
  TravelExpenses120AndMoreKm = 'travelExpenses120AndMoreKm',
  TravelExpensesSiteTransfer = 'travelExpensesSiteTransfer',
  Trucker = 'trucker',
  TwoManTransportDriver = 'twoManTransportDriver',
  Vacation = 'vacation',
  Waiting = 'waiting',
  WorkPreparation = 'workPreparation',
}

export type WorkTimeRangeCreateOrUpdateInput = {
  durationInMinutes: Scalars['Int']['input'];
  from: Scalars['String']['input'];
  gtNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  posteDeTravail?: InputMaybe<UpdatePosteDeTravailInput>;
  salesBranchNumber?: InputMaybe<Scalars['Int']['input']>;
  startsOnNextDay: Scalars['Boolean']['input'];
  timeEntryInformation?: InputMaybe<TimeEntryInformationInput>;
  unitNumber?: InputMaybe<Scalars['String']['input']>;
  workOrderNumber?: InputMaybe<Scalars['String']['input']>;
};

export type WorkTimeRangeInput = {
  durationInMinutes: Scalars['Int']['input'];
  from: Scalars['String']['input'];
  gtNumber?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  isDeleted: Scalars['Boolean']['input'];
  posteDeTravail: PosteDeTravailInput;
  salesBranchNumber?: InputMaybe<Scalars['Int']['input']>;
  startsOnNextDay?: InputMaybe<Scalars['Boolean']['input']>;
  timeEntryInformation?: InputMaybe<TimeEntryInformationInput>;
  unitNumber?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Long']['input']>;
  workOrderNumber?: InputMaybe<Scalars['String']['input']>;
};

export type WorkTimeRangeRecord = {
  durationInMinutes: Scalars['Int']['output'];
  from: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  posteDeTravail?: Maybe<PosteDeTravailRecordMobile>;
  startsOnNextDay: Scalars['Boolean']['output'];
  timeEntryInformation?: Maybe<TimeEntryInformationRecord>;
  unitNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** @deprecated Use the value in the nested Poste property */
  workKind?: Maybe<WorkKind>;
  /** @deprecated Use the value in the nested Poste property */
  workKindNumber?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the value in the nested Poste property */
  workType: WorkType;
  /** @deprecated Use the value in the nested Poste property */
  workTypeNumber: Scalars['Int']['output'];
};

export enum WorkType {
  Barracks = 'barracks',
  CivilEngineeringAndRoads = 'civilEngineeringAndRoads',
  GeneralElectricPostLine = 'generalElectricPostLine',
  HeavyIndustries = 'heavyIndustries',
  Industrial = 'industrial',
  IndustrialSite = 'industrialSite',
  JamesBayAndIsolatedWorksite = 'jamesBayAndIsolatedWorksite',
  OutsideProvinceQuebec = 'outsideProvinceQuebec',
  Pipeline = 'pipeline',
  PowerLineErection = 'powerLineErection',
  RegularLabor = 'regularLabor',
  WindTurbine = 'windTurbine',
}

export enum WorkTypeCategory {
  Accessory = 'accessory',
  Airports = 'airports',
  Antennas = 'antennas',
  AqueductsSewer = 'aqueductsSewer',
  Blade = 'blade',
  Bridges = 'bridges',
  Cages = 'cages',
  Chambers = 'chambers',
  Dams = 'dams',
  Demolition = 'demolition',
  Distribution = 'distribution',
  Docks = 'docks',
  Excavation = 'excavation',
  Gearboxes = 'gearboxes',
  Generator = 'generator',
  Gondola = 'gondola',
  Hand = 'hand',
  Handling = 'handling',
  Landscaping = 'landscaping',
  Lifting = 'lifting',
  Lines = 'lines',
  Modules = 'modules',
  NewConstruction = 'newConstruction',
  NonOperated = 'nonOperated',
  PowerPlants = 'powerPlants',
  Pruning = 'pruning',
  Pylons = 'pylons',
  Refurbishment = 'refurbishment',
  RetentionBasins = 'retentionBasins',
  RoadsRails = 'roadsRails',
  Rotor = 'rotor',
  Shelters = 'shelters',
  Substations = 'substations',
  Towers = 'towers',
  Transformers = 'transformers',
  Transport = 'transport',
  VentilationPlumbingElectrical = 'ventilationPlumbingElectrical',
}

export type WorkTypeRecord = {
  id: Scalars['Int']['output'];
  value: WorkType;
};

export type WorkTypeRecordMobile = {
  id: Scalars['Int']['output'];
  value: WorkType;
};

/** A connection to a list of items. */
export type ClientConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<ClientEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Client>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ClientEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Client;
};

/** A connection to a list of items. */
export type OrdresDeTravailConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<OrdresDeTravailEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<OrdreDeTravailForSalesPrediction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrdresDeTravailEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: OrdreDeTravailForSalesPrediction;
};

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping of interface types */
export type ResolversInterfaceTypes<_RefType extends Record<string, unknown>> = {
  Notification: OrdreDeTravailNotification | OrdreDeTravailUpdateNotification;
};

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AccessoryRecord: ResolverTypeWrapper<AccessoryRecord>;
  AccessoryRecordMobile: ResolverTypeWrapper<AccessoryRecordMobile>;
  AccessoryUpdateRecord: ResolverTypeWrapper<AccessoryUpdateRecord>;
  AdditionalTimeRecord: ResolverTypeWrapper<AdditionalTimeRecord>;
  ApplyPolicy: ApplyPolicy;
  AttachmentInput: AttachmentInput;
  AttachmentRecord: ResolverTypeWrapper<AttachmentRecord>;
  BaseFee: ResolverTypeWrapper<BaseFee>;
  BillableTimeSheetEntryInput: BillableTimeSheetEntryInput;
  BillableTimeSheetEntryUpdateInput: BillableTimeSheetEntryUpdateInput;
  BillingCodeRecord: ResolverTypeWrapper<BillingCodeRecord>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  BooleanOperationFilterInput: BooleanOperationFilterInput;
  BranchForSalesPredictionRecord: ResolverTypeWrapper<BranchForSalesPredictionRecord>;
  BranchForSalesPredictionRecordFilterInput: BranchForSalesPredictionRecordFilterInput;
  BranchForSalesPredictionRecordSortInput: BranchForSalesPredictionRecordSortInput;
  BranchProvince: BranchProvince;
  BranchRecord: ResolverTypeWrapper<BranchRecord>;
  BranchRecordMobile: ResolverTypeWrapper<BranchRecordMobile>;
  Byte: ResolverTypeWrapper<Scalars['Byte']['output']>;
  Checkpoint: ResolverTypeWrapper<Checkpoint>;
  CheckpointInput: CheckpointInput;
  Client: ResolverTypeWrapper<Client>;
  ClientFilterInput: ClientFilterInput;
  ClientForSalesPredictionRecord: ResolverTypeWrapper<ClientForSalesPredictionRecord>;
  ClientForSalesPredictionRecordFilterInput: ClientForSalesPredictionRecordFilterInput;
  ClientForSalesPredictionRecordSortInput: ClientForSalesPredictionRecordSortInput;
  ClientRecord: ResolverTypeWrapper<ClientRecord>;
  ClientRecordMobile: ResolverTypeWrapper<ClientRecordMobile>;
  ClientSortInput: ClientSortInput;
  ClientTrainingRequirement: ResolverTypeWrapper<ClientTrainingRequirement>;
  ClientTrainingRequirementFilterInput: ClientTrainingRequirementFilterInput;
  ContractAgreementAccessoryRecord: ResolverTypeWrapper<ContractAgreementAccessoryRecord>;
  ContractAgreementAccessoryRecordMobile: ResolverTypeWrapper<ContractAgreementAccessoryRecordMobile>;
  ContractAgreementOperatorRecord: ResolverTypeWrapper<ContractAgreementOperatorRecord>;
  ContractAgreementOrdreDeTravailRecord: ResolverTypeWrapper<ContractAgreementOrdreDeTravailRecord>;
  ContractAgreementRecordMobile: ResolverTypeWrapper<ContractAgreementRecordMobile>;
  ContractAgreementSendRequestRecord: ResolverTypeWrapper<ContractAgreementSendRequestRecord>;
  ContractAgreementSendRequestRecordMobile: ResolverTypeWrapper<ContractAgreementSendRequestRecordMobile>;
  ContractAgreementStatus: ContractAgreementStatus;
  ContractAgreementStatusRecord: ResolverTypeWrapper<ContractAgreementStatusRecord>;
  ContractAgreementStatusRecordMobile: ResolverTypeWrapper<ContractAgreementStatusRecordMobile>;
  ContractAgreementWithOrdreDeTravailRecord: ResolverTypeWrapper<ContractAgreementWithOrdreDeTravailRecord>;
  CountEmployeeDistributionRecord: ResolverTypeWrapper<CountEmployeeDistributionRecord>;
  CountFee: ResolverTypeWrapper<CountFee>;
  CraneOperatorForSalesPredictionRecord: ResolverTypeWrapper<CraneOperatorForSalesPredictionRecord>;
  CraneOperatorForSalesPredictionRecordFilterInput: CraneOperatorForSalesPredictionRecordFilterInput;
  CraneOperatorKind: CraneOperatorKind;
  CraneOperatorKindOperationFilterInput: CraneOperatorKindOperationFilterInput;
  CraneOperatorStartingPoint: CraneOperatorStartingPoint;
  CraneOperatorUpdateRecord: ResolverTypeWrapper<CraneOperatorUpdateRecord>;
  CraneRecord: ResolverTypeWrapper<CraneRecord>;
  CraneRecordMobile: ResolverTypeWrapper<CraneRecordMobile>;
  CreateCraneOperatorRequestInput: CreateCraneOperatorRequestInput;
  CreateCraneOperatorResponse: ResolverTypeWrapper<CreateCraneOperatorResponse>;
  CustomListRequestInput: CustomListRequestInput;
  DailyOrdreDeTravailInformationRecord: ResolverTypeWrapper<DailyOrdreDeTravailInformationRecord>;
  DailyTimeSheetAndEmployeeRecord: ResolverTypeWrapper<DailyTimeSheetAndEmployeeRecord>;
  DailyTimeSheetInput: DailyTimeSheetInput;
  DailyTimeSheetRecord: ResolverTypeWrapper<DailyTimeSheetRecord>;
  DailyTimeSheetStatus: DailyTimeSheetStatus;
  DailyTimeSheetType: DailyTimeSheetType;
  DailyTimeSheetUpdateInput: DailyTimeSheetUpdateInput;
  Date: ResolverTypeWrapper<Scalars['Date']['output']>;
  DateOperationFilterInput: DateOperationFilterInput;
  DateRangeInput: DateRangeInput;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  DateTimeOperationFilterInput: DateTimeOperationFilterInput;
  Decimal: ResolverTypeWrapper<Scalars['Decimal']['output']>;
  DecimalOperationFilterInput: DecimalOperationFilterInput;
  DefaultTimeRangeRecord: ResolverTypeWrapper<DefaultTimeRangeRecord>;
  DefaultTimeRangeRecordMobile: ResolverTypeWrapper<DefaultTimeRangeRecordMobile>;
  DistanceFeeRange: DistanceFeeRange;
  DistributedTimeRangeRecord: ResolverTypeWrapper<DistributedTimeRangeRecord>;
  DistributedTimeRangeRecordMobile: ResolverTypeWrapper<DistributedTimeRangeRecordMobile>;
  DurationFee: ResolverTypeWrapper<DurationFee>;
  EmployeeDistributedDefaultTimeRangeRecord: ResolverTypeWrapper<EmployeeDistributedDefaultTimeRangeRecord>;
  EmployeeDistributedTimeRangeRecord: ResolverTypeWrapper<EmployeeDistributedTimeRangeRecord>;
  EmployeeDistributedTravellingExpensesRecordMobile: ResolverTypeWrapper<EmployeeDistributedTravellingExpensesRecordMobile>;
  EmployeeServiceCallNotificationResponse: ResolverTypeWrapper<EmployeeServiceCallNotificationResponse>;
  EmployeeTimeSheetEntriesRecord: ResolverTypeWrapper<EmployeeTimeSheetEntriesRecord>;
  EmployeeTimeSheetEntryRecord: ResolverTypeWrapper<EmployeeTimeSheetEntryRecord>;
  EquipmentTypeRecord: ResolverTypeWrapper<EquipmentTypeRecord>;
  FournisseurType: FournisseurType;
  GenerateContractAgreementNumberRecord: ResolverTypeWrapper<GenerateContractAgreementNumberRecord>;
  GetAllDailyTimeSheetsRecord: ResolverTypeWrapper<GetAllDailyTimeSheetsRecord>;
  GetAllEmployeeRecord: ResolverTypeWrapper<GetAllEmployeeRecord>;
  GetAllEmployeesFilterInput: GetAllEmployeesFilterInput;
  GetAllOrdreDeTravailNotificationsResponse: ResolverTypeWrapper<GetAllOrdreDeTravailNotificationsResponse>;
  GetAllOrdresDeTravailForValidationListItem: ResolverTypeWrapper<GetAllOrdresDeTravailForValidationListItem>;
  GetAllOrdresDeTravailForValidationResponse: ResolverTypeWrapper<GetAllOrdresDeTravailForValidationResponse>;
  GetAllOrdresDeTravailResponse: ResolverTypeWrapper<GetAllOrdresDeTravailResponse>;
  GetAllOrdresDeTravailResponseListItem: ResolverTypeWrapper<GetAllOrdresDeTravailResponseListItem>;
  GetAllPaginatedContractAgreementRecord: ResolverTypeWrapper<GetAllPaginatedContractAgreementRecord>;
  GetAllPaginatedDailyTimeSheetsResponse: ResolverTypeWrapper<GetAllPaginatedDailyTimeSheetsResponse>;
  GetAllPaginatedResponse: ResolverTypeWrapper<GetAllPaginatedResponse>;
  GetAllPaginatedTimeSheetEntriesResponse: ResolverTypeWrapper<GetAllPaginatedTimeSheetEntriesResponse>;
  GetAllTimeSheetsValidationRecordsResponse: ResolverTypeWrapper<GetAllTimeSheetsValidationRecordsResponse>;
  GetAllWorkSitesRecord: ResolverTypeWrapper<GetAllWorkSitesRecord>;
  GetDailyTimeSheetAttachmentRecord: ResolverTypeWrapper<GetDailyTimeSheetAttachmentRecord>;
  GetDailyTimeSheetPremiumRecord: ResolverTypeWrapper<GetDailyTimeSheetPremiumRecord>;
  GetDailyTimeSheetRecord: ResolverTypeWrapper<GetDailyTimeSheetRecord>;
  GetDailyTimeSheetTimeSheetEntryRecord: ResolverTypeWrapper<GetDailyTimeSheetTimeSheetEntryRecord>;
  GetDailyTimeSheetWorkTimeRangeRecord: ResolverTypeWrapper<GetDailyTimeSheetWorkTimeRangeRecord>;
  GetEmployeeServiceCallNotificationsResponse: ResolverTypeWrapper<GetEmployeeServiceCallNotificationsResponse>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  IntOperationFilterInput: IntOperationFilterInput;
  JobCode: JobCode;
  LinkOperator: LinkOperator;
  ListFilterInputTypeOfClientTrainingRequirementFilterInput: ListFilterInputTypeOfClientTrainingRequirementFilterInput;
  ListFilterInputTypeOfCraneOperatorForSalesPredictionRecordFilterInput: ListFilterInputTypeOfCraneOperatorForSalesPredictionRecordFilterInput;
  ListRequestInput: ListRequestInput;
  ListRequestWithBranchTypeIdsFilterInput: ListRequestWithBranchTypeIdsFilterInput;
  ListRequestWithDateInput: ListRequestWithDateInput;
  ListRequestWithDateRangeInput: ListRequestWithDateRangeInput;
  Long: ResolverTypeWrapper<Scalars['Long']['output']>;
  MechanicalFailureTimeRangeRecord: ResolverTypeWrapper<MechanicalFailureTimeRangeRecord>;
  MechanicalFailureTimeRangeRecordMobile: ResolverTypeWrapper<MechanicalFailureTimeRangeRecordMobile>;
  Mutation: ResolverTypeWrapper<{}>;
  NonBillableTimeSheetEntryInput: NonBillableTimeSheetEntryInput;
  NonBillableTimeSheetEntryUpdateInput: NonBillableTimeSheetEntryUpdateInput;
  Notification: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Notification']>;
  NotificationAcknowledgementRecord: ResolverTypeWrapper<NotificationAcknowledgementRecord>;
  NotificationStatus: NotificationStatus;
  NotificationType: NotificationType;
  NullableOfBranchProvinceOperationFilterInput: NullableOfBranchProvinceOperationFilterInput;
  OperatorRecord: ResolverTypeWrapper<OperatorRecord>;
  OperatorRecordMobile: ResolverTypeWrapper<OperatorRecordMobile>;
  OrdreDeTravailAddendumKind: OrdreDeTravailAddendumKind;
  OrdreDeTravailAddendumRecord: ResolverTypeWrapper<OrdreDeTravailAddendumRecord>;
  OrdreDeTravailAttachmentKind: OrdreDeTravailAttachmentKind;
  OrdreDeTravailAttachmentRecord: ResolverTypeWrapper<OrdreDeTravailAttachmentRecord>;
  OrdreDeTravailForContractAgreementRecord: ResolverTypeWrapper<OrdreDeTravailForContractAgreementRecord>;
  OrdreDeTravailForNotificationAcknowledgementRecord: ResolverTypeWrapper<OrdreDeTravailForNotificationAcknowledgementRecord>;
  OrdreDeTravailForSalesPrediction: ResolverTypeWrapper<OrdreDeTravailForSalesPrediction>;
  OrdreDeTravailForSalesPredictionFilterInput: OrdreDeTravailForSalesPredictionFilterInput;
  OrdreDeTravailForSalesPredictionSortInput: OrdreDeTravailForSalesPredictionSortInput;
  OrdreDeTravailFournisseurRecord: ResolverTypeWrapper<OrdreDeTravailFournisseurRecord>;
  OrdreDeTravailNotification: ResolverTypeWrapper<OrdreDeTravailNotification>;
  OrdreDeTravailRecord: ResolverTypeWrapper<OrdreDeTravailRecord>;
  OrdreDeTravailRecordMobile: ResolverTypeWrapper<OrdreDeTravailRecordMobile>;
  OrdreDeTravailStatus: OrdreDeTravailStatus;
  OrdreDeTravailStatusOperationFilterInput: OrdreDeTravailStatusOperationFilterInput;
  OrdreDeTravailSubcontractorRecord: ResolverTypeWrapper<OrdreDeTravailSubcontractorRecord>;
  OrdreDeTravailType: OrdreDeTravailType;
  OrdreDeTravailTypeOperationFilterInput: OrdreDeTravailTypeOperationFilterInput;
  OrdreDeTravailUpdateAddendumInput: OrdreDeTravailUpdateAddendumInput;
  OrdreDeTravailUpdateAttachmentInput: OrdreDeTravailUpdateAttachmentInput;
  OrdreDeTravailUpdateFournisseurInput: OrdreDeTravailUpdateFournisseurInput;
  OrdreDeTravailUpdateInput: OrdreDeTravailUpdateInput;
  OrdreDeTravailUpdateNotification: ResolverTypeWrapper<OrdreDeTravailUpdateNotification>;
  OrdreDeTravailUpdateNotificationDataRecord: ResolverTypeWrapper<OrdreDeTravailUpdateNotificationDataRecord>;
  OrdreDeTravailUpdateWorkSiteInput: OrdreDeTravailUpdateWorkSiteInput;
  OrdreDeTravailValidationStatus: OrdreDeTravailValidationStatus;
  OrdreDeTravailWithDailyInformationRecord: ResolverTypeWrapper<OrdreDeTravailWithDailyInformationRecord>;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  PensionRecordMobile: ResolverTypeWrapper<PensionRecordMobile>;
  PosteDeTravailInput: PosteDeTravailInput;
  PosteDeTravailRecord: ResolverTypeWrapper<PosteDeTravailRecord>;
  PosteDeTravailRecordMobile: ResolverTypeWrapper<PosteDeTravailRecordMobile>;
  PremiumCreateOrUpdateInput: PremiumCreateOrUpdateInput;
  PremiumInput: PremiumInput;
  PremiumRecord: ResolverTypeWrapper<PremiumRecord>;
  PreparationEmployeeDistributionRecord: ResolverTypeWrapper<PreparationEmployeeDistributionRecord>;
  PullBulkEntityOfContractAgreementRecordMobile: ResolverTypeWrapper<PullBulkEntityOfContractAgreementRecordMobile>;
  PullBulkEntityOfDailyTimeSheetRecord: ResolverTypeWrapper<PullBulkEntityOfDailyTimeSheetRecord>;
  PullBulkEntityOfOrdreDeTravailRecordMobile: ResolverTypeWrapper<PullBulkEntityOfOrdreDeTravailRecordMobile>;
  PullBulkNotification: ResolverTypeWrapper<Omit<PullBulkNotification, 'documents'> & { documents: Array<ResolversTypes['Notification']> }>;
  PushRowContractAgreementInput: PushRowContractAgreementInput;
  PushRowDailyTimeSheetInput: PushRowDailyTimeSheetInput;
  PushRowNotificationInput: PushRowNotificationInput;
  PushRowOrdreDeTravailInput: PushRowOrdreDeTravailInput;
  Query: ResolverTypeWrapper<{}>;
  RepresentativeRecord: ResolverTypeWrapper<RepresentativeRecord>;
  RepresentativeRecordMobile: ResolverTypeWrapper<RepresentativeRecordMobile>;
  RequirementRecord: ResolverTypeWrapper<RequirementRecord>;
  SendRequestType: SendRequestType;
  ServiceCallAccessoryGroupUpdateData: ResolverTypeWrapper<ServiceCallAccessoryGroupUpdateData>;
  ServiceCallAccessoryTypeUpdateData: ResolverTypeWrapper<ServiceCallAccessoryTypeUpdateData>;
  ServiceCallAccessoryUpdateData: ResolverTypeWrapper<ServiceCallAccessoryUpdateData>;
  ServiceCallBillingUpdateData: ResolverTypeWrapper<ServiceCallBillingUpdateData>;
  ServiceCallBoomConfigurationUpdateData: ResolverTypeWrapper<ServiceCallBoomConfigurationUpdateData>;
  ServiceCallClientUpdateData: ResolverTypeWrapper<ServiceCallClientUpdateData>;
  ServiceCallCraneConfigurationUpdateData: ResolverTypeWrapper<ServiceCallCraneConfigurationUpdateData>;
  ServiceCallEquipmentTypeUpdateData: ResolverTypeWrapper<ServiceCallEquipmentTypeUpdateData>;
  ServiceCallNotificationBillingRecord: ResolverTypeWrapper<ServiceCallNotificationBillingRecord>;
  ServiceCallNotificationDataRecord: ResolverTypeWrapper<ServiceCallNotificationDataRecord>;
  ServiceCallNotificationRecord: ResolverTypeWrapper<ServiceCallNotificationRecord>;
  ServiceCallNotificationRecordType: ServiceCallNotificationRecordType;
  ServiceCallProjectUpdateData: ResolverTypeWrapper<ServiceCallProjectUpdateData>;
  ServiceCallRecord: ResolverTypeWrapper<ServiceCallRecord>;
  ServiceCallUpdatedNotificationDataRecord: ResolverTypeWrapper<ServiceCallUpdatedNotificationDataRecord>;
  ServiceCallUpdatedNotificationInput: ServiceCallUpdatedNotificationInput;
  SiteRecord: ResolverTypeWrapper<SiteRecord>;
  SiteRecordMobile: ResolverTypeWrapper<SiteRecordMobile>;
  SortEnumType: SortEnumType;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  StringOperationFilterInput: StringOperationFilterInput;
  SubmitDailyTimeSheetInput: SubmitDailyTimeSheetInput;
  SubmitDailyTimeSheetMutationResponse: ResolverTypeWrapper<SubmitDailyTimeSheetMutationResponse>;
  SubmitDailyTimesheetMutationResponseError: ResolverTypeWrapper<SubmitDailyTimesheetMutationResponseError>;
  Subscription: ResolverTypeWrapper<{}>;
  SupportedLanguage: SupportedLanguage;
  SyncAdditionalTimeInput: SyncAdditionalTimeInput;
  SyncAdditionalTimeInputMobileInput: SyncAdditionalTimeInputMobileInput;
  SyncAmountEmployeeDistributionInput: SyncAmountEmployeeDistributionInput;
  SyncContractAgreementAccessoryInput: SyncContractAgreementAccessoryInput;
  SyncContractAgreementAccessoryInputMobileInput: SyncContractAgreementAccessoryInputMobileInput;
  SyncContractAgreementInput: SyncContractAgreementInput;
  SyncContractAgreementInputMobileInput: SyncContractAgreementInputMobileInput;
  SyncContractAgreementSendRequestInputMobileInput: SyncContractAgreementSendRequestInputMobileInput;
  SyncCountEmployeeDistributionInput: SyncCountEmployeeDistributionInput;
  SyncCountEmployeeDistributionInputMobileInput: SyncCountEmployeeDistributionInputMobileInput;
  SyncDefaultTimeInputMobileInput: SyncDefaultTimeInputMobileInput;
  SyncDefaultTimeRangeInput: SyncDefaultTimeRangeInput;
  SyncDefaultTimeRangeInputMobileInput: SyncDefaultTimeRangeInputMobileInput;
  SyncDistributedTimeRangeInput: SyncDistributedTimeRangeInput;
  SyncDistributedTimeRangeInputMobileInput: SyncDistributedTimeRangeInputMobileInput;
  SyncDurationEmployeeDistributionInput: SyncDurationEmployeeDistributionInput;
  SyncDurationEmployeeDistributionInputMobileInput: SyncDurationEmployeeDistributionInputMobileInput;
  SyncEmployeeDistributedTimeRangeInput: SyncEmployeeDistributedTimeRangeInput;
  SyncEmployeeDistributedTimeRangeInputMobileInput: SyncEmployeeDistributedTimeRangeInputMobileInput;
  SyncEmployeeDistributionTravellingExpensesInput: SyncEmployeeDistributionTravellingExpensesInput;
  SyncEmployeeDistributionTravellingExpensesInputMobileInput: SyncEmployeeDistributionTravellingExpensesInputMobileInput;
  SyncMechanicalFailureTimeRangeInput: SyncMechanicalFailureTimeRangeInput;
  SyncMechanicalFailureTimeRangeInputMobileInput: SyncMechanicalFailureTimeRangeInputMobileInput;
  SyncOperatorInput: SyncOperatorInput;
  SyncTimeEntryInformationInput: SyncTimeEntryInformationInput;
  SyncTimeEntryInformationInputMobileInput: SyncTimeEntryInformationInputMobileInput;
  SyncUpdateOrdreDeTravailInput: SyncUpdateOrdreDeTravailInput;
  TimeEntryInformationInput: TimeEntryInformationInput;
  TimeEntryInformationRecord: ResolverTypeWrapper<TimeEntryInformationRecord>;
  TimeEntryInformationRecordMobile: ResolverTypeWrapper<TimeEntryInformationRecordMobile>;
  TimeSheetEntryRecord: ResolverTypeWrapper<TimeSheetEntryRecord>;
  TimeSheetEntryStatus: TimeSheetEntryStatus;
  TimeSheetEntryType: TimeSheetEntryType;
  TimeSheetEntryValidationStatus: TimeSheetEntryValidationStatus;
  TravellingExpensesRecordMobile: ResolverTypeWrapper<TravellingExpensesRecordMobile>;
  TravellingFee: ResolverTypeWrapper<TravellingFee>;
  UUID: ResolverTypeWrapper<Scalars['UUID']['output']>;
  UpdateAttachmentInput: UpdateAttachmentInput;
  UpdateContractAgreementRecord: ResolverTypeWrapper<UpdateContractAgreementRecord>;
  UpdateNotificationRecordInput: UpdateNotificationRecordInput;
  UpdatePosteDeTravailInput: UpdatePosteDeTravailInput;
  UpgradeType: UpgradeType;
  UserInformationRecord: ResolverTypeWrapper<UserInformationRecord>;
  UuidOperationFilterInput: UuidOperationFilterInput;
  WeekType: WeekType;
  WorkKind: WorkKind;
  WorkTimeRangeCreateOrUpdateInput: WorkTimeRangeCreateOrUpdateInput;
  WorkTimeRangeInput: WorkTimeRangeInput;
  WorkTimeRangeRecord: ResolverTypeWrapper<WorkTimeRangeRecord>;
  WorkType: WorkType;
  WorkTypeCategory: WorkTypeCategory;
  WorkTypeRecord: ResolverTypeWrapper<WorkTypeRecord>;
  WorkTypeRecordMobile: ResolverTypeWrapper<WorkTypeRecordMobile>;
  clientConnection: ResolverTypeWrapper<ClientConnection>;
  clientEdge: ResolverTypeWrapper<ClientEdge>;
  ordresDeTravailConnection: ResolverTypeWrapper<OrdresDeTravailConnection>;
  ordresDeTravailEdge: ResolverTypeWrapper<OrdresDeTravailEdge>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AccessoryRecord: AccessoryRecord;
  AccessoryRecordMobile: AccessoryRecordMobile;
  AccessoryUpdateRecord: AccessoryUpdateRecord;
  AdditionalTimeRecord: AdditionalTimeRecord;
  AttachmentInput: AttachmentInput;
  AttachmentRecord: AttachmentRecord;
  BaseFee: BaseFee;
  BillableTimeSheetEntryInput: BillableTimeSheetEntryInput;
  BillableTimeSheetEntryUpdateInput: BillableTimeSheetEntryUpdateInput;
  BillingCodeRecord: BillingCodeRecord;
  Boolean: Scalars['Boolean']['output'];
  BooleanOperationFilterInput: BooleanOperationFilterInput;
  BranchForSalesPredictionRecord: BranchForSalesPredictionRecord;
  BranchForSalesPredictionRecordFilterInput: BranchForSalesPredictionRecordFilterInput;
  BranchForSalesPredictionRecordSortInput: BranchForSalesPredictionRecordSortInput;
  BranchRecord: BranchRecord;
  BranchRecordMobile: BranchRecordMobile;
  Byte: Scalars['Byte']['output'];
  Checkpoint: Checkpoint;
  CheckpointInput: CheckpointInput;
  Client: Client;
  ClientFilterInput: ClientFilterInput;
  ClientForSalesPredictionRecord: ClientForSalesPredictionRecord;
  ClientForSalesPredictionRecordFilterInput: ClientForSalesPredictionRecordFilterInput;
  ClientForSalesPredictionRecordSortInput: ClientForSalesPredictionRecordSortInput;
  ClientRecord: ClientRecord;
  ClientRecordMobile: ClientRecordMobile;
  ClientSortInput: ClientSortInput;
  ClientTrainingRequirement: ClientTrainingRequirement;
  ClientTrainingRequirementFilterInput: ClientTrainingRequirementFilterInput;
  ContractAgreementAccessoryRecord: ContractAgreementAccessoryRecord;
  ContractAgreementAccessoryRecordMobile: ContractAgreementAccessoryRecordMobile;
  ContractAgreementOperatorRecord: ContractAgreementOperatorRecord;
  ContractAgreementOrdreDeTravailRecord: ContractAgreementOrdreDeTravailRecord;
  ContractAgreementRecordMobile: ContractAgreementRecordMobile;
  ContractAgreementSendRequestRecord: ContractAgreementSendRequestRecord;
  ContractAgreementSendRequestRecordMobile: ContractAgreementSendRequestRecordMobile;
  ContractAgreementStatusRecord: ContractAgreementStatusRecord;
  ContractAgreementStatusRecordMobile: ContractAgreementStatusRecordMobile;
  ContractAgreementWithOrdreDeTravailRecord: ContractAgreementWithOrdreDeTravailRecord;
  CountEmployeeDistributionRecord: CountEmployeeDistributionRecord;
  CountFee: CountFee;
  CraneOperatorForSalesPredictionRecord: CraneOperatorForSalesPredictionRecord;
  CraneOperatorForSalesPredictionRecordFilterInput: CraneOperatorForSalesPredictionRecordFilterInput;
  CraneOperatorKindOperationFilterInput: CraneOperatorKindOperationFilterInput;
  CraneOperatorUpdateRecord: CraneOperatorUpdateRecord;
  CraneRecord: CraneRecord;
  CraneRecordMobile: CraneRecordMobile;
  CreateCraneOperatorRequestInput: CreateCraneOperatorRequestInput;
  CreateCraneOperatorResponse: CreateCraneOperatorResponse;
  CustomListRequestInput: CustomListRequestInput;
  DailyOrdreDeTravailInformationRecord: DailyOrdreDeTravailInformationRecord;
  DailyTimeSheetAndEmployeeRecord: DailyTimeSheetAndEmployeeRecord;
  DailyTimeSheetInput: DailyTimeSheetInput;
  DailyTimeSheetRecord: DailyTimeSheetRecord;
  DailyTimeSheetUpdateInput: DailyTimeSheetUpdateInput;
  Date: Scalars['Date']['output'];
  DateOperationFilterInput: DateOperationFilterInput;
  DateRangeInput: DateRangeInput;
  DateTime: Scalars['DateTime']['output'];
  DateTimeOperationFilterInput: DateTimeOperationFilterInput;
  Decimal: Scalars['Decimal']['output'];
  DecimalOperationFilterInput: DecimalOperationFilterInput;
  DefaultTimeRangeRecord: DefaultTimeRangeRecord;
  DefaultTimeRangeRecordMobile: DefaultTimeRangeRecordMobile;
  DistributedTimeRangeRecord: DistributedTimeRangeRecord;
  DistributedTimeRangeRecordMobile: DistributedTimeRangeRecordMobile;
  DurationFee: DurationFee;
  EmployeeDistributedDefaultTimeRangeRecord: EmployeeDistributedDefaultTimeRangeRecord;
  EmployeeDistributedTimeRangeRecord: EmployeeDistributedTimeRangeRecord;
  EmployeeDistributedTravellingExpensesRecordMobile: EmployeeDistributedTravellingExpensesRecordMobile;
  EmployeeServiceCallNotificationResponse: EmployeeServiceCallNotificationResponse;
  EmployeeTimeSheetEntriesRecord: EmployeeTimeSheetEntriesRecord;
  EmployeeTimeSheetEntryRecord: EmployeeTimeSheetEntryRecord;
  EquipmentTypeRecord: EquipmentTypeRecord;
  GenerateContractAgreementNumberRecord: GenerateContractAgreementNumberRecord;
  GetAllDailyTimeSheetsRecord: GetAllDailyTimeSheetsRecord;
  GetAllEmployeeRecord: GetAllEmployeeRecord;
  GetAllEmployeesFilterInput: GetAllEmployeesFilterInput;
  GetAllOrdreDeTravailNotificationsResponse: GetAllOrdreDeTravailNotificationsResponse;
  GetAllOrdresDeTravailForValidationListItem: GetAllOrdresDeTravailForValidationListItem;
  GetAllOrdresDeTravailForValidationResponse: GetAllOrdresDeTravailForValidationResponse;
  GetAllOrdresDeTravailResponse: GetAllOrdresDeTravailResponse;
  GetAllOrdresDeTravailResponseListItem: GetAllOrdresDeTravailResponseListItem;
  GetAllPaginatedContractAgreementRecord: GetAllPaginatedContractAgreementRecord;
  GetAllPaginatedDailyTimeSheetsResponse: GetAllPaginatedDailyTimeSheetsResponse;
  GetAllPaginatedResponse: GetAllPaginatedResponse;
  GetAllPaginatedTimeSheetEntriesResponse: GetAllPaginatedTimeSheetEntriesResponse;
  GetAllTimeSheetsValidationRecordsResponse: GetAllTimeSheetsValidationRecordsResponse;
  GetAllWorkSitesRecord: GetAllWorkSitesRecord;
  GetDailyTimeSheetAttachmentRecord: GetDailyTimeSheetAttachmentRecord;
  GetDailyTimeSheetPremiumRecord: GetDailyTimeSheetPremiumRecord;
  GetDailyTimeSheetRecord: GetDailyTimeSheetRecord;
  GetDailyTimeSheetTimeSheetEntryRecord: GetDailyTimeSheetTimeSheetEntryRecord;
  GetDailyTimeSheetWorkTimeRangeRecord: GetDailyTimeSheetWorkTimeRangeRecord;
  GetEmployeeServiceCallNotificationsResponse: GetEmployeeServiceCallNotificationsResponse;
  Int: Scalars['Int']['output'];
  IntOperationFilterInput: IntOperationFilterInput;
  ListFilterInputTypeOfClientTrainingRequirementFilterInput: ListFilterInputTypeOfClientTrainingRequirementFilterInput;
  ListFilterInputTypeOfCraneOperatorForSalesPredictionRecordFilterInput: ListFilterInputTypeOfCraneOperatorForSalesPredictionRecordFilterInput;
  ListRequestInput: ListRequestInput;
  ListRequestWithBranchTypeIdsFilterInput: ListRequestWithBranchTypeIdsFilterInput;
  ListRequestWithDateInput: ListRequestWithDateInput;
  ListRequestWithDateRangeInput: ListRequestWithDateRangeInput;
  Long: Scalars['Long']['output'];
  MechanicalFailureTimeRangeRecord: MechanicalFailureTimeRangeRecord;
  MechanicalFailureTimeRangeRecordMobile: MechanicalFailureTimeRangeRecordMobile;
  Mutation: {};
  NonBillableTimeSheetEntryInput: NonBillableTimeSheetEntryInput;
  NonBillableTimeSheetEntryUpdateInput: NonBillableTimeSheetEntryUpdateInput;
  Notification: ResolversInterfaceTypes<ResolversParentTypes>['Notification'];
  NotificationAcknowledgementRecord: NotificationAcknowledgementRecord;
  NullableOfBranchProvinceOperationFilterInput: NullableOfBranchProvinceOperationFilterInput;
  OperatorRecord: OperatorRecord;
  OperatorRecordMobile: OperatorRecordMobile;
  OrdreDeTravailAddendumRecord: OrdreDeTravailAddendumRecord;
  OrdreDeTravailAttachmentRecord: OrdreDeTravailAttachmentRecord;
  OrdreDeTravailForContractAgreementRecord: OrdreDeTravailForContractAgreementRecord;
  OrdreDeTravailForNotificationAcknowledgementRecord: OrdreDeTravailForNotificationAcknowledgementRecord;
  OrdreDeTravailForSalesPrediction: OrdreDeTravailForSalesPrediction;
  OrdreDeTravailForSalesPredictionFilterInput: OrdreDeTravailForSalesPredictionFilterInput;
  OrdreDeTravailForSalesPredictionSortInput: OrdreDeTravailForSalesPredictionSortInput;
  OrdreDeTravailFournisseurRecord: OrdreDeTravailFournisseurRecord;
  OrdreDeTravailNotification: OrdreDeTravailNotification;
  OrdreDeTravailRecord: OrdreDeTravailRecord;
  OrdreDeTravailRecordMobile: OrdreDeTravailRecordMobile;
  OrdreDeTravailStatusOperationFilterInput: OrdreDeTravailStatusOperationFilterInput;
  OrdreDeTravailSubcontractorRecord: OrdreDeTravailSubcontractorRecord;
  OrdreDeTravailTypeOperationFilterInput: OrdreDeTravailTypeOperationFilterInput;
  OrdreDeTravailUpdateAddendumInput: OrdreDeTravailUpdateAddendumInput;
  OrdreDeTravailUpdateAttachmentInput: OrdreDeTravailUpdateAttachmentInput;
  OrdreDeTravailUpdateFournisseurInput: OrdreDeTravailUpdateFournisseurInput;
  OrdreDeTravailUpdateInput: OrdreDeTravailUpdateInput;
  OrdreDeTravailUpdateNotification: OrdreDeTravailUpdateNotification;
  OrdreDeTravailUpdateNotificationDataRecord: OrdreDeTravailUpdateNotificationDataRecord;
  OrdreDeTravailUpdateWorkSiteInput: OrdreDeTravailUpdateWorkSiteInput;
  OrdreDeTravailWithDailyInformationRecord: OrdreDeTravailWithDailyInformationRecord;
  PageInfo: PageInfo;
  PensionRecordMobile: PensionRecordMobile;
  PosteDeTravailInput: PosteDeTravailInput;
  PosteDeTravailRecord: PosteDeTravailRecord;
  PosteDeTravailRecordMobile: PosteDeTravailRecordMobile;
  PremiumCreateOrUpdateInput: PremiumCreateOrUpdateInput;
  PremiumInput: PremiumInput;
  PremiumRecord: PremiumRecord;
  PreparationEmployeeDistributionRecord: PreparationEmployeeDistributionRecord;
  PullBulkEntityOfContractAgreementRecordMobile: PullBulkEntityOfContractAgreementRecordMobile;
  PullBulkEntityOfDailyTimeSheetRecord: PullBulkEntityOfDailyTimeSheetRecord;
  PullBulkEntityOfOrdreDeTravailRecordMobile: PullBulkEntityOfOrdreDeTravailRecordMobile;
  PullBulkNotification: Omit<PullBulkNotification, 'documents'> & { documents: Array<ResolversParentTypes['Notification']> };
  PushRowContractAgreementInput: PushRowContractAgreementInput;
  PushRowDailyTimeSheetInput: PushRowDailyTimeSheetInput;
  PushRowNotificationInput: PushRowNotificationInput;
  PushRowOrdreDeTravailInput: PushRowOrdreDeTravailInput;
  Query: {};
  RepresentativeRecord: RepresentativeRecord;
  RepresentativeRecordMobile: RepresentativeRecordMobile;
  RequirementRecord: RequirementRecord;
  ServiceCallAccessoryGroupUpdateData: ServiceCallAccessoryGroupUpdateData;
  ServiceCallAccessoryTypeUpdateData: ServiceCallAccessoryTypeUpdateData;
  ServiceCallAccessoryUpdateData: ServiceCallAccessoryUpdateData;
  ServiceCallBillingUpdateData: ServiceCallBillingUpdateData;
  ServiceCallBoomConfigurationUpdateData: ServiceCallBoomConfigurationUpdateData;
  ServiceCallClientUpdateData: ServiceCallClientUpdateData;
  ServiceCallCraneConfigurationUpdateData: ServiceCallCraneConfigurationUpdateData;
  ServiceCallEquipmentTypeUpdateData: ServiceCallEquipmentTypeUpdateData;
  ServiceCallNotificationBillingRecord: ServiceCallNotificationBillingRecord;
  ServiceCallNotificationDataRecord: ServiceCallNotificationDataRecord;
  ServiceCallNotificationRecord: ServiceCallNotificationRecord;
  ServiceCallProjectUpdateData: ServiceCallProjectUpdateData;
  ServiceCallRecord: ServiceCallRecord;
  ServiceCallUpdatedNotificationDataRecord: ServiceCallUpdatedNotificationDataRecord;
  ServiceCallUpdatedNotificationInput: ServiceCallUpdatedNotificationInput;
  SiteRecord: SiteRecord;
  SiteRecordMobile: SiteRecordMobile;
  String: Scalars['String']['output'];
  StringOperationFilterInput: StringOperationFilterInput;
  SubmitDailyTimeSheetInput: SubmitDailyTimeSheetInput;
  SubmitDailyTimeSheetMutationResponse: SubmitDailyTimeSheetMutationResponse;
  SubmitDailyTimesheetMutationResponseError: SubmitDailyTimesheetMutationResponseError;
  Subscription: {};
  SyncAdditionalTimeInput: SyncAdditionalTimeInput;
  SyncAdditionalTimeInputMobileInput: SyncAdditionalTimeInputMobileInput;
  SyncAmountEmployeeDistributionInput: SyncAmountEmployeeDistributionInput;
  SyncContractAgreementAccessoryInput: SyncContractAgreementAccessoryInput;
  SyncContractAgreementAccessoryInputMobileInput: SyncContractAgreementAccessoryInputMobileInput;
  SyncContractAgreementInput: SyncContractAgreementInput;
  SyncContractAgreementInputMobileInput: SyncContractAgreementInputMobileInput;
  SyncContractAgreementSendRequestInputMobileInput: SyncContractAgreementSendRequestInputMobileInput;
  SyncCountEmployeeDistributionInput: SyncCountEmployeeDistributionInput;
  SyncCountEmployeeDistributionInputMobileInput: SyncCountEmployeeDistributionInputMobileInput;
  SyncDefaultTimeInputMobileInput: SyncDefaultTimeInputMobileInput;
  SyncDefaultTimeRangeInput: SyncDefaultTimeRangeInput;
  SyncDefaultTimeRangeInputMobileInput: SyncDefaultTimeRangeInputMobileInput;
  SyncDistributedTimeRangeInput: SyncDistributedTimeRangeInput;
  SyncDistributedTimeRangeInputMobileInput: SyncDistributedTimeRangeInputMobileInput;
  SyncDurationEmployeeDistributionInput: SyncDurationEmployeeDistributionInput;
  SyncDurationEmployeeDistributionInputMobileInput: SyncDurationEmployeeDistributionInputMobileInput;
  SyncEmployeeDistributedTimeRangeInput: SyncEmployeeDistributedTimeRangeInput;
  SyncEmployeeDistributedTimeRangeInputMobileInput: SyncEmployeeDistributedTimeRangeInputMobileInput;
  SyncEmployeeDistributionTravellingExpensesInput: SyncEmployeeDistributionTravellingExpensesInput;
  SyncEmployeeDistributionTravellingExpensesInputMobileInput: SyncEmployeeDistributionTravellingExpensesInputMobileInput;
  SyncMechanicalFailureTimeRangeInput: SyncMechanicalFailureTimeRangeInput;
  SyncMechanicalFailureTimeRangeInputMobileInput: SyncMechanicalFailureTimeRangeInputMobileInput;
  SyncOperatorInput: SyncOperatorInput;
  SyncTimeEntryInformationInput: SyncTimeEntryInformationInput;
  SyncTimeEntryInformationInputMobileInput: SyncTimeEntryInformationInputMobileInput;
  SyncUpdateOrdreDeTravailInput: SyncUpdateOrdreDeTravailInput;
  TimeEntryInformationInput: TimeEntryInformationInput;
  TimeEntryInformationRecord: TimeEntryInformationRecord;
  TimeEntryInformationRecordMobile: TimeEntryInformationRecordMobile;
  TimeSheetEntryRecord: TimeSheetEntryRecord;
  TravellingExpensesRecordMobile: TravellingExpensesRecordMobile;
  TravellingFee: TravellingFee;
  UUID: Scalars['UUID']['output'];
  UpdateAttachmentInput: UpdateAttachmentInput;
  UpdateContractAgreementRecord: UpdateContractAgreementRecord;
  UpdateNotificationRecordInput: UpdateNotificationRecordInput;
  UpdatePosteDeTravailInput: UpdatePosteDeTravailInput;
  UserInformationRecord: UserInformationRecord;
  UuidOperationFilterInput: UuidOperationFilterInput;
  WorkTimeRangeCreateOrUpdateInput: WorkTimeRangeCreateOrUpdateInput;
  WorkTimeRangeInput: WorkTimeRangeInput;
  WorkTimeRangeRecord: WorkTimeRangeRecord;
  WorkTypeRecord: WorkTypeRecord;
  WorkTypeRecordMobile: WorkTypeRecordMobile;
  clientConnection: ClientConnection;
  clientEdge: ClientEdge;
  ordresDeTravailConnection: OrdresDeTravailConnection;
  ordresDeTravailEdge: OrdresDeTravailEdge;
};

export type AccessoryRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccessoryRecord'] = ResolversParentTypes['AccessoryRecord'],
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shortTypeDescriptionEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortTypeDescriptionFr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessoryRecordMobileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccessoryRecordMobile'] = ResolversParentTypes['AccessoryRecordMobile'],
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shortTypeDescriptionEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortTypeDescriptionFr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessoryUpdateRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccessoryUpdateRecord'] = ResolversParentTypes['AccessoryUpdateRecord'],
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shortTypeDescriptionEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortTypeDescriptionFr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdditionalTimeRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdditionalTimeRecord'] = ResolversParentTypes['AdditionalTimeRecord'],
> = {
  numberOfPersons?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timeRanges?: Resolver<Maybe<Array<ResolversTypes['EmployeeDistributedTimeRangeRecord']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttachmentRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttachmentRecord'] = ResolversParentTypes['AttachmentRecord'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isUploaded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mimeType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseFeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseFee'] = ResolversParentTypes['BaseFee']> = {
  employeeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingCodeRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BillingCodeRecord'] = ResolversParentTypes['BillingCodeRecord'],
> = {
  code?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  subCode?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BranchForSalesPredictionRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BranchForSalesPredictionRecord'] = ResolversParentTypes['BranchForSalesPredictionRecord'],
> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dispatchBranchId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  province?: Resolver<Maybe<ResolversTypes['BranchProvince']>, ParentType, ContextType>;
  salesDistribution?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  shortNameEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortNameFr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BranchRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BranchRecord'] = ResolversParentTypes['BranchRecord'],
> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  salesDistribution?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BranchRecordMobileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BranchRecordMobile'] = ResolversParentTypes['BranchRecordMobile'],
> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  salesDistribution?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ByteScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Byte'], any> {
  name: 'Byte';
}

export type CheckpointResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Checkpoint'] = ResolversParentTypes['Checkpoint'],
> = {
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientResolvers<ContextType = any, ParentType extends ResolversParentTypes['Client'] = ResolversParentTypes['Client']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billingRequirementDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  callVolume?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  isPurchaseOrderMandatory?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  representativeId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  totalSales?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  trainingRequirements?: Resolver<Array<ResolversTypes['ClientTrainingRequirement']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientForSalesPredictionRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClientForSalesPredictionRecord'] = ResolversParentTypes['ClientForSalesPredictionRecord'],
> = {
  clientId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClientRecord'] = ResolversParentTypes['ClientRecord'],
> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billingRequirementDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isPurchaseOrderMandatory?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  purchaseOrder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requirements?: Resolver<Maybe<Array<ResolversTypes['RequirementRecord']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientRecordMobileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClientRecordMobile'] = ResolversParentTypes['ClientRecordMobile'],
> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientTrainingRequirementResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClientTrainingRequirement'] = ResolversParentTypes['ClientTrainingRequirement'],
> = {
  clientId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  trainingId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractAgreementAccessoryRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContractAgreementAccessoryRecord'] = ResolversParentTypes['ContractAgreementAccessoryRecord'],
> = {
  accessoryId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shortTypeDescriptionEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortTypeDescriptionFr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  used?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractAgreementAccessoryRecordMobileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ContractAgreementAccessoryRecordMobile'] = ResolversParentTypes['ContractAgreementAccessoryRecordMobile'],
> = {
  accessoryId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shortTypeDescriptionEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortTypeDescriptionFr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  used?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractAgreementOperatorRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContractAgreementOperatorRecord'] = ResolversParentTypes['ContractAgreementOperatorRecord'],
> = {
  contractAgreementId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  dailyTimeSheetId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  employeeId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['CraneOperatorKind'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractAgreementOrdreDeTravailRecordResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ContractAgreementOrdreDeTravailRecord'] = ResolversParentTypes['ContractAgreementOrdreDeTravailRecord'],
> = {
  accessories?: Resolver<Array<ResolversTypes['AccessoryRecord']>, ParentType, ContextType>;
  addendum?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  arrivalDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  arrowLength?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  callReceivedFrom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  client?: Resolver<Maybe<ResolversTypes['ClientRecord']>, ParentType, ContextType>;
  contractAgreements?: Resolver<Maybe<Array<ResolversTypes['ContractAgreementStatusRecord']>>, ParentType, ContextType>;
  contractMinimum?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  counterweight?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  crane?: Resolver<Maybe<ResolversTypes['CraneRecord']>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  departureDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  departureDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dispatchBranch?: Resolver<Maybe<ResolversTypes['BranchRecord']>, ParentType, ContextType>;
  estimatedDurationInDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  estimatedDurationInHours?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gtNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jibLength?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  maximumWeightToLift?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  nextOrdreDeTravailNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offset?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  operators?: Resolver<Array<ResolversTypes['ContractAgreementOperatorRecord']>, ParentType, ContextType>;
  previousOrdreDeTravailId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  previousOrdreDeTravailNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  readyDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  representative?: Resolver<Maybe<ResolversTypes['RepresentativeRecord']>, ParentType, ContextType>;
  requiredCapacity?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  salesBranch?: Resolver<Maybe<ResolversTypes['BranchRecord']>, ParentType, ContextType>;
  serviceCallId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  site?: Resolver<Maybe<ResolversTypes['SiteRecord']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['OrdreDeTravailStatus'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  validationStatus?: Resolver<ResolversTypes['OrdreDeTravailValidationStatus'], ParentType, ContextType>;
  waitingForInformationComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weekType?: Resolver<Maybe<ResolversTypes['WeekType']>, ParentType, ContextType>;
  workDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workRadius?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  workType?: Resolver<ResolversTypes['WorkTypeRecord'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractAgreementRecordMobileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContractAgreementRecordMobile'] = ResolversParentTypes['ContractAgreementRecordMobile'],
> = {
  accessories?: Resolver<Maybe<Array<ResolversTypes['ContractAgreementAccessoryRecordMobile']>>, ParentType, ContextType>;
  carAllowances?: Resolver<Maybe<Array<ResolversTypes['CountEmployeeDistributionRecord']>>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  employeeDistributionTravellingExpenses?: Resolver<
    Maybe<Array<ResolversTypes['EmployeeDistributedTravellingExpensesRecordMobile']>>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSubmitted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lunchBreaks?: Resolver<Maybe<Array<ResolversTypes['DefaultTimeRangeRecordMobile']>>, ParentType, ContextType>;
  manTime?: Resolver<Maybe<ResolversTypes['AdditionalTimeRecord']>, ParentType, ContextType>;
  manTransport?: Resolver<Maybe<ResolversTypes['AdditionalTimeRecord']>, ParentType, ContextType>;
  meal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  meals?: Resolver<Maybe<Array<ResolversTypes['CountEmployeeDistributionRecord']>>, ParentType, ContextType>;
  mechanicalFailures?: Resolver<Maybe<Array<ResolversTypes['MechanicalFailureTimeRangeRecordMobile']>>, ParentType, ContextType>;
  noBreakTimes?: Resolver<Maybe<Array<ResolversTypes['EmployeeDistributedDefaultTimeRangeRecord']>>, ParentType, ContextType>;
  noLunchTimes?: Resolver<Maybe<Array<ResolversTypes['EmployeeDistributedDefaultTimeRangeRecord']>>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ordreDeTravailDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ordreDeTravailId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parking?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  parkingDistributionIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  pension?: Resolver<Maybe<ResolversTypes['PensionRecordMobile']>, ParentType, ContextType>;
  pensions?: Resolver<Maybe<Array<ResolversTypes['CountEmployeeDistributionRecord']>>, ParentType, ContextType>;
  preparation?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  preparations?: Resolver<Maybe<Array<ResolversTypes['PreparationEmployeeDistributionRecord']>>, ParentType, ContextType>;
  sendRequests?: Resolver<Maybe<Array<ResolversTypes['ContractAgreementSendRequestRecordMobile']>>, ParentType, ContextType>;
  serviceHours?: Resolver<Maybe<Array<ResolversTypes['DistributedTimeRangeRecordMobile']>>, ParentType, ContextType>;
  signature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ContractAgreementStatus'], ParentType, ContextType>;
  taxis?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  taxisDistributionIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  travellingExpenses?: Resolver<Maybe<ResolversTypes['TravellingExpensesRecordMobile']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractAgreementSendRequestRecordResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ContractAgreementSendRequestRecord'] = ResolversParentTypes['ContractAgreementSendRequestRecord'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recipient?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['SendRequestType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractAgreementSendRequestRecordMobileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ContractAgreementSendRequestRecordMobile'] = ResolversParentTypes['ContractAgreementSendRequestRecordMobile'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recipient?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['SendRequestType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractAgreementStatusRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContractAgreementStatusRecord'] = ResolversParentTypes['ContractAgreementStatusRecord'],
> = {
  status?: Resolver<ResolversTypes['ContractAgreementStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractAgreementStatusRecordMobileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ContractAgreementStatusRecordMobile'] = ResolversParentTypes['ContractAgreementStatusRecordMobile'],
> = {
  status?: Resolver<ResolversTypes['ContractAgreementStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractAgreementWithOrdreDeTravailRecordResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ContractAgreementWithOrdreDeTravailRecord'] = ResolversParentTypes['ContractAgreementWithOrdreDeTravailRecord'],
> = {
  accessories?: Resolver<Maybe<Array<ResolversTypes['ContractAgreementAccessoryRecord']>>, ParentType, ContextType>;
  carAllowances?: Resolver<Maybe<Array<ResolversTypes['CountFee']>>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSubmitted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isValidated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lunchBreaks?: Resolver<Maybe<Array<ResolversTypes['DefaultTimeRangeRecord']>>, ParentType, ContextType>;
  manTime?: Resolver<Maybe<Array<ResolversTypes['EmployeeDistributedTimeRangeRecord']>>, ParentType, ContextType>;
  manTransport?: Resolver<Maybe<Array<ResolversTypes['EmployeeDistributedTimeRangeRecord']>>, ParentType, ContextType>;
  meals?: Resolver<Maybe<Array<ResolversTypes['CountFee']>>, ParentType, ContextType>;
  mechanicalFailures?: Resolver<Maybe<Array<ResolversTypes['MechanicalFailureTimeRangeRecord']>>, ParentType, ContextType>;
  noBreakTimes?: Resolver<Maybe<Array<ResolversTypes['EmployeeDistributedTimeRangeRecord']>>, ParentType, ContextType>;
  noLunchTimes?: Resolver<Maybe<Array<ResolversTypes['EmployeeDistributedTimeRangeRecord']>>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ordreDeTravail?: Resolver<Maybe<ResolversTypes['ContractAgreementOrdreDeTravailRecord']>, ParentType, ContextType>;
  ordreDeTravailId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parking?: Resolver<Maybe<Array<ResolversTypes['BaseFee']>>, ParentType, ContextType>;
  pensions?: Resolver<Maybe<Array<ResolversTypes['CountFee']>>, ParentType, ContextType>;
  preparations?: Resolver<Maybe<Array<ResolversTypes['DurationFee']>>, ParentType, ContextType>;
  sendRequests?: Resolver<Maybe<Array<ResolversTypes['ContractAgreementSendRequestRecord']>>, ParentType, ContextType>;
  serviceHours?: Resolver<Maybe<Array<ResolversTypes['DistributedTimeRangeRecord']>>, ParentType, ContextType>;
  signature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ContractAgreementStatus'], ParentType, ContextType>;
  submittedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  submittedBy?: Resolver<Maybe<ResolversTypes['UserInformationRecord']>, ParentType, ContextType>;
  taxis?: Resolver<Maybe<Array<ResolversTypes['BaseFee']>>, ParentType, ContextType>;
  travellingExpenses?: Resolver<Maybe<Array<ResolversTypes['TravellingFee']>>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountEmployeeDistributionRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountEmployeeDistributionRecord'] = ResolversParentTypes['CountEmployeeDistributionRecord'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  employeeIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountFeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountFee'] = ResolversParentTypes['CountFee']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  employeeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CraneOperatorForSalesPredictionRecordResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CraneOperatorForSalesPredictionRecord'] = ResolversParentTypes['CraneOperatorForSalesPredictionRecord'],
> = {
  employeeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['CraneOperatorKind'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CraneOperatorUpdateRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CraneOperatorUpdateRecord'] = ResolversParentTypes['CraneOperatorUpdateRecord'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  kind?: Resolver<Maybe<ResolversTypes['CraneOperatorKind']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CraneRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CraneRecord'] = ResolversParentTypes['CraneRecord'],
> = {
  capacity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CraneRecordMobileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CraneRecordMobile'] = ResolversParentTypes['CraneRecordMobile'],
> = {
  capacity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateCraneOperatorResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateCraneOperatorResponse'] = ResolversParentTypes['CreateCraneOperatorResponse'],
> = {
  employeeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['CraneOperatorKind'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DailyOrdreDeTravailInformationRecordResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DailyOrdreDeTravailInformationRecord'] = ResolversParentTypes['DailyOrdreDeTravailInformationRecord'],
> = {
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['OrdreDeTravailStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DailyTimeSheetAndEmployeeRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DailyTimeSheetAndEmployeeRecord'] = ResolversParentTypes['DailyTimeSheetAndEmployeeRecord'],
> = {
  billableTimeSheetEntries?: Resolver<Array<ResolversTypes['GetDailyTimeSheetTimeSheetEntryRecord']>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  employee?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  employeeNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  nonBillableTimeSheetEntry?: Resolver<Maybe<ResolversTypes['GetDailyTimeSheetTimeSheetEntryRecord']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  submittedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  submittedBy?: Resolver<Maybe<ResolversTypes['UserInformationRecord']>, ParentType, ContextType>;
  timeSheetId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DailyTimeSheetRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DailyTimeSheetRecord'] = ResolversParentTypes['DailyTimeSheetRecord'],
> = {
  billableTimeSheetEntries?: Resolver<Array<ResolversTypes['TimeSheetEntryRecord']>, ParentType, ContextType>;
  completed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  employeeId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  nonBillableTimeSheetEntry?: Resolver<Maybe<ResolversTypes['TimeSheetEntryRecord']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export interface DecimalScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Decimal'], any> {
  name: 'Decimal';
}

export type DefaultTimeRangeRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DefaultTimeRangeRecord'] = ResolversParentTypes['DefaultTimeRangeRecord'],
> = {
  durationInMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startsOnNextDay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DefaultTimeRangeRecordMobileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DefaultTimeRangeRecordMobile'] = ResolversParentTypes['DefaultTimeRangeRecordMobile'],
> = {
  durationInMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startsOnNextDay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistributedTimeRangeRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DistributedTimeRangeRecord'] = ResolversParentTypes['DistributedTimeRangeRecord'],
> = {
  durationInMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startsOnNextDay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  timeEntryInformation?: Resolver<ResolversTypes['TimeEntryInformationRecord'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistributedTimeRangeRecordMobileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DistributedTimeRangeRecordMobile'] = ResolversParentTypes['DistributedTimeRangeRecordMobile'],
> = {
  durationInMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startsOnNextDay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  timeEntryInformation?: Resolver<ResolversTypes['TimeEntryInformationRecordMobile'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DurationFeeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DurationFee'] = ResolversParentTypes['DurationFee'],
> = {
  duration?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  employeeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmployeeDistributedDefaultTimeRangeRecordResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EmployeeDistributedDefaultTimeRangeRecord'] = ResolversParentTypes['EmployeeDistributedDefaultTimeRangeRecord'],
> = {
  durationInMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  employeeIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startsOnNextDay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmployeeDistributedTimeRangeRecordResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EmployeeDistributedTimeRangeRecord'] = ResolversParentTypes['EmployeeDistributedTimeRangeRecord'],
> = {
  durationInMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  employeeIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startsOnNextDay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  timeEntryInformation?: Resolver<ResolversTypes['TimeEntryInformationRecord'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmployeeDistributedTravellingExpensesRecordMobileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EmployeeDistributedTravellingExpensesRecordMobile'] = ResolversParentTypes['EmployeeDistributedTravellingExpensesRecordMobile'],
> = {
  distanceTravelled?: Resolver<ResolversTypes['DistanceFeeRange'], ParentType, ContextType>;
  employeeIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  extendedDistance?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmployeeServiceCallNotificationResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EmployeeServiceCallNotificationResponse'] = ResolversParentTypes['EmployeeServiceCallNotificationResponse'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  notification?: Resolver<Maybe<ResolversTypes['ServiceCallNotificationRecord']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmployeeTimeSheetEntriesRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeTimeSheetEntriesRecord'] = ResolversParentTypes['EmployeeTimeSheetEntriesRecord'],
> = {
  employeeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  entries?: Resolver<Array<ResolversTypes['EmployeeTimeSheetEntryRecord']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmployeeTimeSheetEntryRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeTimeSheetEntryRecord'] = ResolversParentTypes['EmployeeTimeSheetEntryRecord'],
> = {
  dailyTimeSheetId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  dispatchBranch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  employeeBranch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  jobCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['TimeSheetEntryType'], ParentType, ContextType>;
  validationStatus?: Resolver<ResolversTypes['TimeSheetEntryStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EquipmentTypeRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EquipmentTypeRecord'] = ResolversParentTypes['EquipmentTypeRecord'],
> = {
  abbreviation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sequence?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerateContractAgreementNumberRecordResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GenerateContractAgreementNumberRecord'] = ResolversParentTypes['GenerateContractAgreementNumberRecord'],
> = {
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ContractAgreementStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllDailyTimeSheetsRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetAllDailyTimeSheetsRecord'] = ResolversParentTypes['GetAllDailyTimeSheetsRecord'],
> = {
  branchNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  craneOperator?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  effectiveDailyTimeSheetValidationStatus?: Resolver<Maybe<ResolversTypes['TimeSheetEntryValidationStatus']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobCode?: Resolver<ResolversTypes['JobCode'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['DailyTimeSheetStatus'], ParentType, ContextType>;
  timeSheetId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['DailyTimeSheetType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllEmployeeRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetAllEmployeeRecord'] = ResolversParentTypes['GetAllEmployeeRecord'],
> = {
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllOrdreDeTravailNotificationsResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetAllOrdreDeTravailNotificationsResponse'] = ResolversParentTypes['GetAllOrdreDeTravailNotificationsResponse'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['NotificationAcknowledgementRecord']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllOrdresDeTravailForValidationListItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetAllOrdresDeTravailForValidationListItem'] = ResolversParentTypes['GetAllOrdresDeTravailForValidationListItem'],
> = {
  branch?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  client?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  contractMinimum?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  craneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  craneOperators?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  maxDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  maximumWeightToLift?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  minDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  operatorsBranches?: Resolver<Array<Maybe<ResolversTypes['Int']>>, ParentType, ContextType>;
  requiredCapacity?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  salesBranch?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  site?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['OrdreDeTravailStatus']>, ParentType, ContextType>;
  validationStatus?: Resolver<Maybe<ResolversTypes['OrdreDeTravailValidationStatus']>, ParentType, ContextType>;
  waitingForInformationComment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workType?: Resolver<ResolversTypes['WorkType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllOrdresDeTravailForValidationResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetAllOrdresDeTravailForValidationResponse'] = ResolversParentTypes['GetAllOrdresDeTravailForValidationResponse'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['GetAllOrdresDeTravailForValidationListItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllOrdresDeTravailResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetAllOrdresDeTravailResponse'] = ResolversParentTypes['GetAllOrdresDeTravailResponse'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['GetAllOrdresDeTravailResponseListItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllOrdresDeTravailResponseListItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetAllOrdresDeTravailResponseListItem'] = ResolversParentTypes['GetAllOrdresDeTravailResponseListItem'],
> = {
  clientName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contractMinimum?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  craneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  craneOperators?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dispatchBranch?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maxDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  maximumWeightToLift?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  minDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  representativeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  requiredCapacity?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  serviceCallFriendlyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  siteName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['OrdreDeTravailStatus']>, ParentType, ContextType>;
  workDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workType?: Resolver<ResolversTypes['WorkType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllPaginatedContractAgreementRecordResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetAllPaginatedContractAgreementRecord'] = ResolversParentTypes['GetAllPaginatedContractAgreementRecord'],
> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contractAgreementStatus?: Resolver<ResolversTypes['ContractAgreementStatus'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ordreDeTravail?: Resolver<ResolversTypes['OrdreDeTravailForContractAgreementRecord'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllPaginatedDailyTimeSheetsResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetAllPaginatedDailyTimeSheetsResponse'] = ResolversParentTypes['GetAllPaginatedDailyTimeSheetsResponse'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['GetAllDailyTimeSheetsRecord']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllPaginatedResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetAllPaginatedResponse'] = ResolversParentTypes['GetAllPaginatedResponse'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['GetAllPaginatedContractAgreementRecord']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllPaginatedTimeSheetEntriesResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetAllPaginatedTimeSheetEntriesResponse'] = ResolversParentTypes['GetAllPaginatedTimeSheetEntriesResponse'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['EmployeeTimeSheetEntriesRecord']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllTimeSheetsValidationRecordsResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetAllTimeSheetsValidationRecordsResponse'] = ResolversParentTypes['GetAllTimeSheetsValidationRecordsResponse'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['GetAllDailyTimeSheetsRecord']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllWorkSitesRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetAllWorkSitesRecord'] = ResolversParentTypes['GetAllWorkSitesRecord'],
> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contact?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mobilePhoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetDailyTimeSheetAttachmentRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetDailyTimeSheetAttachmentRecord'] = ResolversParentTypes['GetDailyTimeSheetAttachmentRecord'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isUploaded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mimeType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetDailyTimeSheetPremiumRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetDailyTimeSheetPremiumRecord'] = ResolversParentTypes['GetDailyTimeSheetPremiumRecord'],
> = {
  amount?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  durationInMinutes?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  extendedDistance?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  from?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gtNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSubmitted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  numberOfDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  numberOfHours?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  numberOfPersons?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  posteDeTravail?: Resolver<Maybe<ResolversTypes['PosteDeTravailRecord']>, ParentType, ContextType>;
  salesBranchNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  startsOnNextDay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  timeEntryInformation?: Resolver<Maybe<ResolversTypes['TimeEntryInformationRecord']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  unitNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  workOrderNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetDailyTimeSheetRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetDailyTimeSheetRecord'] = ResolversParentTypes['GetDailyTimeSheetRecord'],
> = {
  billableTimeSheetEntries?: Resolver<Array<ResolversTypes['GetDailyTimeSheetTimeSheetEntryRecord']>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  employee?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  employeeNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  nonBillableTimeSheetEntry?: Resolver<Maybe<ResolversTypes['GetDailyTimeSheetTimeSheetEntryRecord']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  submittedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  submittedBy?: Resolver<Maybe<ResolversTypes['UserInformationRecord']>, ParentType, ContextType>;
  timeSheetId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetDailyTimeSheetTimeSheetEntryRecordResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetDailyTimeSheetTimeSheetEntryRecord'] = ResolversParentTypes['GetDailyTimeSheetTimeSheetEntryRecord'],
> = {
  attachments?: Resolver<Maybe<Array<ResolversTypes['GetDailyTimeSheetAttachmentRecord']>>, ParentType, ContextType>;
  client?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  completed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  contractAgreementId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contractAgreementNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  craneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  craneOperatorId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  entryType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isLinkedToDocument?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSubmitted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isValidated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  manuallyCreated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ordreDeTravailId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ordreDeTravailNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  premiums?: Resolver<Maybe<Array<ResolversTypes['GetDailyTimeSheetPremiumRecord']>>, ParentType, ContextType>;
  salesBranchNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  validationStatus?: Resolver<ResolversTypes['TimeSheetEntryValidationStatus'], ParentType, ContextType>;
  workTimeRanges?: Resolver<Maybe<Array<ResolversTypes['GetDailyTimeSheetWorkTimeRangeRecord']>>, ParentType, ContextType>;
  workType?: Resolver<Maybe<ResolversTypes['WorkType']>, ParentType, ContextType>;
  workTypeNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetDailyTimeSheetWorkTimeRangeRecordResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetDailyTimeSheetWorkTimeRangeRecord'] = ResolversParentTypes['GetDailyTimeSheetWorkTimeRangeRecord'],
> = {
  durationInMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gtNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSubmitted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  posteDeTravail?: Resolver<Maybe<ResolversTypes['PosteDeTravailRecord']>, ParentType, ContextType>;
  salesBranchNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  startsOnNextDay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  timeEntryInformation?: Resolver<Maybe<ResolversTypes['TimeEntryInformationRecord']>, ParentType, ContextType>;
  unitNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  workKind?: Resolver<Maybe<ResolversTypes['WorkKind']>, ParentType, ContextType>;
  workKindNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workOrderNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workType?: Resolver<ResolversTypes['WorkType'], ParentType, ContextType>;
  workTypeNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetEmployeeServiceCallNotificationsResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetEmployeeServiceCallNotificationsResponse'] = ResolversParentTypes['GetEmployeeServiceCallNotificationsResponse'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  notifications?: Resolver<Array<ResolversTypes['ServiceCallNotificationRecord']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface LongScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Long'], any> {
  name: 'Long';
}

export type MechanicalFailureTimeRangeRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MechanicalFailureTimeRangeRecord'] = ResolversParentTypes['MechanicalFailureTimeRangeRecord'],
> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  durationInMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startsOnNextDay?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MechanicalFailureTimeRangeRecordMobileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MechanicalFailureTimeRangeRecordMobile'] = ResolversParentTypes['MechanicalFailureTimeRangeRecordMobile'],
> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  durationInMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startsOnNextDay?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  addCraneOperator?: Resolver<
    ResolversTypes['CreateCraneOperatorResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddCraneOperatorArgs, 'createCraneOperatorRequest'>
  >;
  generateContractAgreementNumber?: Resolver<
    ResolversTypes['GenerateContractAgreementNumberRecord'],
    ParentType,
    ContextType,
    RequireFields<MutationGenerateContractAgreementNumberArgs, 'contractAgreementId'>
  >;
  pushContractAgreements?: Resolver<
    Array<ResolversTypes['ContractAgreementRecordMobile']>,
    ParentType,
    ContextType,
    RequireFields<MutationPushContractAgreementsArgs, 'contractAgreements'>
  >;
  pushDailyTimeSheets?: Resolver<
    Array<ResolversTypes['DailyTimeSheetRecord']>,
    ParentType,
    ContextType,
    RequireFields<MutationPushDailyTimeSheetsArgs, 'dailyTimeSheets'>
  >;
  pushNotifications?: Resolver<
    Array<ResolversTypes['Notification']>,
    ParentType,
    ContextType,
    RequireFields<MutationPushNotificationsArgs, 'notifications'>
  >;
  pushOrdresDeTravail?: Resolver<
    Array<ResolversTypes['OrdreDeTravailRecordMobile']>,
    ParentType,
    ContextType,
    RequireFields<MutationPushOrdresDeTravailArgs, 'ordresDeTravail'>
  >;
  removeDevice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationRemoveDeviceArgs, 'token'>>;
  submitDailyTimeSheet?: Resolver<
    ResolversTypes['SubmitDailyTimeSheetMutationResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationSubmitDailyTimeSheetArgs, 'input'>
  >;
  updateContractAgreement?: Resolver<
    ResolversTypes['UpdateContractAgreementRecord'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateContractAgreementArgs, 'contractAgreementInput'>
  >;
  updateDevices?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDevicesArgs, 'languageCode' | 'token'>
  >;
  updateOrdreDeTravail?: Resolver<
    Maybe<ResolversTypes['OrdreDeTravailRecord']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateOrdreDeTravailArgs, 'id' | 'ordreDeTravailInput'>
  >;
  updateServiceCallUpdatedNotification?: Resolver<
    ResolversTypes['ServiceCallNotificationRecord'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateServiceCallUpdatedNotificationArgs, 'id' | 'notificationInput'>
  >;
  updateStatus?: Resolver<
    Maybe<ResolversTypes['OrdreDeTravailStatus']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateStatusArgs, 'id' | 'status'>
  >;
  updateTimeSheet?: Resolver<
    Maybe<ResolversTypes['DailyTimeSheetAndEmployeeRecord']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTimeSheetArgs, 'timeSheetInput'>
  >;
  updateWeekType?: Resolver<Maybe<ResolversTypes['WeekType']>, ParentType, ContextType, RequireFields<MutationUpdateWeekTypeArgs, 'id'>>;
};

export type NotificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification'],
> = {
  __resolveType: TypeResolveFn<'OrdreDeTravailNotification' | 'OrdreDeTravailUpdateNotification', ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  employeeId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['NotificationStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['NotificationType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
};

export type NotificationAcknowledgementRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationAcknowledgementRecord'] = ResolversParentTypes['NotificationAcknowledgementRecord'],
> = {
  employeeFullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastNotificationType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notificationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notificationStatus?: Resolver<ResolversTypes['NotificationStatus'], ParentType, ContextType>;
  operatorBranchNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ordreDeTravail?: Resolver<ResolversTypes['OrdreDeTravailForNotificationAcknowledgementRecord'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OperatorRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OperatorRecord'] = ResolversParentTypes['OperatorRecord'],
> = {
  arrivalDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  confirmation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  departureDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  emailAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  employeeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isCurrentUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['CraneOperatorKind'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startingPoint?: Resolver<Maybe<ResolversTypes['CraneOperatorStartingPoint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OperatorRecordMobileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OperatorRecordMobile'] = ResolversParentTypes['OperatorRecordMobile'],
> = {
  confirmation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  contractAgreementId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  employeeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isCurrentUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['CraneOperatorKind'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrdreDeTravailAddendumRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdreDeTravailAddendumRecord'] = ResolversParentTypes['OrdreDeTravailAddendumRecord'],
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['OrdreDeTravailAddendumKind'], ParentType, ContextType>;
  manuallyCreated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrdreDeTravailAttachmentRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdreDeTravailAttachmentRecord'] = ResolversParentTypes['OrdreDeTravailAttachmentRecord'],
> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  downloadUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['OrdreDeTravailAttachmentKind'], ParentType, ContextType>;
  manuallyCreated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mimeType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uri?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrdreDeTravailForContractAgreementRecordResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['OrdreDeTravailForContractAgreementRecord'] = ResolversParentTypes['OrdreDeTravailForContractAgreementRecord'],
> = {
  client?: Resolver<Maybe<ResolversTypes['ClientRecord']>, ParentType, ContextType>;
  crane?: Resolver<Maybe<ResolversTypes['CraneRecord']>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  dispatchBranch?: Resolver<Maybe<ResolversTypes['BranchRecord']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  operators?: Resolver<Array<ResolversTypes['OperatorRecord']>, ParentType, ContextType>;
  representative?: Resolver<Maybe<ResolversTypes['RepresentativeRecord']>, ParentType, ContextType>;
  salesBranch?: Resolver<Maybe<ResolversTypes['BranchRecord']>, ParentType, ContextType>;
  site?: Resolver<Maybe<ResolversTypes['SiteRecord']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['OrdreDeTravailStatus'], ParentType, ContextType>;
  workDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workType?: Resolver<ResolversTypes['WorkTypeRecord'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrdreDeTravailForNotificationAcknowledgementRecordResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['OrdreDeTravailForNotificationAcknowledgementRecord'] = ResolversParentTypes['OrdreDeTravailForNotificationAcknowledgementRecord'],
> = {
  clientName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  craneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  dispatchBranchNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  representativeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  salesBranchNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['OrdreDeTravailStatus'], ParentType, ContextType>;
  workDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workTypeValue?: Resolver<ResolversTypes['WorkType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrdreDeTravailForSalesPredictionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdreDeTravailForSalesPrediction'] = ResolversParentTypes['OrdreDeTravailForSalesPrediction'],
> = {
  arrivalDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  client?: Resolver<ResolversTypes['ClientForSalesPredictionRecord'], ParentType, ContextType>;
  craneId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  craneOperators?: Resolver<Array<ResolversTypes['CraneOperatorForSalesPredictionRecord']>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  departureDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dispatchBranch?: Resolver<Maybe<ResolversTypes['BranchForSalesPredictionRecord']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mainEquipmentId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salesBranch?: Resolver<Maybe<ResolversTypes['BranchForSalesPredictionRecord']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['OrdreDeTravailStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['OrdreDeTravailType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrdreDeTravailFournisseurRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdreDeTravailFournisseurRecord'] = ResolversParentTypes['OrdreDeTravailFournisseurRecord'],
> = {
  billingCode?: Resolver<Maybe<ResolversTypes['BillingCodeRecord']>, ParentType, ContextType>;
  departureDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fournisseurId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  isPlanned?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numeroDeCommandeAchat?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['FournisseurType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrdreDeTravailNotificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdreDeTravailNotification'] = ResolversParentTypes['OrdreDeTravailNotification'],
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  employeeId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ordreDeTravailId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['NotificationStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['NotificationType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrdreDeTravailRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdreDeTravailRecord'] = ResolversParentTypes['OrdreDeTravailRecord'],
> = {
  accessories?: Resolver<Array<ResolversTypes['AccessoryRecord']>, ParentType, ContextType>;
  addenda?: Resolver<Array<ResolversTypes['OrdreDeTravailAddendumRecord']>, ParentType, ContextType>;
  arrivalDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  arrowLength?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  attachments?: Resolver<Array<ResolversTypes['OrdreDeTravailAttachmentRecord']>, ParentType, ContextType>;
  callReceivedFrom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  client?: Resolver<Maybe<ResolversTypes['ClientRecord']>, ParentType, ContextType>;
  contractMinimum?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  counterweight?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  crane?: Resolver<Maybe<ResolversTypes['CraneRecord']>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  departureDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  departureDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dispatchBranch?: Resolver<Maybe<ResolversTypes['BranchRecord']>, ParentType, ContextType>;
  estimatedDurationInDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  estimatedDurationInHours?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fournisseurs?: Resolver<Array<ResolversTypes['OrdreDeTravailFournisseurRecord']>, ParentType, ContextType>;
  gtNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isEstablishedSchedule?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSecondServing?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jibLength?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  maximumWeightToLift?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  nextOrdreDeTravailId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nextOrdreDeTravailNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offset?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  operators?: Resolver<Array<ResolversTypes['OperatorRecord']>, ParentType, ContextType>;
  preferredEquipmentType?: Resolver<Maybe<ResolversTypes['EquipmentTypeRecord']>, ParentType, ContextType>;
  preventReplacement?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  preventSubcontracting?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  previousOrdreDeTravailId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  previousOrdreDeTravailNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  readyDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  representative?: Resolver<Maybe<ResolversTypes['RepresentativeRecord']>, ParentType, ContextType>;
  requiredCapacity?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  salesBranch?: Resolver<Maybe<ResolversTypes['BranchRecord']>, ParentType, ContextType>;
  serviceCall?: Resolver<Maybe<ResolversTypes['ServiceCallRecord']>, ParentType, ContextType>;
  site?: Resolver<Maybe<ResolversTypes['SiteRecord']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['OrdreDeTravailStatus'], ParentType, ContextType>;
  subcontractor?: Resolver<Maybe<ResolversTypes['OrdreDeTravailSubcontractorRecord']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['OrdreDeTravailType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  validationStatus?: Resolver<ResolversTypes['OrdreDeTravailValidationStatus'], ParentType, ContextType>;
  waitingForInformationComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weekType?: Resolver<Maybe<ResolversTypes['WeekType']>, ParentType, ContextType>;
  workDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workRadius?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  workType?: Resolver<ResolversTypes['WorkTypeRecord'], ParentType, ContextType>;
  workTypeCategory?: Resolver<Maybe<ResolversTypes['WorkTypeCategory']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrdreDeTravailRecordMobileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdreDeTravailRecordMobile'] = ResolversParentTypes['OrdreDeTravailRecordMobile'],
> = {
  accessories?: Resolver<Array<ResolversTypes['AccessoryRecordMobile']>, ParentType, ContextType>;
  addendum?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  arrivalDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  arrowLength?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  callReceivedFrom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  client?: Resolver<Maybe<ResolversTypes['ClientRecordMobile']>, ParentType, ContextType>;
  contractAgreements?: Resolver<Maybe<Array<ResolversTypes['ContractAgreementStatusRecordMobile']>>, ParentType, ContextType>;
  contractMinimum?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  counterweight?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  crane?: Resolver<Maybe<ResolversTypes['CraneRecordMobile']>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  departureDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  departureDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dispatchBranch?: Resolver<Maybe<ResolversTypes['BranchRecordMobile']>, ParentType, ContextType>;
  estimatedDurationInDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  estimatedDurationInHours?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gtNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jibLength?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  maximumWeightToLift?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  nextOrdreDeTravailNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offset?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  operators?: Resolver<Array<ResolversTypes['OperatorRecordMobile']>, ParentType, ContextType>;
  previousOrdreDeTravailId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  previousOrdreDeTravailNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  readyDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  representative?: Resolver<Maybe<ResolversTypes['RepresentativeRecordMobile']>, ParentType, ContextType>;
  requiredCapacity?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  salesBranch?: Resolver<Maybe<ResolversTypes['BranchRecordMobile']>, ParentType, ContextType>;
  serviceCallId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  site?: Resolver<Maybe<ResolversTypes['SiteRecordMobile']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['OrdreDeTravailStatus'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  validationStatus?: Resolver<ResolversTypes['OrdreDeTravailValidationStatus'], ParentType, ContextType>;
  waitingForInformationComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weekType?: Resolver<Maybe<ResolversTypes['WeekType']>, ParentType, ContextType>;
  workDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workRadius?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  workType?: Resolver<ResolversTypes['WorkTypeRecordMobile'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrdreDeTravailSubcontractorRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdreDeTravailSubcontractorRecord'] = ResolversParentTypes['OrdreDeTravailSubcontractorRecord'],
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numeroDeCommandeAchat?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrdreDeTravailUpdateNotificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrdreDeTravailUpdateNotification'] = ResolversParentTypes['OrdreDeTravailUpdateNotification'],
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  employeeId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  notificationData?: Resolver<ResolversTypes['OrdreDeTravailUpdateNotificationDataRecord'], ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ordreDeTravailId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['NotificationStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['NotificationType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrdreDeTravailUpdateNotificationDataRecordResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['OrdreDeTravailUpdateNotificationDataRecord'] = ResolversParentTypes['OrdreDeTravailUpdateNotificationDataRecord'],
> = {
  originalAccessories?: Resolver<Maybe<Array<ResolversTypes['AccessoryUpdateRecord']>>, ParentType, ContextType>;
  originalArrivalDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  originalCraneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalCraneOperators?: Resolver<Maybe<Array<ResolversTypes['CraneOperatorUpdateRecord']>>, ParentType, ContextType>;
  originalDepartureDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  originalSiteLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrdreDeTravailWithDailyInformationRecordResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['OrdreDeTravailWithDailyInformationRecord'] = ResolversParentTypes['OrdreDeTravailWithDailyInformationRecord'],
> = {
  dailyOtsInformation?: Resolver<Maybe<Array<ResolversTypes['DailyOrdreDeTravailInformationRecord']>>, ParentType, ContextType>;
  isPrimaryContractAgreementFinished?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ordreDeTravail?: Resolver<Maybe<ResolversTypes['OrdreDeTravailRecord']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']> = {
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PensionRecordMobileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PensionRecordMobile'] = ResolversParentTypes['PensionRecordMobile'],
> = {
  numberOfPersons?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unitOfTimeCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PosteDeTravailRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PosteDeTravailRecord'] = ResolversParentTypes['PosteDeTravailRecord'],
> = {
  additionalDigit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workKind?: Resolver<Maybe<ResolversTypes['WorkKind']>, ParentType, ContextType>;
  workKindNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workType?: Resolver<Maybe<ResolversTypes['WorkType']>, ParentType, ContextType>;
  workTypeNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PosteDeTravailRecordMobileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PosteDeTravailRecordMobile'] = ResolversParentTypes['PosteDeTravailRecordMobile'],
> = {
  additionalDigit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workKind?: Resolver<Maybe<ResolversTypes['WorkKind']>, ParentType, ContextType>;
  workKindNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workType?: Resolver<Maybe<ResolversTypes['WorkType']>, ParentType, ContextType>;
  workTypeNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PremiumRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PremiumRecord'] = ResolversParentTypes['PremiumRecord'],
> = {
  durationInMinutes?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  extendedDistance?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  from?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  numberOfDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  numberOfHours?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  numberOfPersons?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  posteDeTravail?: Resolver<Maybe<ResolversTypes['PosteDeTravailRecordMobile']>, ParentType, ContextType>;
  startsOnNextDay?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  timeEntryInformation?: Resolver<Maybe<ResolversTypes['TimeEntryInformationRecord']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  unitNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  workKind?: Resolver<Maybe<ResolversTypes['WorkKind']>, ParentType, ContextType>;
  workType?: Resolver<Maybe<ResolversTypes['WorkType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PreparationEmployeeDistributionRecordResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PreparationEmployeeDistributionRecord'] = ResolversParentTypes['PreparationEmployeeDistributionRecord'],
> = {
  duration?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  employeeIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PullBulkEntityOfContractAgreementRecordMobileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PullBulkEntityOfContractAgreementRecordMobile'] = ResolversParentTypes['PullBulkEntityOfContractAgreementRecordMobile'],
> = {
  checkpoint?: Resolver<Maybe<ResolversTypes['Checkpoint']>, ParentType, ContextType>;
  documents?: Resolver<Array<ResolversTypes['ContractAgreementRecordMobile']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PullBulkEntityOfDailyTimeSheetRecordResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PullBulkEntityOfDailyTimeSheetRecord'] = ResolversParentTypes['PullBulkEntityOfDailyTimeSheetRecord'],
> = {
  checkpoint?: Resolver<Maybe<ResolversTypes['Checkpoint']>, ParentType, ContextType>;
  documents?: Resolver<Array<ResolversTypes['DailyTimeSheetRecord']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PullBulkEntityOfOrdreDeTravailRecordMobileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PullBulkEntityOfOrdreDeTravailRecordMobile'] = ResolversParentTypes['PullBulkEntityOfOrdreDeTravailRecordMobile'],
> = {
  checkpoint?: Resolver<Maybe<ResolversTypes['Checkpoint']>, ParentType, ContextType>;
  documents?: Resolver<Array<ResolversTypes['OrdreDeTravailRecordMobile']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PullBulkNotificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PullBulkNotification'] = ResolversParentTypes['PullBulkNotification'],
> = {
  checkpoint?: Resolver<Maybe<ResolversTypes['Checkpoint']>, ParentType, ContextType>;
  documents?: Resolver<Array<ResolversTypes['Notification']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  allEmployees?: Resolver<
    Array<ResolversTypes['GetAllEmployeeRecord']>,
    ParentType,
    ContextType,
    RequireFields<QueryAllEmployeesArgs, 'filter'>
  >;
  allPaginatedContractAgreements?: Resolver<
    ResolversTypes['GetAllPaginatedResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryAllPaginatedContractAgreementsArgs, 'request'>
  >;
  allPaginatedDailyTimeSheets?: Resolver<
    ResolversTypes['GetAllPaginatedDailyTimeSheetsResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryAllPaginatedDailyTimeSheetsArgs, 'request'>
  >;
  allPaginatedOrdreDeTravailNotificationsWithoutFinishedStatus?: Resolver<
    ResolversTypes['GetAllOrdreDeTravailNotificationsResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryAllPaginatedOrdreDeTravailNotificationsWithoutFinishedStatusArgs, 'request'>
  >;
  allPaginatedOrdresDeTravail?: Resolver<
    ResolversTypes['GetAllOrdresDeTravailResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryAllPaginatedOrdresDeTravailArgs, 'request'>
  >;
  allPaginatedOrdresDeTravailForValidation?: Resolver<
    ResolversTypes['GetAllOrdresDeTravailForValidationResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryAllPaginatedOrdresDeTravailForValidationArgs, 'request'>
  >;
  allTimeSheetEntries?: Resolver<
    ResolversTypes['GetAllPaginatedTimeSheetEntriesResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryAllTimeSheetEntriesArgs, 'request'>
  >;
  allTimeSheetsValidation?: Resolver<
    ResolversTypes['GetAllTimeSheetsValidationRecordsResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryAllTimeSheetsValidationArgs, 'request'>
  >;
  client?: Resolver<Maybe<ResolversTypes['clientConnection']>, ParentType, ContextType, Partial<QueryClientArgs>>;
  contractAgreementPdf?: Resolver<
    Maybe<Array<ResolversTypes['Byte']>>,
    ParentType,
    ContextType,
    RequireFields<QueryContractAgreementPdfArgs, 'id' | 'language' | 'originalVersion'>
  >;
  dailyTimeSheetById?: Resolver<
    Maybe<ResolversTypes['GetDailyTimeSheetRecord']>,
    ParentType,
    ContextType,
    Partial<QueryDailyTimeSheetByIdArgs>
  >;
  employeeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minimalVersionMobile?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<QueryMinimalVersionMobileArgs, 'currentVersion'>
  >;
  ordreDeTravailById?: Resolver<
    Maybe<ResolversTypes['OrdreDeTravailRecord']>,
    ParentType,
    ContextType,
    RequireFields<QueryOrdreDeTravailByIdArgs, 'id'>
  >;
  ordreDeTravailForSalesPrediction?: Resolver<
    Maybe<ResolversTypes['ordresDeTravailConnection']>,
    ParentType,
    ContextType,
    Partial<QueryOrdreDeTravailForSalesPredictionArgs>
  >;
  ordreDeTravailPdf?: Resolver<
    Maybe<Array<ResolversTypes['Byte']>>,
    ParentType,
    ContextType,
    RequireFields<QueryOrdreDeTravailPdfArgs, 'id' | 'language'>
  >;
  ordreDeTravailWithDailyInfo?: Resolver<
    Maybe<ResolversTypes['OrdreDeTravailWithDailyInformationRecord']>,
    ParentType,
    ContextType,
    RequireFields<QueryOrdreDeTravailWithDailyInfoArgs, 'id'>
  >;
  pullContractAgreements?: Resolver<
    ResolversTypes['PullBulkEntityOfContractAgreementRecordMobile'],
    ParentType,
    ContextType,
    RequireFields<QueryPullContractAgreementsArgs, 'limit'>
  >;
  pullDailyTimeSheets?: Resolver<
    ResolversTypes['PullBulkEntityOfDailyTimeSheetRecord'],
    ParentType,
    ContextType,
    RequireFields<QueryPullDailyTimeSheetsArgs, 'limit'>
  >;
  pullNotifications?: Resolver<
    ResolversTypes['PullBulkNotification'],
    ParentType,
    ContextType,
    RequireFields<QueryPullNotificationsArgs, 'limit'>
  >;
  pullOrdresDeTravail?: Resolver<
    ResolversTypes['PullBulkEntityOfOrdreDeTravailRecordMobile'],
    ParentType,
    ContextType,
    RequireFields<QueryPullOrdresDeTravailArgs, 'limit'>
  >;
  representatives?: Resolver<Array<ResolversTypes['RepresentativeRecord']>, ParentType, ContextType>;
  serviceCallNotifications?: Resolver<
    ResolversTypes['GetEmployeeServiceCallNotificationsResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryServiceCallNotificationsArgs, 'minNumberToReturn'>
  >;
  timeSheetPdf?: Resolver<
    Maybe<Array<ResolversTypes['Byte']>>,
    ParentType,
    ContextType,
    RequireFields<QueryTimeSheetPdfArgs, 'dailyTimeSheetId' | 'language' | 'timeSheetId'>
  >;
  upgradeTypeMobile?: Resolver<
    ResolversTypes['UpgradeType'],
    ParentType,
    ContextType,
    RequireFields<QueryUpgradeTypeMobileArgs, 'currentVersion'>
  >;
  workSites?: Resolver<Array<ResolversTypes['GetAllWorkSitesRecord']>, ParentType, ContextType>;
};

export type RepresentativeRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RepresentativeRecord'] = ResolversParentTypes['RepresentativeRecord'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mobilePhoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RepresentativeRecordMobileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RepresentativeRecordMobile'] = ResolversParentTypes['RepresentativeRecordMobile'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mobilePhoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequirementRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RequirementRecord'] = ResolversParentTypes['RequirementRecord'],
> = {
  code?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  natureCode?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceCallAccessoryGroupUpdateDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ServiceCallAccessoryGroupUpdateData'] = ResolversParentTypes['ServiceCallAccessoryGroupUpdateData'],
> = {
  branchName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceCallAccessoryTypeUpdateDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ServiceCallAccessoryTypeUpdateData'] = ResolversParentTypes['ServiceCallAccessoryTypeUpdateData'],
> = {
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shortDescriptionEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortDescriptionFr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceCallAccessoryUpdateDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceCallAccessoryUpdateData'] = ResolversParentTypes['ServiceCallAccessoryUpdateData'],
> = {
  accessoryGroup?: Resolver<Maybe<ResolversTypes['ServiceCallAccessoryGroupUpdateData']>, ParentType, ContextType>;
  accessoryOutOfInventory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  accessoryType?: Resolver<Maybe<ResolversTypes['ServiceCallAccessoryTypeUpdateData']>, ParentType, ContextType>;
  billable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  billingCode?: Resolver<Maybe<ResolversTypes['ServiceCallBillingUpdateData']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceCallBillingUpdateDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceCallBillingUpdateData'] = ResolversParentTypes['ServiceCallBillingUpdateData'],
> = {
  code?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  priceCode?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shortDescriptionEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortDescriptionFr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subCode?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceCallBoomConfigurationUpdateDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ServiceCallBoomConfigurationUpdateData'] = ResolversParentTypes['ServiceCallBoomConfigurationUpdateData'],
> = {
  boomLength?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  counterweight?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  jibLength?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  maxWeight?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  offsetAngle?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  radius?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceCallClientUpdateDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceCallClientUpdateData'] = ResolversParentTypes['ServiceCallClientUpdateData'],
> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceCallCraneConfigurationUpdateDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ServiceCallCraneConfigurationUpdateData'] = ResolversParentTypes['ServiceCallCraneConfigurationUpdateData'],
> = {
  capacity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  equipmentType?: Resolver<Maybe<ResolversTypes['ServiceCallEquipmentTypeUpdateData']>, ParentType, ContextType>;
  make?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  model?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceCallEquipmentTypeUpdateDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ServiceCallEquipmentTypeUpdateData'] = ResolversParentTypes['ServiceCallEquipmentTypeUpdateData'],
> = {
  abbreviation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceCallNotificationBillingRecordResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ServiceCallNotificationBillingRecord'] = ResolversParentTypes['ServiceCallNotificationBillingRecord'],
> = {
  codeSubCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  descriptionEn?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  descriptionFr?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceCallNotificationDataRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceCallNotificationDataRecord'] = ResolversParentTypes['ServiceCallNotificationDataRecord'],
> = {
  accessories?: Resolver<Maybe<Array<Maybe<ResolversTypes['ServiceCallAccessoryUpdateData']>>>, ParentType, ContextType>;
  boomConfiguration?: Resolver<Maybe<ResolversTypes['ServiceCallBoomConfigurationUpdateData']>, ParentType, ContextType>;
  client?: Resolver<Maybe<ResolversTypes['ServiceCallClientUpdateData']>, ParentType, ContextType>;
  craneConfiguration?: Resolver<Maybe<ResolversTypes['ServiceCallCraneConfigurationUpdateData']>, ParentType, ContextType>;
  preventReplacement?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  preventSubcontracting?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  project?: Resolver<Maybe<ResolversTypes['ServiceCallProjectUpdateData']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceCallNotificationRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceCallNotificationRecord'] = ResolversParentTypes['ServiceCallNotificationRecord'],
> = {
  billingCode?: Resolver<Maybe<ResolversTypes['ServiceCallNotificationBillingRecord']>, ParentType, ContextType>;
  continuityLastDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  employeeId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isFirstOrdreDeTravailOfContinuity?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  newDateTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  notificationData?: Resolver<Maybe<ResolversTypes['ServiceCallUpdatedNotificationDataRecord']>, ParentType, ContextType>;
  oldDateTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  ordreDeTravailArrivalDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ordreDeTravailClient?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ordreDeTravailDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  ordreDeTravailId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  ordreDeTravailNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantityDeleted?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['NotificationStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ServiceCallNotificationRecordType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceCallProjectUpdateDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceCallProjectUpdateData'] = ResolversParentTypes['ServiceCallProjectUpdateData'],
> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  arrivalDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  departureDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  durationInDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hoursPerDay?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  isSecondServing?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  readyDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  workType?: Resolver<Maybe<ResolversTypes['WorkType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceCallRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceCallRecord'] = ResolversParentTypes['ServiceCallRecord'],
> = {
  friendlyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permalink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceCallUpdatedNotificationDataRecordResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ServiceCallUpdatedNotificationDataRecord'] = ResolversParentTypes['ServiceCallUpdatedNotificationDataRecord'],
> = {
  newNotificationData?: Resolver<ResolversTypes['ServiceCallNotificationDataRecord'], ParentType, ContextType>;
  originalNotificationData?: Resolver<ResolversTypes['ServiceCallNotificationDataRecord'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SiteRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SiteRecord'] = ResolversParentTypes['SiteRecord'],
> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobilePhoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requirements?: Resolver<Maybe<Array<ResolversTypes['RequirementRecord']>>, ParentType, ContextType>;
  toSeeOnSite?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SiteRecordMobileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SiteRecordMobile'] = ResolversParentTypes['SiteRecordMobile'],
> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobilePhoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  toSeeOnSite?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubmitDailyTimeSheetMutationResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubmitDailyTimeSheetMutationResponse'] = ResolversParentTypes['SubmitDailyTimeSheetMutationResponse'],
> = {
  dailyTimeSheetWithEmployee?: Resolver<Maybe<ResolversTypes['DailyTimeSheetAndEmployeeRecord']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<ResolversTypes['SubmitDailyTimesheetMutationResponseError']>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubmitDailyTimesheetMutationResponseErrorResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubmitDailyTimesheetMutationResponseError'] = ResolversParentTypes['SubmitDailyTimesheetMutationResponseError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription'],
> = {
  onContractAgreementUpdated?: SubscriptionResolver<
    ResolversTypes['PullBulkEntityOfContractAgreementRecordMobile'],
    'onContractAgreementUpdated',
    ParentType,
    ContextType,
    RequireFields<SubscriptionOnContractAgreementUpdatedArgs, 'employeeId'>
  >;
  onDailyTimeSheetUpdated?: SubscriptionResolver<
    ResolversTypes['PullBulkEntityOfDailyTimeSheetRecord'],
    'onDailyTimeSheetUpdated',
    ParentType,
    ContextType,
    RequireFields<SubscriptionOnDailyTimeSheetUpdatedArgs, 'employeeId'>
  >;
  onNotificationUpdated?: SubscriptionResolver<
    ResolversTypes['PullBulkNotification'],
    'onNotificationUpdated',
    ParentType,
    ContextType,
    RequireFields<SubscriptionOnNotificationUpdatedArgs, 'employeeId'>
  >;
  onOrdreDeTravailUpdated?: SubscriptionResolver<
    ResolversTypes['PullBulkEntityOfOrdreDeTravailRecordMobile'],
    'onOrdreDeTravailUpdated',
    ParentType,
    ContextType,
    RequireFields<SubscriptionOnOrdreDeTravailUpdatedArgs, 'employeeId'>
  >;
  onServiceCallNotificationUpdated?: SubscriptionResolver<
    ResolversTypes['EmployeeServiceCallNotificationResponse'],
    'onServiceCallNotificationUpdated',
    ParentType,
    ContextType,
    RequireFields<SubscriptionOnServiceCallNotificationUpdatedArgs, 'employeeId'>
  >;
};

export type TimeEntryInformationRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimeEntryInformationRecord'] = ResolversParentTypes['TimeEntryInformationRecord'],
> = {
  automaticPrime?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  double?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  single?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  singleAndAHalf?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  variablePrime?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeEntryInformationRecordMobileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimeEntryInformationRecordMobile'] = ResolversParentTypes['TimeEntryInformationRecordMobile'],
> = {
  automaticPrime?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  double?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  single?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  singleAndAHalf?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  variablePrime?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeSheetEntryRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimeSheetEntryRecord'] = ResolversParentTypes['TimeSheetEntryRecord'],
> = {
  attachments?: Resolver<Maybe<Array<ResolversTypes['AttachmentRecord']>>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  completed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  contractAgreementId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contractAgreementNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  craneOperatorId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  entryType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ordreDeTravailId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ordreDeTravailNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  premiums?: Resolver<Maybe<Array<ResolversTypes['PremiumRecord']>>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  workTimeRanges?: Resolver<Maybe<Array<ResolversTypes['WorkTimeRangeRecord']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravellingExpensesRecordMobileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TravellingExpensesRecordMobile'] = ResolversParentTypes['TravellingExpensesRecordMobile'],
> = {
  distanceTravelled?: Resolver<ResolversTypes['DistanceFeeRange'], ParentType, ContextType>;
  extendedDistance?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravellingFeeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TravellingFee'] = ResolversParentTypes['TravellingFee'],
> = {
  distanceTravelled?: Resolver<ResolversTypes['DistanceFeeRange'], ParentType, ContextType>;
  employeeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  extendedDistance?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UuidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['UUID'], any> {
  name: 'UUID';
}

export type UpdateContractAgreementRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateContractAgreementRecord'] = ResolversParentTypes['UpdateContractAgreementRecord'],
> = {
  contractAgreement?: Resolver<Maybe<ResolversTypes['ContractAgreementWithOrdreDeTravailRecord']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserInformationRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserInformationRecord'] = ResolversParentTypes['UserInformationRecord'],
> = {
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkTimeRangeRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WorkTimeRangeRecord'] = ResolversParentTypes['WorkTimeRangeRecord'],
> = {
  durationInMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  posteDeTravail?: Resolver<Maybe<ResolversTypes['PosteDeTravailRecordMobile']>, ParentType, ContextType>;
  startsOnNextDay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  timeEntryInformation?: Resolver<Maybe<ResolversTypes['TimeEntryInformationRecord']>, ParentType, ContextType>;
  unitNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  workKind?: Resolver<Maybe<ResolversTypes['WorkKind']>, ParentType, ContextType>;
  workKindNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  workType?: Resolver<ResolversTypes['WorkType'], ParentType, ContextType>;
  workTypeNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkTypeRecordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WorkTypeRecord'] = ResolversParentTypes['WorkTypeRecord'],
> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['WorkType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkTypeRecordMobileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WorkTypeRecordMobile'] = ResolversParentTypes['WorkTypeRecordMobile'],
> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['WorkType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['clientConnection'] = ResolversParentTypes['clientConnection'],
> = {
  edges?: Resolver<Maybe<Array<ResolversTypes['clientEdge']>>, ParentType, ContextType>;
  nodes?: Resolver<Maybe<Array<ResolversTypes['Client']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['clientEdge'] = ResolversParentTypes['clientEdge'],
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrdresDeTravailConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ordresDeTravailConnection'] = ResolversParentTypes['ordresDeTravailConnection'],
> = {
  edges?: Resolver<Maybe<Array<ResolversTypes['ordresDeTravailEdge']>>, ParentType, ContextType>;
  nodes?: Resolver<Maybe<Array<ResolversTypes['OrdreDeTravailForSalesPrediction']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrdresDeTravailEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ordresDeTravailEdge'] = ResolversParentTypes['ordresDeTravailEdge'],
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['OrdreDeTravailForSalesPrediction'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AccessoryRecord?: AccessoryRecordResolvers<ContextType>;
  AccessoryRecordMobile?: AccessoryRecordMobileResolvers<ContextType>;
  AccessoryUpdateRecord?: AccessoryUpdateRecordResolvers<ContextType>;
  AdditionalTimeRecord?: AdditionalTimeRecordResolvers<ContextType>;
  AttachmentRecord?: AttachmentRecordResolvers<ContextType>;
  BaseFee?: BaseFeeResolvers<ContextType>;
  BillingCodeRecord?: BillingCodeRecordResolvers<ContextType>;
  BranchForSalesPredictionRecord?: BranchForSalesPredictionRecordResolvers<ContextType>;
  BranchRecord?: BranchRecordResolvers<ContextType>;
  BranchRecordMobile?: BranchRecordMobileResolvers<ContextType>;
  Byte?: GraphQLScalarType;
  Checkpoint?: CheckpointResolvers<ContextType>;
  Client?: ClientResolvers<ContextType>;
  ClientForSalesPredictionRecord?: ClientForSalesPredictionRecordResolvers<ContextType>;
  ClientRecord?: ClientRecordResolvers<ContextType>;
  ClientRecordMobile?: ClientRecordMobileResolvers<ContextType>;
  ClientTrainingRequirement?: ClientTrainingRequirementResolvers<ContextType>;
  ContractAgreementAccessoryRecord?: ContractAgreementAccessoryRecordResolvers<ContextType>;
  ContractAgreementAccessoryRecordMobile?: ContractAgreementAccessoryRecordMobileResolvers<ContextType>;
  ContractAgreementOperatorRecord?: ContractAgreementOperatorRecordResolvers<ContextType>;
  ContractAgreementOrdreDeTravailRecord?: ContractAgreementOrdreDeTravailRecordResolvers<ContextType>;
  ContractAgreementRecordMobile?: ContractAgreementRecordMobileResolvers<ContextType>;
  ContractAgreementSendRequestRecord?: ContractAgreementSendRequestRecordResolvers<ContextType>;
  ContractAgreementSendRequestRecordMobile?: ContractAgreementSendRequestRecordMobileResolvers<ContextType>;
  ContractAgreementStatusRecord?: ContractAgreementStatusRecordResolvers<ContextType>;
  ContractAgreementStatusRecordMobile?: ContractAgreementStatusRecordMobileResolvers<ContextType>;
  ContractAgreementWithOrdreDeTravailRecord?: ContractAgreementWithOrdreDeTravailRecordResolvers<ContextType>;
  CountEmployeeDistributionRecord?: CountEmployeeDistributionRecordResolvers<ContextType>;
  CountFee?: CountFeeResolvers<ContextType>;
  CraneOperatorForSalesPredictionRecord?: CraneOperatorForSalesPredictionRecordResolvers<ContextType>;
  CraneOperatorUpdateRecord?: CraneOperatorUpdateRecordResolvers<ContextType>;
  CraneRecord?: CraneRecordResolvers<ContextType>;
  CraneRecordMobile?: CraneRecordMobileResolvers<ContextType>;
  CreateCraneOperatorResponse?: CreateCraneOperatorResponseResolvers<ContextType>;
  DailyOrdreDeTravailInformationRecord?: DailyOrdreDeTravailInformationRecordResolvers<ContextType>;
  DailyTimeSheetAndEmployeeRecord?: DailyTimeSheetAndEmployeeRecordResolvers<ContextType>;
  DailyTimeSheetRecord?: DailyTimeSheetRecordResolvers<ContextType>;
  Date?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  Decimal?: GraphQLScalarType;
  DefaultTimeRangeRecord?: DefaultTimeRangeRecordResolvers<ContextType>;
  DefaultTimeRangeRecordMobile?: DefaultTimeRangeRecordMobileResolvers<ContextType>;
  DistributedTimeRangeRecord?: DistributedTimeRangeRecordResolvers<ContextType>;
  DistributedTimeRangeRecordMobile?: DistributedTimeRangeRecordMobileResolvers<ContextType>;
  DurationFee?: DurationFeeResolvers<ContextType>;
  EmployeeDistributedDefaultTimeRangeRecord?: EmployeeDistributedDefaultTimeRangeRecordResolvers<ContextType>;
  EmployeeDistributedTimeRangeRecord?: EmployeeDistributedTimeRangeRecordResolvers<ContextType>;
  EmployeeDistributedTravellingExpensesRecordMobile?: EmployeeDistributedTravellingExpensesRecordMobileResolvers<ContextType>;
  EmployeeServiceCallNotificationResponse?: EmployeeServiceCallNotificationResponseResolvers<ContextType>;
  EmployeeTimeSheetEntriesRecord?: EmployeeTimeSheetEntriesRecordResolvers<ContextType>;
  EmployeeTimeSheetEntryRecord?: EmployeeTimeSheetEntryRecordResolvers<ContextType>;
  EquipmentTypeRecord?: EquipmentTypeRecordResolvers<ContextType>;
  GenerateContractAgreementNumberRecord?: GenerateContractAgreementNumberRecordResolvers<ContextType>;
  GetAllDailyTimeSheetsRecord?: GetAllDailyTimeSheetsRecordResolvers<ContextType>;
  GetAllEmployeeRecord?: GetAllEmployeeRecordResolvers<ContextType>;
  GetAllOrdreDeTravailNotificationsResponse?: GetAllOrdreDeTravailNotificationsResponseResolvers<ContextType>;
  GetAllOrdresDeTravailForValidationListItem?: GetAllOrdresDeTravailForValidationListItemResolvers<ContextType>;
  GetAllOrdresDeTravailForValidationResponse?: GetAllOrdresDeTravailForValidationResponseResolvers<ContextType>;
  GetAllOrdresDeTravailResponse?: GetAllOrdresDeTravailResponseResolvers<ContextType>;
  GetAllOrdresDeTravailResponseListItem?: GetAllOrdresDeTravailResponseListItemResolvers<ContextType>;
  GetAllPaginatedContractAgreementRecord?: GetAllPaginatedContractAgreementRecordResolvers<ContextType>;
  GetAllPaginatedDailyTimeSheetsResponse?: GetAllPaginatedDailyTimeSheetsResponseResolvers<ContextType>;
  GetAllPaginatedResponse?: GetAllPaginatedResponseResolvers<ContextType>;
  GetAllPaginatedTimeSheetEntriesResponse?: GetAllPaginatedTimeSheetEntriesResponseResolvers<ContextType>;
  GetAllTimeSheetsValidationRecordsResponse?: GetAllTimeSheetsValidationRecordsResponseResolvers<ContextType>;
  GetAllWorkSitesRecord?: GetAllWorkSitesRecordResolvers<ContextType>;
  GetDailyTimeSheetAttachmentRecord?: GetDailyTimeSheetAttachmentRecordResolvers<ContextType>;
  GetDailyTimeSheetPremiumRecord?: GetDailyTimeSheetPremiumRecordResolvers<ContextType>;
  GetDailyTimeSheetRecord?: GetDailyTimeSheetRecordResolvers<ContextType>;
  GetDailyTimeSheetTimeSheetEntryRecord?: GetDailyTimeSheetTimeSheetEntryRecordResolvers<ContextType>;
  GetDailyTimeSheetWorkTimeRangeRecord?: GetDailyTimeSheetWorkTimeRangeRecordResolvers<ContextType>;
  GetEmployeeServiceCallNotificationsResponse?: GetEmployeeServiceCallNotificationsResponseResolvers<ContextType>;
  Long?: GraphQLScalarType;
  MechanicalFailureTimeRangeRecord?: MechanicalFailureTimeRangeRecordResolvers<ContextType>;
  MechanicalFailureTimeRangeRecordMobile?: MechanicalFailureTimeRangeRecordMobileResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Notification?: NotificationResolvers<ContextType>;
  NotificationAcknowledgementRecord?: NotificationAcknowledgementRecordResolvers<ContextType>;
  OperatorRecord?: OperatorRecordResolvers<ContextType>;
  OperatorRecordMobile?: OperatorRecordMobileResolvers<ContextType>;
  OrdreDeTravailAddendumRecord?: OrdreDeTravailAddendumRecordResolvers<ContextType>;
  OrdreDeTravailAttachmentRecord?: OrdreDeTravailAttachmentRecordResolvers<ContextType>;
  OrdreDeTravailForContractAgreementRecord?: OrdreDeTravailForContractAgreementRecordResolvers<ContextType>;
  OrdreDeTravailForNotificationAcknowledgementRecord?: OrdreDeTravailForNotificationAcknowledgementRecordResolvers<ContextType>;
  OrdreDeTravailForSalesPrediction?: OrdreDeTravailForSalesPredictionResolvers<ContextType>;
  OrdreDeTravailFournisseurRecord?: OrdreDeTravailFournisseurRecordResolvers<ContextType>;
  OrdreDeTravailNotification?: OrdreDeTravailNotificationResolvers<ContextType>;
  OrdreDeTravailRecord?: OrdreDeTravailRecordResolvers<ContextType>;
  OrdreDeTravailRecordMobile?: OrdreDeTravailRecordMobileResolvers<ContextType>;
  OrdreDeTravailSubcontractorRecord?: OrdreDeTravailSubcontractorRecordResolvers<ContextType>;
  OrdreDeTravailUpdateNotification?: OrdreDeTravailUpdateNotificationResolvers<ContextType>;
  OrdreDeTravailUpdateNotificationDataRecord?: OrdreDeTravailUpdateNotificationDataRecordResolvers<ContextType>;
  OrdreDeTravailWithDailyInformationRecord?: OrdreDeTravailWithDailyInformationRecordResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  PensionRecordMobile?: PensionRecordMobileResolvers<ContextType>;
  PosteDeTravailRecord?: PosteDeTravailRecordResolvers<ContextType>;
  PosteDeTravailRecordMobile?: PosteDeTravailRecordMobileResolvers<ContextType>;
  PremiumRecord?: PremiumRecordResolvers<ContextType>;
  PreparationEmployeeDistributionRecord?: PreparationEmployeeDistributionRecordResolvers<ContextType>;
  PullBulkEntityOfContractAgreementRecordMobile?: PullBulkEntityOfContractAgreementRecordMobileResolvers<ContextType>;
  PullBulkEntityOfDailyTimeSheetRecord?: PullBulkEntityOfDailyTimeSheetRecordResolvers<ContextType>;
  PullBulkEntityOfOrdreDeTravailRecordMobile?: PullBulkEntityOfOrdreDeTravailRecordMobileResolvers<ContextType>;
  PullBulkNotification?: PullBulkNotificationResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RepresentativeRecord?: RepresentativeRecordResolvers<ContextType>;
  RepresentativeRecordMobile?: RepresentativeRecordMobileResolvers<ContextType>;
  RequirementRecord?: RequirementRecordResolvers<ContextType>;
  ServiceCallAccessoryGroupUpdateData?: ServiceCallAccessoryGroupUpdateDataResolvers<ContextType>;
  ServiceCallAccessoryTypeUpdateData?: ServiceCallAccessoryTypeUpdateDataResolvers<ContextType>;
  ServiceCallAccessoryUpdateData?: ServiceCallAccessoryUpdateDataResolvers<ContextType>;
  ServiceCallBillingUpdateData?: ServiceCallBillingUpdateDataResolvers<ContextType>;
  ServiceCallBoomConfigurationUpdateData?: ServiceCallBoomConfigurationUpdateDataResolvers<ContextType>;
  ServiceCallClientUpdateData?: ServiceCallClientUpdateDataResolvers<ContextType>;
  ServiceCallCraneConfigurationUpdateData?: ServiceCallCraneConfigurationUpdateDataResolvers<ContextType>;
  ServiceCallEquipmentTypeUpdateData?: ServiceCallEquipmentTypeUpdateDataResolvers<ContextType>;
  ServiceCallNotificationBillingRecord?: ServiceCallNotificationBillingRecordResolvers<ContextType>;
  ServiceCallNotificationDataRecord?: ServiceCallNotificationDataRecordResolvers<ContextType>;
  ServiceCallNotificationRecord?: ServiceCallNotificationRecordResolvers<ContextType>;
  ServiceCallProjectUpdateData?: ServiceCallProjectUpdateDataResolvers<ContextType>;
  ServiceCallRecord?: ServiceCallRecordResolvers<ContextType>;
  ServiceCallUpdatedNotificationDataRecord?: ServiceCallUpdatedNotificationDataRecordResolvers<ContextType>;
  SiteRecord?: SiteRecordResolvers<ContextType>;
  SiteRecordMobile?: SiteRecordMobileResolvers<ContextType>;
  SubmitDailyTimeSheetMutationResponse?: SubmitDailyTimeSheetMutationResponseResolvers<ContextType>;
  SubmitDailyTimesheetMutationResponseError?: SubmitDailyTimesheetMutationResponseErrorResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  TimeEntryInformationRecord?: TimeEntryInformationRecordResolvers<ContextType>;
  TimeEntryInformationRecordMobile?: TimeEntryInformationRecordMobileResolvers<ContextType>;
  TimeSheetEntryRecord?: TimeSheetEntryRecordResolvers<ContextType>;
  TravellingExpensesRecordMobile?: TravellingExpensesRecordMobileResolvers<ContextType>;
  TravellingFee?: TravellingFeeResolvers<ContextType>;
  UUID?: GraphQLScalarType;
  UpdateContractAgreementRecord?: UpdateContractAgreementRecordResolvers<ContextType>;
  UserInformationRecord?: UserInformationRecordResolvers<ContextType>;
  WorkTimeRangeRecord?: WorkTimeRangeRecordResolvers<ContextType>;
  WorkTypeRecord?: WorkTypeRecordResolvers<ContextType>;
  WorkTypeRecordMobile?: WorkTypeRecordMobileResolvers<ContextType>;
  clientConnection?: ClientConnectionResolvers<ContextType>;
  clientEdge?: ClientEdgeResolvers<ContextType>;
  ordresDeTravailConnection?: OrdresDeTravailConnectionResolvers<ContextType>;
  ordresDeTravailEdge?: OrdresDeTravailEdgeResolvers<ContextType>;
};
