import { WorkKindNumberValue } from '../../common/enums/WorkKindEnum';
import { OrdreDeTravailValidationStatus, TimeSheetEntryValidationStatus } from '../../generated/schemas';
import { CompilablePremiumsEnum, NonCompilablePremiumsEnum } from '../../timesheet/enums/PremiumsEnum';
import { AdditionalHourType } from '../../validationNext/components/contractAgreement/AdditionalHours';

const button = {
  add: 'Add',
  close: 'Close',
  save: 'Save',
  submit: 'Submit all',
  validateAndContinue: 'Validate and continue',
  submitTimeSheets: 'Submit timesheets only',
  expandAll: 'Expand all',
  collapseAll: 'Collapse all',
  filter: 'Filter',
  onlyNotSubmitted: 'Show not-submitted only',
};

const validationsStatuses = {
  [OrdreDeTravailValidationStatus.DocumentsReceived]: 'Documents received',
  [OrdreDeTravailValidationStatus.Open]: 'Open',
  [OrdreDeTravailValidationStatus.Submitted]: 'Submitted',
  [OrdreDeTravailValidationStatus.Upcoming]: 'Upcoming',
  [OrdreDeTravailValidationStatus.Entering]: 'Entering',
  [OrdreDeTravailValidationStatus.WaitingForInformation]: 'Waiting for information',
  [OrdreDeTravailValidationStatus.Validated]: 'Validated',
  [OrdreDeTravailValidationStatus.Cancelled]: 'Cancelled',
};

const timeSheetEntryValidationStatus = {
  [TimeSheetEntryValidationStatus.Open]: 'Open',
  [TimeSheetEntryValidationStatus.DocumentsReceived]: 'Documents received',
  [TimeSheetEntryValidationStatus.Submitted]: 'Submitted',
};

const timeSheetDistributedTimes = {
  status: {
    automatic: 'Automatic suggestion',
    expired: 'Automatic suggestion outdated',
    modified: 'Changes made to the automatic suggestion',
  },
  tooltip: 'Suggestion generated at {{ date }}',
};

const headers = {
  validationStatus: 'Validation Status',
  number: 'OT Number',
  branch: 'Dispatch branch',
  operatorsBranches: 'Operators branches',
  salesBranches: 'Sales branch',
  unitNo: 'Unit No',
  client: 'Client',
  operators: 'Crane operators',
  documentStatus: 'OT Status',
  date: 'Date',
  workType: 'Nature of the work',
  contractMinimum: 'Minimum contract hours',
  requiredCapacity: 'Required capacity',
  workDescription: 'Work description',
  maximumWeightToLift: 'Max. Weight to Lift',
  representativeName: 'Project manager',
  site: 'Worksite',
  timeSheetEntryValidationStatus: 'Statut de validation',
};

const list = {
  title: 'Validations',
  headers,
  validationsStatuses,
};

const premiums = {
  title: 'Premiums',
  addPremium: 'Add',
  numberOfDays: 'Nb of days',
  numberOfHours: 'Nb of hours',
  hours_one: '{{count}} hour',
  hours_other: '{{count}} hours',
  days_one: '{{count}} day',
  days_other: '{{count}} days',
  to: 'to',
  travelledKm: 'Travelled km',
  [CompilablePremiumsEnum.PreparationPremium]: 'Work Preparation',
  [NonCompilablePremiumsEnum.CarAllowancePremium]: 'Car Allowance',
  [NonCompilablePremiumsEnum.MealPremium]: 'Meals',
  [NonCompilablePremiumsEnum.TaxisPremium]: 'Taxi Fees',
  [NonCompilablePremiumsEnum.ParkingPremium]: 'Parking Fees',
  [NonCompilablePremiumsEnum.TravellingExpensesPremium]: 'Travelling Expenses',
  [NonCompilablePremiumsEnum.PensionPremium]: 'Pension',
  [CompilablePremiumsEnum.ManTransportPremium]: 'Man Transport',
  [CompilablePremiumsEnum.ManTimePremium]: 'Man Time',
  [CompilablePremiumsEnum.NoLunchTimesPremium]: 'No Lunch',
  [CompilablePremiumsEnum.NoBreakTimesPremium]: 'No Break',
  [WorkKindNumberValue.travelExpenses49To72Km]: '49 - 72 km',
  [WorkKindNumberValue.travelExpenses73To88Km]: '73 - 88 km',
  [WorkKindNumberValue.travelExpenses89To119Km]: '89 - 119 km',
  [WorkKindNumberValue.travelExpenses120AndMoreKm]: '120 + km',
  [WorkKindNumberValue.travelExpensesSiteTransfer]: 'Site Transfer',
  [WorkKindNumberValue.roomAndBoardDay]: 'Day',
  [WorkKindNumberValue.roomAndBoardTwoPlusWeeks]: '2+ weeks',
  workKind: 'Work Kind',
  linkedTo: 'Linked to',
  notLinked: 'Not linked',
  columns: {
    premium: 'Premium',
    posteDeTravail: 'Poste',
    branch: 'Branch',
  },
  unknown: 'Incomplete Entry',
  empty: 'Select',
};

const additionalTimes = {
  title: 'Additional times',
  options: {
    [CompilablePremiumsEnum.ManTransportPremium]: 'Man Transport',
    [CompilablePremiumsEnum.ManTimePremium]: 'Man Time',
    [CompilablePremiumsEnum.NoLunchTimesPremium]: 'No Lunch',
    [CompilablePremiumsEnum.NoBreakTimesPremium]: 'No Break',
  },
  type: {
    [AdditionalHourType.ManTransport]: 'Man Transport',
    [AdditionalHourType.ManTime]: 'Man Time',
    [AdditionalHourType.NoLunchTime]: 'No Lunch',
    [AdditionalHourType.NoBreakTime]: 'No Break',
    empty: 'Select',
  },
};

const timesheet = {
  title: 'Timesheet',
  days_one: 'day',
  days_other: 'days',
  premiums,
};

const submitDocumentsModal = {
  submitMenu: 'Submit all timesheets',
  title: 'Submit documents',
  content:
    'Once the documents have been submitted, you will have 48 hours from the last day of the OT to make modifications and resubmit. After this time, it will no longer be possible to modify the information.',
  submittedContent: 'Are you sure you want to resubmit the documents following your changes?',
  includeNotLinked: 'Include not linked time blocks',
  cancel: 'Cancel',
  submit: 'Submit',
};

const submitTimesheetsModal = {
  title: 'Submit all timesheets?',
  content: 'Timesheets will be submitted to the AS400.',
  cancel: 'Cancel',
  submit: 'Submit',
};

const differingRatesModal = {
  title: 'Sales rates differs',
  content: `Caution : the OT [{{ otNumber }}] contains at least one billing code which rate differs from the system rate. Validate sales rate before submitting.`,
  cancel: 'Return',
  submit: 'Keep rates',
};

const submitSelectionDocumentsModal = {
  title: 'Select the validated documents linked to OT #{{ otNumber }} to submit.',
  close: 'Close',
  submit_submit: 'Submit selected documents',
  submit_submitting: 'Submitting documents...',
  submitCodeDeFacturation: 'Codes de facturation',
  submitMenu: 'Select the documents to submit',
  timeSheetContent: 'Timesheets ({{ employeeNames }})',
  contractAgreementContent: 'Contract agreement #{{ contractAgreementNumber }}',
  codeDeFacturationContent: 'Submitted at {{ submittedAt }} by {{ name }}',
  contractAgreement: 'Contract agreement',
  documentStatus_submitting: 'Submitting',
  documentStatus_submitted: 'Submitted',
  documentStatus_waiting: 'Waiting',
  documentStatus_ready: 'Ready',
  documentStatus_rejected: 'Error',
};

const addTimeRangeModal = {
  title: 'Add',
  addTimeRange: 'Time range',
  addCompilablePremium: 'Compilable premium',
  indemnityType: 'Indemnity type',
  errors: {
    required: 'Required',
    emptyFields: `You have empty fields`,
    toGreaterThanFrom: `The end time must be greater than the start time`,
    invalidPosteDeTravail: `Invalid Poste De Travail`,
    incorrectRestOrMealTimeRange: `The time entered in a No break/No lunch is incorrect`,
    invalidGtNumber: 'The GT Number is invalid',
  },
  tableHeader: {
    linkTo: 'Link to CA',
    linkToOt: 'Link to OT',
    from: 'From',
    to: 'To',
    quantity: 'Quantity',
    code: 'Poste',
    single: 'Single',
    singleAndHalf: 'Half',
    double: 'Double',
    variablePrime: 'Var Pr.',
    automaticPrime: 'Auto Pr.',
    rate: 'Rate',
    gtNumber: '#GT',
    workOrderNumber: 'Equi./WO',
  },
  cancel: 'Cancel',
  add: 'Add',
};

const splitTimeRangeModal = {
  title: 'Separate a time range',
  addNewTimeRange: 'Add a time range',
  warningTotalHoursQuantity: `Please note that the quantity of hours entered is different from the quantity of initials hours.`,
  errors: {
    emptyFields: `Time ranges are empty. Thank you for correcting.`,
    overlappingFields: `Some time ranges overlap. Thank you for correcting.`,
    incorrectRestOrMealTimeRange: `The time entered in a No break/No lunch is incorrect.`,
    minIsFifteenMinutes: `Time ranges must be a minimum of 15 minutes.`,
  },
  cancel: 'Cancel',
  split: 'Split',
};

const waitingForInformationModal = {
  title: 'Move to "Waiting for information" ?',
  content: 'Please enter a comment...',
  comment: 'Comment',
  cancel: 'Cancel',
  confirm: 'Confirm',
};

const details = {
  validations: 'Validation',
  submitted: 'Submitted',
  completed: 'Completed',
  ongoing: 'Ongoing',
  upcoming: 'Upcoming',
  submitPopup: 'Validate all timesheets and save your changes before submitting',
  contractAgreement: 'Contract agreement',
  timesheet,
  ot: 'ot',
  validationsStatuses,
  button,
  overlapTimeRangeError: 'Overlapping time ranges',
  fromBiggerThanToError: 'From cannot be bigger than to',
  noTimeRangeError: 'No time range in list',
  hours: 'hour(s)',
  saveSuccessMessage: 'The modifications of the documents linked to OT {{otNumber}} have been saved.',
  validationErrorMsg: 'The form contains validation errors. Please correct them before submitting.',
  submitErrorMsg: 'A technical error occurred while submitting to the AS400',
  rateValidationErrorMsg: 'A technical error occurred while validating rates',
  submitSuccessMsg: 'The validation was successfully submitted to the AS400',
  submitDocumentsModal,
  differingRatesModal,
  submitTimesheetsModal,
  submitSelectionDocumentsModal,
  splitTimeRangeModal,
  addTimeRangeModal,
  waitingForInformationModal,
  totalCraneTimeError: 'Service hours in the Contract agreement do not match Timesheet entries',
  reviewedBy: 'Reviewed by',
};

const serviceHourTable = {
  title: 'Service hours',
  total: 'Total billable',
  craneService: 'Crane service',
  addButtonLabel: 'Add',
  noData: 'No service hours',
  fromBiggerThanToError: 'From cannot be bigger than to',
  overlapTimeRangeError: 'Overlapping time ranges',
  unmatchTotalExpectationError: 'Crane service hours do not match minimum call ({{expectedServiceHoursAmount}} hours)',
  header: {
    from: 'From',
    to: 'To',
    activity: 'Activity',
    single: 'Sin.',
    singleAndAHalf: 'Half',
    double: 'Dou.',
    dispatch: 'Disp.',
    quantity: 'Qty',
  },
  options: {
    lunchBreak: 'Lunch',
    serviceHour: 'Bill.',
  },
};

const inlineTimesheet = {
  title: 'Timesheet',
  header: {
    linkedTo: 'Linked to',
    from: 'From',
    to: 'To',
    qty: 'Qty',
    code: 'Poste',
    branch: 'Branch',
    single: 'Sin.',
    singleAndHalf: 'Half',
    double: 'Dou.',
    automaticPrime: 'Aut. P.',
    variablePrime: 'Var. P.',
    rate: 'Rate',
    notReceived: 'Timesheet not received',
    gtNumber: '#GT',
    workOrderNumber: 'Equi./WO',
  },
  footer: {
    total: 'Total',
  },
};

const detailTabs = {
  billing: 'Billing',
  pay: 'Pay',
  operators: 'Operators',
  dispatch: 'Dispatch',
  branches: 'Branches',
  readonly: 'Read only',
  only: 'Only',
  noBranchSelected: 'No branch selected',
};

const rateModifiedModal = {
  title: 'Justify the rate modifications',
  headers: {
    code: 'Code',
    suggestedRate: 'Suggested rate',
    modifiedRate: 'Modified rate',
    justification: 'Justification',
  },
  enterJustification: 'Enter a justification...',
};

const addCommentModal = {
  title: 'Add a comment',
  subtitle: 'Write a comment applying to',
  comment: 'Comment',
};

const prefacturation = {
  title: 'Codes De Facturation',
  add: 'Add',
  header: {
    codeDeFacturation: 'Code De Facturation',
    description: 'Description',
    quantity: 'Quantity',
  },
  crane: 'Crane',
  labor: 'Labor',
  accessories: 'Accessories',
  otherFees: 'Other fees',
  toBill: 'To bill',
  notRecuringBilling: 'Non-recurring codes',
  tableHeaders: {
    codeDeFacturation: 'Code',
    description: 'Description',
    accessories: 'Accessories',
    labor: `Labor`,
    fees: 'Fees',
    quantity: 'Quantity',
    rate: 'Rate',
    billable: 'Bill.',
    gtNumber: '#GT',
  },
  emptyState: 'No code de facturation of this type.',
  errors: {
    noCodeDeFacturationInDouble: 'This code de facturation already exists.',
  },
  commentsInformation: 'Comments & Information',
  comments: 'Comments',
  comment: 'This is a comment',
  noPo: 'PO #',
  total: 'Total',
  disabled: 'This code de facturation comes from the OT',
  you: 'You',
  saleRateError: 'Error fetching sale rate',
  details: 'Details.',
  noDetails: 'No details available',
  rateModifiedModal,
  addCommentModal,
  gtNumberOnOt: 'A GT number has been applied to this OT',
};

const additionalFees = {
  meals: {
    label: 'Meals',
    editLabel: 'Meals',
  },
  carAllowances: {
    label: 'Car Allowance',
    editLabel: 'Car Allowance (km per driver)',
  },
  preparations: {
    label: 'Preparation',
    editLabel: 'Pension (days per person) ',
  },
  pensions: {
    label: 'Pension',
    editLabel: 'Pension (days per person) ',
  },
  travellingExpenses: 'Travelling Expenses',
  edit: {
    travellingExpenses: {
      to: 'to',
      kmTravelled: 'Km travelled',
    },
  },
};

const additionalHours = {
  title: 'Additional hours',
  noData: 'No additional hours',
};

const validation = {
  noTimeRangeOverlap: 'Cannot overlap two time ranges',
  invalidNoLunchTimeRange: 'A time range of type "no lunch break" must have a duration of 30 minutes',
  invalidNoBreakTimeRange: 'A time range of type "no break time" must have a duration of 15 minutes',
  invalidPreparationTimeRange: 'A time range of type "preparation" must have a duration of 1 or 1.25 hours',
  toTimeBeforeFromTime: 'The end time cannot be before the start time',
  mustMatchDuration: 'The sum of all hours must match the duration',
  invalidWorkType: 'The work type is invalid',
  invalidWorkKind: 'The work kind is invalid',
  invalidGtNumber: 'The GT Number is invalid',
};

const overlapWarning = {
  title: 'More than one crane operator at the same time on the OT',
};

const dailyTimeSheet = {
  noData: 'No time sheet to display.',
  status: {
    submitted: 'Submitted',
    completed: 'Finished',
    ongoing: 'Ongoing',
  },
  linkedHours: 'Linked to',
  pending: 'Pending',
  unlinkedHours: 'Not linked',
  total: 'Total',
  totalWeekly: 'Weekly total',
  compilableHours: {
    noData: 'No time ranges',
    from: 'From',
    to: 'To',
    quantity: 'Qty',
    quantity_tip: 'Quantity',
    poste: 'Poste',
    branch_tip: 'Branch',
    branch: 'Branch',
    workOrderNumber: 'Eq/WO',
    workOrderNumber_tip: 'Equipment/Work Order',
    gtNumber: '#GT',
    single: 'Simp.',
    single_tip: 'Simple',
    singleAndHalf: 'Half',
    double: 'Double',
    autoPrime: 'Auto P.',
    autoPrime_tip: 'Automatic Prime',
    variablePrime: 'Var. P.',
    variablePrime_tip: 'Variable Prime',
    rate: 'Rate',
  },
  nonCompilableHours: {
    noData: 'No premiums',
    premium: 'Premium',
    poste: 'Poste',
    branch_tip: 'Branch',
    branch: 'Branch',
    gtNumber: '#GT',
  },
  comments: {
    title: 'Comments',
    noData: 'No comments',
  },
  attachments: {
    title: 'Attachments',
    noData: 'No attachments',
    notUploaded: 'This attachment has not been uploaded yet',
    preview: 'View the attachment',
    download: 'Download the attachment',
    name: 'Name',
  },
};

const prompt = {
  informations: 'Informations',
  title: 'Service Call Informations',
  client: 'Client Name',
  noClient: 'No Client',
  worksite: 'Worksite',
  noWorksite: 'No Worksite',
  workProgress: 'Work Progress',
  tracking: 'Tracking',
};

export const validations = {
  additionalFees,
  dailyTimeSheet,
  inlineTimesheet,
  prefacturation,
  list,
  details,
  additionalTimes,
  serviceHourTable,
  detailTabs,
  timeSheetEntryValidationStatus,
  timeSheetDistributedTimes,
  additionalHours,
  validation,
  overlapWarning,
  manuallyCreated: 'Manually created',
  prompt,
};
