import { SxProps, TextField, TextFieldProps } from '@mui/material';
import theme from '../../../mui/theme';
import { backgroundErrorColor, black } from '../../constants/colors';
import { forwardRef, useMemo } from 'react';

export const defaultInlineSx: SxProps = {
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
    borderRadius: 0,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
  '&.Mui-disabled .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
  '&.Mui-disabled:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
  '&.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: `transparent`,
    borderBottom: `1px solid ${theme.palette.error.main}`,
    backgroundColor: backgroundErrorColor,
  },
  '&.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: `transparent`,
    borderBottom: `1px solid ${theme.palette.error.main}`,
    backgroundColor: backgroundErrorColor,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${black}`,
  },
  '&.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${black}`,
  },
};

type Props = Omit<TextFieldProps, 'select'>;

export const InlineSelectInput = forwardRef<HTMLDivElement, Props>(({ value, InputProps, sx, ...props }: Props, ref) => {
  const subProps = useMemo(
    () => ({
      ...InputProps,
      style: { cursor: 'pointer', ...(InputProps?.style || {}) },
      sx: {
        fontSize: '0.875rem',
        ...defaultInlineSx,
        ...(InputProps?.sx || {}),
      },
    }),
    [InputProps],
  );

  return <TextField ref={ref} {...props} select value={value || ''} sx={{ fontSize: '0.875rem', ...sx }} InputProps={subProps} />;
});
